import cx from 'classnames';
import React from 'react';

import css from './slider.module.scss';

interface SliderProps {
  value: number;
  hightlightValue?: number;
  min: number;
  max: number;
  disabled?: boolean;
  className?: string;
  onChange: (number: number) => void;
  leftColor?: string;
  rightColor?: string;
  customBackground?: string;
}

const Slider: React.FC<SliderProps> = ({
  value,
  min,
  max,
  hightlightValue,
  onChange,
  disabled,
  className,
  leftColor,
  rightColor,
  customBackground,
}) => {
  const percent = Math.round(((value - min) / (max - min)) * 100);

  const lColor = leftColor ?? '#FF5757';
  const rColor = rightColor ?? '#EEEEEE';

  const background =
    customBackground ??
    `linear-gradient(to right, ${lColor} 0%, ${lColor} ${percent}%, ${rColor} ${percent}%, ${rColor} 100%)`;

  const hightlightPercent = hightlightValue
    ? Math.round(((hightlightValue - min) / (max - min)) * 100)
    : null;

  return (
    <div className={css.Root}>
      <input
        disabled={disabled}
        type="range"
        value={value}
        min={min}
        max={max}
        onTouchMoveCapture={(e) => e.stopPropagation()}
        onPointerMoveCapture={(e) => e.stopPropagation()}
        onChange={(e) => onChange(parseFloat(e.currentTarget.value))}
        className={cx(css.Slider, className)}
        style={{
          background,
        }}
      />
    </div>
  );
};

export default Slider;
