import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_MY_CLIENT from '../../../application/graphql/get-my-client';
import UPLOAD_CLIENT_AVATAR, {
  UploadClientAvatarMutation,
  UploadClientAvatarMutationVars,
} from '../graphql/upload-client-avatar';

const useUploadClientAvatar = () => {
  const { t } = useTranslation(['profile']);
  const [uploadClientAvatarMutation] = useMutation<
    UploadClientAvatarMutation,
    UploadClientAvatarMutationVars
  >(UPLOAD_CLIENT_AVATAR, { refetchQueries: [GET_MY_CLIENT] });

  return async (file: File) => {
    try {
      const { data, errors } = await uploadClientAvatarMutation({
        variables: { file },
      });

      const isAvatarUploadSuccess = verifyApolloMutationResponse(data, errors);
      if (!isAvatarUploadSuccess) {
        return;
      }

      if (data?.uploadClientAvatar.__typename === 'Error') {
        Toast.error(data.uploadClientAvatar.error);
        return;
      }

      if (data?.uploadClientAvatar.__typename === 'UploadClientAvatar') {
        Toast.success(t('account:successes:avatar_upload_success'));
        return data.uploadClientAvatar.fileUrl;
      }
    } catch (e: unknown) {
      Toast.error(t('account:errors:avatar_upload_error'));
    }
  };
};

export default useUploadClientAvatar;
