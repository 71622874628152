import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { formatDatetimeToFull } from 'application/utils/date';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Text } from 'ui/v2/typography/text';
import { VerticalScrollWrapper } from 'ui/vertical-scroll-wrapper/vertical-scroll-wrapper';

import css from './transactions-history.module.scss';
import { ClientTransactionModel } from '../../graphql/get-my-client-transactions';

const MoneyAmount = ({ amount }: { amount: number }) => {
  if (amount > 0) {
    return <div className={css.Positive}>+{amount} vts</div>;
  }

  return <div className={css.Negative}>{amount} vts</div>;
};

interface Props {
  transactions: ClientTransactionModel[];
}

const TransactionsHistoryTable = ({ transactions }: Props) => {
  const { t } = useTranslation(['period', 'bets']);

  const gridTemplate = '110px minmax(200px,1fr) 150px';
  const gridTemplateTablet = '80px minmax(180px,1fr) 150px';
  const gridTemplateMobile = '70px minmax(120px,1fr) 110px';

  return (
    <VerticalScrollWrapper>
      <ResponsiveGrid
        className={css.Table}
        alignItems="center"
        gap={GridGap.x2}
        gridTemplateColumns={gridTemplate}
        tablet={{ gridTemplateColumns: gridTemplateTablet }}
        mobile={{ gridTemplateColumns: gridTemplateMobile }}
        gridAutoRows="40px"
      >
        <Text type="headline">{t('common:period:date')}</Text>
        <Text type="headline">{t('bets:transaction_type')}</Text>
        <Text type="headline" textAlign="center">
          {t('bets:amount')}
        </Text>
      </ResponsiveGrid>
      {transactions.map((el, index) => {
        return (
          <ResponsiveGrid
            className={cx(css.Table, css.Border_top, {
              [css.gray_back]: index % 2 === 0,
            })}
            alignItems="center"
            gap={GridGap.x2}
            gridTemplateColumns={gridTemplate}
            tablet={{ gridTemplateColumns: gridTemplateTablet }}
            mobile={{ gridTemplateColumns: gridTemplateMobile }}
            gridAutoRows="minmax(74px, 1fr)"
            key={el.id}
          >
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              className={css.Wrap}
            >
              {formatDatetimeToFull(new Date(el.date))}
            </Text>
            <Flex flexDirection="column" gap={'5px'} alignItems="flex-start">
              <Text
                type="paragraph"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
                className={css.Wrap}
              >
                {el.typeName}
              </Text>
              {el.outcomeName && (
                <Text
                  type="paragraph"
                  mobile={{ fontSize: '12px' }}
                  tablet={{ fontSize: '14px' }}
                  className={css.Wrap}
                >
                  {el.outcomeName}
                </Text>
              )}
              {el.eventName && (
                <Text
                  type="paragraph"
                  mobile={{ fontSize: '12px' }}
                  tablet={{ fontSize: '14px' }}
                  className={css.Wrap}
                >
                  {el.eventName}
                </Text>
              )}
              {el.showTokenQuantity && (
                <Text
                  type="paragraph"
                  mobile={{ fontSize: '12px' }}
                  tablet={{ fontSize: '14px' }}
                  textAlign="center"
                >
                  Количество купленных токенов: {el.tokenQuantity}
                </Text>
              )}
            </Flex>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              <MoneyAmount amount={el.amount} />
            </Text>
          </ResponsiveGrid>
        );
      })}
    </VerticalScrollWrapper>
  );
};

export default TransactionsHistoryTable;
