import React, { useState } from 'react';

import useVoteLeaders from './use-vote-leaders';
import VoteLeaders, { VoteLeadersPeriods } from './vote-leaders';

const VoteLeadersConnected: React.FC<{ sport?: string }> = ({ sport }) => {
  const [period, setPeriod] = useState<VoteLeadersPeriods>('TODAY');
  const { leaders } = useVoteLeaders(period, sport);

  return (
    <VoteLeaders period={period} onPeriodChange={setPeriod} votes={leaders} />
  );
};

export default VoteLeadersConnected;
