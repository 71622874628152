import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { Toast } from 'ui';
import { refactorEditorBlocks } from 'ui/editor/refactor-editor-blocks';

import EditPublishedNewsModal from './edit-published-news-modal';
import useEditNews from '../../hooks/use-edit-news';
import { useGetNewsFeedFilters } from '../../hooks/use-get-news-feed-filters';
import useGetOnePublishedNews from '../../hooks/use-get-one-published-news';
import useLoadWidgetData from '../../hooks/use-load-widget-data';
import useNewsFeed from '../../hooks/use-news-feed';
import usePinPublishedNews from '../../hooks/use-pin-published-news';
import useUpdatePublishedNews from '../../hooks/use-update-published-news';
import useUploadImage from '../../hooks/use-upload-image';
import useUploadVideo from '../../hooks/use-upload-video';

interface EditPublishedNewsModalContainerProps {
  id: string;
  closeModal: () => void;
}

const EditPublishedNewsModalContainer: React.FC<
  EditPublishedNewsModalContainerProps
> = ({ id, closeModal }) => {
  const { t } = useTranslation(['news']);
  const { data, error } = useGetOnePublishedNews(id);
  const { fetchMore } = useNewsFeed();
  const {
    isInitialDataReady,
    newsFormData,
    updateNewsFormData,
    isNewsDataModified,
  } = useEditNews(data ?? null);

  const [loading, setLoading] = useState(false);
  const [isPinned, setIsPinned] = useState<boolean>(false);
  const updatePublishedNewsMutation = useUpdatePublishedNews(
    useGetNewsFeedFilters()
  );

  const pinPublishedNews = usePinPublishedNews();

  const loadWidgetLink = useLoadWidgetData();
  const uploadImage = useUploadImage();
  const uploadVideo = useUploadVideo();

  useEffect(() => {
    if (data?.isPinned !== undefined) {
      setIsPinned(data.isPinned);
    }
  }, [data]);

  const uploadPreviewImage = async (img: File) => {
    const result = await uploadImage(img);

    if (result.success === 1) {
      updateNewsFormData('previewImage', result.file.url);
    }
    return result;
  };

  const uploadPreviewVideo = async (video: File) => {
    const result = await uploadVideo(video);

    if (result.success === 1) {
      updateNewsFormData('previewVideo', result.file.url);
    }
    return result;
  };

  const updatePublishedNews = async () => {
    if (!newsFormData.sportId || !newsFormData.categoryId) return;

    const confirmUpdateNews = window.confirm(
      t('news:actions:confirm_update_news')
    );
    if (!confirmUpdateNews) return;

    setLoading(true);
    const input = {
      sportId: newsFormData.sportId,
      categoryId: newsFormData.categoryId,
      title: newsFormData.title,
      previewDescription: newsFormData.previewDescription,
      previewImage: newsFormData.previewImage,
      previewVideo: newsFormData.previewVideo,
      blocks: refactorEditorBlocks(newsFormData.blocks),
      previewUploadDescription: newsFormData.previewUploadDescription,
    };

    const result = await updatePublishedNewsMutation(id, input);
    if (!result) return;

    setLoading(false);
    Toast.success(t('news:successes:article_update_success'));
    closeModal();
  };

  const handleSetIsPinned = async () => {
    setLoading(true);
    const newPinnedState = !isPinned;
    const pinnedNewsId = await pinPublishedNews(id, newPinnedState);
    if (pinnedNewsId) {
      setIsPinned(newPinnedState);
      fetchMore({
        variables: {
          sportAlias: '',
          offset: 0,
        },
      });
    }
    setLoading(false);
  };

  if (!isInitialDataReady || error) return <></>;

  return (
    <EditPublishedNewsModal
      loading={loading}
      newsFormData={newsFormData}
      setIsPinned={handleSetIsPinned}
      isPinned={isPinned}
      uploadImage={uploadImage}
      uploadVideo={uploadVideo}
      loadNewsWidgetLink={loadWidgetLink}
      isNewsDataModified={isNewsDataModified}
      updateNewsFormData={updateNewsFormData}
      uploadPreviewImage={uploadPreviewImage}
      uploadPreviewVideo={uploadPreviewVideo}
      updatePublishedNews={updatePublishedNews}
      closeModal={closeModal}
    />
  );
};

export default EditPublishedNewsModalContainer;
