import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

import { ShowMoreButton, Tabs } from 'ui';

import css from './comments-list.module.scss';
import { CommentModel } from '../../commets-type';
import { AddCommentMutationVars } from '../../graphql/add-comment';
import AnswerInput from '../answer-input';
import CommentsTree from '../comments-tree';

import { SortType } from './index';

const COMMENTS_LIMIT = 20;

export type AnswerVariablesType = Omit<AddCommentMutationVars, 'comment'>;

interface CommentsListProps {
  commentsData: {
    level: number;
    totalCount: number;
    comments: CommentModel[];
  };
  answerVariables: AnswerVariablesType;
  sort: SortType;
  setSort: (sort: SortType) => void;
  updateCommentsData: () => void;
  loadMore: (lastCommentId: string) => void;
}

const CommentsList: React.FC<CommentsListProps> = ({
  commentsData,
  answerVariables,
  sort,
  setSort,
  loadMore,
  updateCommentsData,
}) => {
  const { t } = useTranslation(['comments', 'common']);
  const { comments } = commentsData;

  const showMoreComments = () => {
    loadMore(comments[comments.length - 1].id);
  };

  const commentsHeader = (
    <>
      <div className={css.Header}>
        <p className={css.Title}>
          {t('comments:comment', { count: commentsData.totalCount })}
        </p>

        <div className={css.Tabs}>
          <Tabs
            value={sort}
            items={[
              { value: SortType.POPULAR, label: t('comments:popular') },
              { value: SortType.LAST, label: t('comments:latest') },
              { value: SortType.ORDER, label: t('comments:in_order') },
            ]}
            onSelect={setSort}
            small
          />
        </div>
      </div>

      <AnswerInput
        answerVariables={answerVariables}
        updateCommentsData={updateCommentsData}
      />
    </>
  );

  const { query } = useRouter();

  useEffect(() => {
    if (query.event || query.news) {
      const elId = query.event ? `tournament-comments` : 'news-comments';

      const element = document.getElementById(elId);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [query.event, query.news]);

  return (
    <>
      {!answerVariables.commentId && commentsHeader}

      <CommentsTree
        comments={comments}
        updateCommentsData={updateCommentsData}
      />

      {comments.length === COMMENTS_LIMIT && (
        <div className={css.ShowMoreButton}>
          <ShowMoreButton onClick={showMoreComments} />
        </div>
      )}
    </>
  );
};

export default CommentsList;
