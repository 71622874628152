import { useTranslation } from 'next-i18next';
import React from 'react';

import ConfirmModal from 'ui/confirm-modal';

import css from './reject-confirm-modal.module.scss';
import { NewsRejectedReason } from '../../graphql/get-news-rejected-reason-list';
import { RejectedReason } from '../moderated-news-modal';
import NewsRejectedReasonList from '../news-rejected-reason-list';

interface RejectConfirmModalProps {
  loading: boolean;
  isModalOpen: boolean;
  rejectedReason: NewsRejectedReason | null;
  closeModal: () => void;
  rejectNews: () => void;
  setRejectedReason: (reasonItem: RejectedReason) => void;
}

const RejectConfirmModal: React.FC<RejectConfirmModalProps> = ({
  loading,
  isModalOpen,
  closeModal,
  setRejectedReason,
  rejectNews,
  rejectedReason,
}) => {
  const { t } = useTranslation(['news']);
  const buttonDisabled =
    loading || !rejectedReason || (rejectedReason && !rejectedReason.reason);

  return (
    <ConfirmModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      callback={rejectNews}
      actionButtonTitle={t('news:actions:reject')}
      disabled={buttonDisabled}
    >
      <div className={css.ConfirmModalContainer}>
        <p className={css.Title}>
          {t('news:labels:article_rejection_reason')}:
        </p>
        <NewsRejectedReasonList setRejectedReason={setRejectedReason} />
      </div>
    </ConfirmModal>
  );
};

export default RejectConfirmModal;
