import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import React, { createRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import webApp from 'application/web-app';
import { Button, Checkbox, Input, PasswordInput, ReCaptcha } from 'ui';
import GoogleIcon from 'ui/icons/google';
import Modal, { ModalContent, ModalFooter } from 'ui/modal';
import { Text } from 'ui/v2/typography/text';

import css from './signup-modal.module.scss';
import useWindowDimensions from '../../../../application/hooks/use-window-dimensions';

type RegistrationData = {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
  recaptcha: ReCAPTCHA | null;
  refCode?: string;
  agreement: boolean;
};

export type SignupFormErrors = {
  username?: string;
  email?: string;
  password?: string;
  passwordConfirm?: string;
  agreement?: string;
  recaptcha?: string;
};

interface SignupModalProps {
  errors: SignupFormErrors;
  isOpen: boolean;
  isProcessing: boolean;
  noOverflow?: boolean;
  onClearPasswordConfirmError: () => void;
  onClose?: () => void;
  onRegistration: (data: RegistrationData) => void;
  onGotToSignin: () => void;
}

const MOBILE_MODAL_WIDTH = 302.81;
const DESKTOP_MODAL_WIDTH = 375;

const SignupModal: React.FC<SignupModalProps> = ({
  errors,
  isOpen,
  isProcessing,
  noOverflow,
  onClose,
  onRegistration,
  onGotToSignin,
  onClearPasswordConfirmError,
}) => {
  const { query } = useRouter();

  const refCode = query.refCode as string | undefined;
  const { width: windowWidth } = useWindowDimensions();

  const modalWidth =
    windowWidth <= 768 ? MOBILE_MODAL_WIDTH : DESKTOP_MODAL_WIDTH;

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [agreement, setAgreement] = useState(false);
  const recaptcha = createRef<ReCAPTCHA>();
  const { t } = useTranslation(['auth']);

  const handleGoogleLogin = () => {
    localStorage.setItem('redirectUrl', window.location.href);
    window.location.href = webApp.router.getGoogleAuth(refCode);
  };

  return (
    <Modal
      title={t('auth:sign_up')}
      isOpen={isOpen}
      width={modalWidth}
      onClose={onClose}
      noOverflow={noOverflow}
      promoBanner={refCode ? 'Конкурс на 1500$!' : ''}
    >
      <ModalContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={css.SmallForm}
        >
          <div className={css.Group}>
            <div className={css.Title}>
              <Trans
                i18nKey="auth:nick_example"
                components={{
                  1: (
                    <Text
                      className={css.NickExampleText}
                      type="paragraph"
                      color="grey-primary"
                    ></Text>
                  ),
                }}
              />
            </div>
            <div>
              <Input
                value={username}
                className={css.Input}
                onChange={setUsername}
                error={errors.username}
              />
            </div>
          </div>

          <div className={css.Group}>
            <div className={css.Title}>{t('auth:email')}</div>
            <div>
              <Input
                type="email"
                className={css.Input}
                value={email}
                onChange={setEmail}
                error={errors.email}
              />
            </div>
          </div>

          <div className={css.Group}>
            <div className={css.Title}>{t('auth:password')}</div>
            <div>
              <PasswordInput
                className={css.Input}
                value={password}
                onChange={setPassword}
                error={errors.password}
              />
            </div>
          </div>

          <div className={css.Group}>
            <div className={css.Title}>{t('auth:password_confirm')}</div>
            <div>
              <PasswordInput
                className={css.Input}
                value={passwordConfirm}
                onChange={setPasswordConfirm}
                error={errors.passwordConfirm}
                onFocus={onClearPasswordConfirmError}
              />
            </div>
          </div>

          <div className={css.Group}>
            <Checkbox
              checked={agreement}
              onCheck={() => {
                setAgreement(!agreement);
              }}
              error={errors.agreement}
            >
              <div className={css.Agreement}>
                {t('auth:agreement')}
                <a href="/privacy" target="_blank">
                  {t('auth:terms')}
                </a>
                .
              </div>
            </Checkbox>
          </div>

          {!refCode && (
            <div className={css.Group}>
              <ReCaptcha ref={recaptcha} error={errors.recaptcha} />
            </div>
          )}

          <div className={css.Group}>
            <Button
              className={css.Button}
              disabled={isProcessing}
              onClick={() =>
                onRegistration({
                  username,
                  email,
                  password,
                  passwordConfirm,
                  agreement,
                  recaptcha: recaptcha.current ?? null,
                  refCode,
                })
              }
              relative
              color="secondary"
            >
              {t('auth:to_sign_up')}
            </Button>
          </div>
          {refCode && (
            <div className={css.Group}>
              <Button
                size="large"
                className={css.Google}
                color="invert"
                onClick={handleGoogleLogin}
              >
                <GoogleIcon />
                {t('auth:to_sign_in_google')}
              </Button>
            </div>
          )}
        </form>
      </ModalContent>
      {!refCode && (
        <ModalFooter>
          <Button
            disabled={isProcessing}
            className={css.SignIn}
            onClick={onGotToSignin}
            relative
          >
            {t('auth:sign_in')}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default SignupModal;
