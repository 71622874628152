import { useTranslation } from 'next-i18next';

import { Button, Modal, ModalContent, ModalFooter } from 'ui';

import css from './push-token-permission-modal.module.scss';

interface PushTokenModalProps {
  open: boolean;
  close: () => void;
  sendPushToken: () => void;
}

const PushTokenModal: React.FC<PushTokenModalProps> = ({
  open,
  close,
  sendPushToken,
}) => {
  const { t } = useTranslation(['notifications']);

  return (
    <Modal title={t('notifications:connect_push_notifications')} isOpen={open}>
      <ModalContent>{t('notifications:want_push_notifications')}</ModalContent>
      <ModalFooter>
        <div className={css.Buttons}>
          <Button color="empty" onClick={close}>
            {t('common:labels:no')}
          </Button>
          <Button onClick={sendPushToken}>{t('common:actions:to_plug')}</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default PushTokenModal;
