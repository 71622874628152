import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useRef } from 'react';
import { Id, toast } from 'react-toastify';

import Button from 'ui/button';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import WarningIcon from 'ui/icons/warning';
import { Text } from 'ui/v2/typography/text';

import css from './cookie-terms.module.scss';

const COOKIE_NAME = 'vote-cookie-consent';

interface Props {
  onAccept: () => void;
}

const Content = ({ onAccept }: Props) => {
  const { i18n, t } = useTranslation(['notifications']);

  return (
    <Flex flexDirection="column" gap={GridGap.x1}>
      <Text type="caption" color="white-color">
        <Trans
          i18nKey="notifications:terms_cookie:content"
          components={{
            1: (
              <Link
                href={{
                  pathname: '/privacy',
                }}
                className={css.Link}
                target="_blank"
              />
            ),
          }}
        />
      </Text>
      <Button onClick={onAccept} className={css.Agree} outline>
        {t('notifications:terms_cookie:btn')}
      </Button>
    </Flex>
  );
};

const dropNotification = ({ onAccept }: Props) =>
  toast.warn(<Content onAccept={onAccept} />, {
    icon: <WarningIcon />,
    autoClose: false,
    closeOnClick: false,
    closeButton: false,
    onClose: onAccept,
    containerId: 'cookie',
  });

const useCookieTerms = () => {
  const toastId = useRef<Id | null>(null);

  const onAccept = () => {
    localStorage.setItem(COOKIE_NAME, 'true');

    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
  };
  useEffect(() => {
    const cookieAccepted = localStorage.getItem(COOKIE_NAME);

    if (!cookieAccepted) {
      toastId.current = dropNotification({ onAccept });
    }
  }, []);
};

export default useCookieTerms;
