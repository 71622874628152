import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';

export type VoteLeaderModel = {
  id: string;
  tournamentId: string;
  name: string;
  position: number;
  positionStatus: boolean | null;
  coef1: string;
  coef2: string;
  increase: string;
};

export interface GetVoteLeadersQuery {
  getVoteLeaders:
    | {
        __typename: 'GetVoteLeaders';
        leaders: VoteLeaderModel[];
      }
    | GraphqlErrorType;
}

export interface GetVoteLeadersQueryVars {
  period: string;
  sport?: string;
}

const GET_VOTE_LEDAERS = gql`
  query GetVoteLeaders($period: String!, $sport: ID) {
    getVoteLeaders(period: $period, sport: $sport) {
      __typename
      ... on GetVoteLeaders {
        leaders {
          id
          tournamentId
          name
          position
          positionStatus
          coef1
          coef2
          increase
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_VOTE_LEDAERS;
