import cx from 'classnames';
import { useRouter } from 'next/router';

import { Card } from 'ui';
import { RenderList } from 'ui/render-list';

import CommentIcon from './comment.svg';
import css from './tournament-comments.module.scss';
import CommentsList from '../comments-list';

import { GENERAL_DISCUSSION_ID } from './index';

interface TournamentCommentsProps {
  tournament: string;
  events: { id: string; name: string; count: number }[];
  eventId: string;
  selectEvent: (event: string) => void;
}

const TournamentComments: React.FC<TournamentCommentsProps> = ({
  tournament,
  events,
  eventId,
  selectEvent,
}) => {
  const { query } = useRouter();

  return (
    <div className={css.Root} id="tournament-comments">
      <Card className={css.Card}>
        <ul className={css.List}>
          <RenderList
            renderItem={(event) => {
              return (
                <li
                  key={event.id}
                  className={cx(
                    css.Item,
                    eventId === event.id && css.ActiveItem
                  )}
                  onClick={() => selectEvent(event.id)}
                  id={`event-${event.id}`}
                >
                  {event.name + ' '}
                  <span className={css.Count}>{event.count}</span>
                </li>
              );
            }}
            list={events}
            limit={10}
            mobileLimit={5}
            firstEl={<CommentIcon />}
            defaultIsLimited={!query.event}
          />
        </ul>

        <div className={css.Comments}>
          {eventId !== GENERAL_DISCUSSION_ID && (
            <CommentsList eventId={eventId} />
          )}
          {eventId === GENERAL_DISCUSSION_ID && (
            <CommentsList tournamentId={tournament} />
          )}
        </div>
      </Card>
    </div>
  );
};

export default TournamentComments;
