import { Trans } from 'next-i18next';
import React from 'react';

import { ConfirmModal } from 'ui';

interface DeleteNewsModalProps {
  title: string;
  isModalOpen: boolean;
  closeModal: () => void;
  callback: () => void;
}

const DeleteNewsModal: React.FC<DeleteNewsModalProps> = ({
  title,
  isModalOpen,
  closeModal,
  callback,
}) => {
  return (
    <ConfirmModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      callback={callback}
    >
      <p>
        <Trans
          i18nKey="news:actions:article_delete_confirm"
          values={{ news: title }}
          components={{
            1: <span></span>,
          }}
        />
      </p>
    </ConfirmModal>
  );
};

export default DeleteNewsModal;
