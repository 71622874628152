import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';

export type LastAnnounceModel = {
  id: string;
  tournamentId: string;
  name: string;
  description: string;
  date: string;
  opponent1: {
    id: string;
    name: string;
    country: string;
    score: string;
    image: string;
  };
  opponent2: {
    id: string;
    name: string;
    country: string;
    score: string;
    image: string;
  };
  coef1: number;
  coef2: number;
};

export interface GetLastAnnouncesQuery {
  getLastAnnounces:
    | {
        __typename: 'GetLastAnnounces';
        announces: LastAnnounceModel[];
      }
    | GraphqlErrorType;
}

export interface GetLastAnnouncesQueryVars {
  sport?: string;
}

const GET_LAST_ANNOUNCES = gql`
  query GetLastAnnounces($sport: ID) {
    getLastAnnounces(sport: $sport) {
      __typename
      ... on GetLastAnnounces {
        announces {
          id
          tournamentId
          name
          description
          date
          opponent1 {
            id
            name
            country
            score
            image
          }
          opponent2 {
            id
            name
            country
            score
            image
          }
          coef1
          coef2
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_LAST_ANNOUNCES;
