import { useMutation } from '@apollo/client';
import { i18n } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import BAN_COMMENT_AUTHOR, {
  BanCommentAuthorMutation,
  BanCommentAuthorMutationVars,
} from '../graphql/ban-comment-author';

const useBanCommentAuthor = () => {
  const [banCommentAuthor] = useMutation<
    BanCommentAuthorMutation,
    BanCommentAuthorMutationVars
  >(BAN_COMMENT_AUTHOR);

  return async (commentId: string) => {
    try {
      const { data, errors } = await banCommentAuthor({
        variables: {
          id: commentId,
        },
      });
      const isBanCommentAuthorSuccess = verifyApolloMutationResponse(
        data,
        errors
      );
      if (!isBanCommentAuthorSuccess) return;

      if (data?.banCommentAuthor.__typename === 'Error') {
        Toast.error(data.banCommentAuthor.error);
        return;
      }

      Toast.info(i18n!.t('comments:user_banned'));
    } catch (e: unknown) {
      Toast.error(i18n!.t('comments:ban_error'));
    }
  };
};

export default useBanCommentAuthor;
