import React from 'react';

import { IndexCard } from 'ui';

import css from './extra-card.module.scss';

interface ExtraCardProps {
  title: string;
  children: React.ReactNode;
}

const ExtraCard: React.FC<ExtraCardProps> = ({ title, children }) => {
  return (
    <IndexCard noPadding>
      <div className={css.Title}>{title}</div>
      {children}
    </IndexCard>
  );
};

export default ExtraCard;
