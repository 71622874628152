import Chart from 'chart.js/auto';
import React, { useEffect, useRef, useState } from 'react';

import logoSrc from '../../../public/images/logo-for-chart.png';
import { LiveResultLegendItem } from '../../features/tournament/graphql/get-live-result-history';

let zoomPlugin: any = null;

if (typeof window !== 'undefined') {
  import('chartjs-plugin-zoom').then((module) => {
    zoomPlugin = module.default;
    Chart.register(zoomPlugin);
  });
}

interface LineChartProps {
  dates?: string[];
  data: {
    name: string;
    color: string;
    dataset: number[] | { x: number; y: number }[];
  }[];
  y?: { min?: number; max?: number };
  horizontalGrid?: boolean;
  verticalGrid?: boolean;
  legend?: LiveResultLegendItem[];
  onPointHover?: (value: number, legendName: string) => void;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showTooltip?: boolean;
  zoomEnabled?: boolean;
  onPointLeave?: () => void;
  onLastPointChange?: (lastPoint: number) => void;
}

const LineChart: React.FC<LineChartProps> = ({
  dates,
  horizontalGrid,
  showTooltip,
  onPointHover,
  legend,
  onPointLeave,
  onLastPointChange,
  verticalGrid,
  data,
  showXAxis,
  showYAxis,
  y,
  zoomEnabled,
}) => {
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chart = useRef<Chart | null>(null);
  const [pluginLoaded, setPluginLoaded] = useState(false);

  useEffect(() => {
    if (zoomPlugin) {
      setPluginLoaded(true);
    }
  }, []);

  useEffect(() => {
    const canvas = ref.current;
    if (canvas) {
      canvas.addEventListener(
        'wheel',
        (e) => {
          e.preventDefault();
        },
        { passive: false }
      );
    }
  }, []);

  useEffect(() => {
    if (chart.current) {
      chart.current.data = {
        ...chart.current.data,
        labels: dates,
        datasets: data.map((item) => {
          const dataset = item.dataset as number[];

          const pointRadius = dataset.map((_, idx) =>
            idx === dataset.length - 1 ? 6 : 0
          );

          return {
            label: item.name,
            borderWidth: 3,
            borderColor: item.color,
            backgroundColor: item.color,
            data: dataset,
            fill: false,
            pointRadius: pointRadius,
            pointHoverRadius: 8,
            pointBackgroundColor: item.color,
            pointHoverBackgroundColor: 'white',
            pointHoverBorderColor: item.color,
            pointBorderWidth: 2,
          };
        }),
      };

      if (chart.current.options.plugins?.tooltip) {
        chart.current.options.plugins.tooltip.enabled = showTooltip ?? true;
      }

      chart.current.options.scales = {
        x: {
          grid: {
            display: verticalGrid,
          },
          border: {
            display: showXAxis,
          },
          ticks: {
            maxTicksLimit: 5,
          },
        },
        y: {
          position: 'right',
          grid: {
            display: horizontalGrid,
          },
          border: {
            display: showYAxis,
          },
          ...(y?.min !== undefined && { min: y.min }),
          ...(y?.max !== undefined && { max: y.max }),
        },
      };

      chart.current.options.onHover = (event: any, chartElement: any) => {
        if (chartElement.length > 0) {
          const { datasetIndex, index } = chartElement[0];

          const currentLegend = legend?.[datasetIndex];

          const value = chart.current?.data.datasets[datasetIndex].data[index];
          // const startValue = currentLegend?.startValue
          //   ? parseFloat(currentLegend.startValue)
          //   : 0;

          if (typeof value === 'number') {
            onPointHover?.(value, currentLegend?.name || '');
          }
        } else {
          onPointLeave?.();
        }
      };

      chart.current.update('none');
    }
  }, [dates, data, y]);

  useEffect(() => {
    if (ref.current && pluginLoaded) {
      if (chart.current) {
        chart.current.destroy();
      }

      chart.current = new Chart(ref.current, {
        type: 'line',
        data: {
          labels: dates,
          datasets: data.map((item) => {
            const dataset = item.dataset as number[];
            const pointRadius = dataset.map((_, idx) =>
              idx === dataset.length - 1 ? 6 : 0
            );

            return {
              label: item.name,
              borderWidth: 3,
              borderColor: item.color,
              backgroundColor: item.color,
              data: dataset,
              fill: false,
              pointRadius: pointRadius,
              pointHoverRadius: 8,
              pointBackgroundColor: item.color,
              pointHoverBackgroundColor: 'white',
              pointHoverBorderColor: item.color,
              pointBorderWidth: 2,
            };
          }),
        },
        options: {
          responsive: true,
          aspectRatio: 2.33,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: showTooltip,
              mode: 'index',
              intersect: false,
              position: 'nearest',
              callbacks: {
                label: (item) => {
                  const outcomeName = item.dataset.label;
                  const value = item.raw;
                  return ` ${outcomeName}: ${value}`;
                },
              },
              backgroundColor: '#f6f3f3',
              titleColor: '#000',
              bodyColor: '#000',
              borderColor: '#8c8c8c',
              borderWidth: 1,
              titleFont: { size: 14, weight: 'bold' },
              bodyFont: { size: 12 },
              padding: 10,
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: zoomEnabled,
                },
                mode: 'x',
              },
              pan: {
                enabled: zoomEnabled,
                mode: 'x',
              },
              limits: {
                y: {
                  ...(y?.min !== undefined && { min: y.min }),
                  ...(y?.max !== undefined && { max: y.max }),
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: verticalGrid,
              },
              border: {
                display: showXAxis,
              },
              ticks: {
                maxTicksLimit: 5,
              },
            },
            y: {
              position: 'right',
              grid: {
                display: horizontalGrid,
              },
              border: {
                display: showYAxis,
              },
              ...(y?.min !== undefined && { min: y.min }),
              ...(y?.max !== undefined && { max: y.max }),
            },
          },
        },
      });

      if (chart.current) {
        const lastDataset = chart.current.data.datasets[0]?.data as number[];
        const lastPoint = lastDataset?.[lastDataset.length - 1];
        if (lastPoint !== undefined) {
          onLastPointChange?.(lastPoint);
        }
      }

      return () => {
        chart.current?.destroy();
        chart.current = null;
      };
    }
  }, [ref, pluginLoaded, zoomEnabled]);

  useEffect(() => {
    if (chart.current) {
      const lastDataset = chart.current.data.datasets[0]?.data as number[];
      const lastPoint = lastDataset?.[lastDataset.length - 1];

      if (lastPoint !== undefined && onLastPointChange) {
        onLastPointChange(lastPoint);
      }
    }
  }, [data, dates, onLastPointChange]);

  return (
    <div
      style={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}
    >
      <img src={logoSrc.src} style={{ marginBottom: '5px' }} alt="Logo" />
      <canvas ref={ref} />
    </div>
  );
};

export default LineChart;
