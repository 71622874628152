import { useMutation } from '@apollo/client';
import { i18n } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_TOURNAMENT_INFO, {
  TournamentInfo,
} from '../../tournament/graphql/get-tournament';
import { CommentModel } from '../commets-type';
import DELETE_MY_COMMENT, {
  DeleteMyCommentMutation,
  DeleteMyCommentMutationVars,
} from '../graphql/delete-my-comment';
import GET_COMMENTS, { GetCommentsQueryVars } from '../graphql/get-comments';
import GET_TOURNAMENT_EVENTS_COMMENTS, {
  EventType,
} from '../graphql/get-tournament-events-comments';

const useDeleteMyComment = (
  params: GetCommentsQueryVars,
  tournamentId?: string
) => {
  const [deleteMyComment] = useMutation<
    DeleteMyCommentMutation,
    DeleteMyCommentMutationVars
  >(DELETE_MY_COMMENT, {
    update: (cache, { data }) => {
      if (data?.deleteMyComment.__typename !== 'DeleteMyComment') return;

      const deletedCommentData = data.deleteMyComment;

      const commentsList: {
        getComments: { comments: CommentModel[] };
      } | null = cache.readQuery({
        query: GET_COMMENTS,
        variables: params,
      });

      if (!commentsList || !deletedCommentData.commentId) return;

      const updatedCommentsList = commentsList.getComments.comments.filter(
        (comment) => comment.id !== deletedCommentData.commentId
      );

      cache.writeQuery({
        query: GET_COMMENTS,
        variables: params,
        data: {
          getComments: {
            ...commentsList.getComments,
            comments: updatedCommentsList,
          },
        },
      });

      cache.modify({
        fields: {
          getComments(existingCommentsData, { readField }) {
            if (readField('level', existingCommentsData) === 1) {
              return {
                ...existingCommentsData,
                totalCount: deletedCommentData.totalCount,
              };
            }
            return existingCommentsData;
          },
        },
      });

      if (!tournamentId) return;

      const tournamentInfo: {
        getTournamentInfo: {
          __typename: 'GetTournamentInfo';
        } & TournamentInfo;
      } | null = cache.readQuery({
        query: GET_TOURNAMENT_INFO,
        variables: {
          id: tournamentId,
        },
      });

      if (tournamentInfo && deletedCommentData.eventId) {
        const newEvents = tournamentInfo.getTournamentInfo.events.map((event) =>
          event.id === deletedCommentData.eventId
            ? {
                ...event,
                commentsCount: deletedCommentData.totalCount,
              }
            : event
        );

        cache.writeQuery({
          query: GET_TOURNAMENT_INFO,
          variables: {
            id: tournamentId,
          },
          data: {
            getTournamentInfo: {
              ...tournamentInfo.getTournamentInfo,
              events: newEvents,
            },
          },
        });
      }

      const tournamentEventsComments: {
        getTournamentEventsComments: {
          count: number;
          events: EventType[];
        };
      } | null = cache.readQuery({
        query: GET_TOURNAMENT_EVENTS_COMMENTS,
        variables: {
          id: tournamentId,
        },
      });

      if (!tournamentEventsComments) return;

      if (deletedCommentData.tournamentId) {
        cache.writeQuery({
          query: GET_TOURNAMENT_EVENTS_COMMENTS,
          variables: {
            id: tournamentId,
          },
          data: {
            getTournamentEventsComments: {
              ...tournamentEventsComments.getTournamentEventsComments,
              count: deletedCommentData.totalCount,
            },
          },
        });
      }

      if (deletedCommentData.eventId) {
        const newEvents =
          tournamentEventsComments.getTournamentEventsComments.events.map(
            (event) =>
              event.id === deletedCommentData.eventId
                ? {
                    ...event,
                    count: deletedCommentData.totalCount,
                  }
                : event
          );

        cache.writeQuery({
          query: GET_TOURNAMENT_EVENTS_COMMENTS,
          variables: {
            id: tournamentId,
          },
          data: {
            getTournamentEventsComments: {
              ...tournamentEventsComments.getTournamentEventsComments,
              events: newEvents,
            },
          },
        });
      }
    },
  });

  return async (commentId: string) => {
    try {
      const { data, errors } = await deleteMyComment({
        variables: {
          id: commentId,
        },
      });

      const isDeleteMyCommentSuccess = verifyApolloMutationResponse(
        data,
        errors
      );
      if (!isDeleteMyCommentSuccess) return;

      if (data?.deleteMyComment.__typename === 'Error') {
        Toast.error(data.deleteMyComment.error);
        return;
      }

      if (data?.deleteMyComment.__typename === 'DeleteMyComment') {
        Toast.info(i18n!.t('comments:deleted_comment_success'));
        return data.deleteMyComment.commentId;
      }
    } catch (e: unknown) {
      Toast.error(i18n!.t('comments:delete_comment_error'));
    }
  };
};

export default useDeleteMyComment;
