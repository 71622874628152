import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type NextEventTournamentModel = {
  id: string;
  name: string;
  date: string;
  sportType: string | null;
};

export type NextEventModel = {
  id: string;
  tournamentId: string;
  startDate: string;
  opponent1: NextEventOpponentModel;
  opponent2: NextEventOpponentModel;
  coef1: number;
  coef2: number;
};

export type NextEventOpponentModel = {
  id: string;
  name: string;
  country: string;
  score: string;
  image: string;
  mmaOpponent: NextEventMmaOpponent;
};

export type NextEventMmaOpponent = {
  win: number | null;
  draw: number | null;
  lose: number | null;
  countryFlag: string | null;
};

export interface GetNextEventsQuery {
  getNextEvents:
    | {
        __typename: 'GetNextEvents';
        tournaments: NextEventTournamentModel[];
        events: NextEventModel[];
        opponents: NextEventOpponentModel[];
      }
    | GraphqlErrorType;
}

export interface GetNextEventsQueryVars {
  sport?: string;
}

const GET_NEXT_EVENTS = gql`
  query GetNextEvents($sport: ID) {
    getNextEvents(sport: $sport) {
      __typename
      ... on GetNextEvents {
        tournaments {
          id
          name
          date
          sportType
        }
        events {
          id
          tournamentId
          startDate
          opponent1Id
          opponent1 {
            id
            name
            country
            score
            image
            mmaOpponent {
              win
              draw
              lose
              countryFlag
            }
          }
          opponent2Id
          opponent2 {
            id
            name
            country
            score
            image
            mmaOpponent {
              win
              draw
              lose
              country
            }
          }
          coef1
          coef2
        }
        opponents {
          id
          name
          country
          score
          image
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEXT_EVENTS;
