import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { verifyApolloQueryResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_POPULAR_NEWS_LIST, {
  GetPopularNewsListQuery,
  PopularNewsQueryVars,
} from '../graphql/get-popular-news-list';

const useGetPopularNewsList = (page: number, period: string) => {
  const { data, error, loading } = useQuery<
    GetPopularNewsListQuery,
    PopularNewsQueryVars
  >(GET_POPULAR_NEWS_LIST, {
    variables: { page, period },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (error) {
      verifyApolloQueryResponse(data, error);
      return;
    }
    if (data?.getPopularNewsList.__typename === 'Error') {
      Toast.error(data.getPopularNewsList.error);
    }
  }, [data, error]);

  return {
    data:
      data?.getPopularNewsList.__typename === 'GetPopularNewsList'
        ? data.getPopularNewsList.news
        : null,
    loading,
    error,
  };
};

export default useGetPopularNewsList;
