import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';
import { PopularNewsListDataType } from '../types/popular-news-list-data-type';

export interface GetPopularNewsListQuery {
  getPopularNewsList:
    | {
        __typename: 'GetPopularNewsList';
        news: PopularNewsListDataType[];
      }
    | GraphqlErrorType;
}

export interface PopularNewsQueryVars {
  page: number;
  period: string;
}

const GET_POPULAR_NEWS_LIST = gql`
  query GetPopularNewsList($page: Int!, $period: String!) {
    getPopularNewsList(page: $page, period: $period) {
      ... on GetPopularNewsList {
        news {
          id
          title
          date
          previewDescription
          previewImage
          previewVideo
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          views
          likesCount
          commentsCount
          isLiked
          isPinned
          bookmarksInfo {
            inBookmarks
          }
          client {
            id
            name
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_POPULAR_NEWS_LIST;
