import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import {
  ModeratedNewsListFiltersType,
  ModeratedNewsListReviewingStatusesType,
} from './use-set-moderated-news-list-filters';
import GET_MODERATED_NEWS_LIST, {
  OneModeratedNewsDataType,
} from '../graphql/get-moderation-news-list';
import GET_ONE_MODERATED_NEWS from '../graphql/get-one-moderated-news';
import START_NEWS_REVIEW, {
  StartNewsReviewMutation,
  StartNewsReviewMutationVars,
} from '../graphql/start-news-review';

const useStartNewsReview = (filters: ModeratedNewsListFiltersType) => {
  const { t } = useTranslation(['news']);
  const [startNewsReviewMutation] = useMutation<
    StartNewsReviewMutation,
    StartNewsReviewMutationVars
  >(START_NEWS_REVIEW, {
    update(cache, { data }) {
      if (data?.startNewsReview.__typename !== 'StartNewsReview') return;

      const newsOnReview = data.startNewsReview;

      const oneModeratedNews: {
        getOneModeratedNews: {
          news: OneModeratedNewsDataType;
          myReview: boolean;
        };
      } | null = cache.readQuery({
        query: GET_ONE_MODERATED_NEWS,
        variables: { id: newsOnReview.newsId },
      });

      if (oneModeratedNews) {
        cache.writeQuery({
          query: GET_ONE_MODERATED_NEWS,
          variables: { id: newsOnReview.newsId },
          data: {
            getOneModeratedNews: {
              ...oneModeratedNews.getOneModeratedNews,
              myReview:
                newsOnReview.reviewInfo.id ===
                ModeratedNewsListReviewingStatusesType.MY,
            },
          },
        });
      }

      const { status, sports, sort, pageNumber, categories } = filters;
      const variables = {
        sort,
        pageNumber,
        filter: {
          categories,
          sports,
          status,
        },
      };

      const moderatedNewsList: {
        getModeratedNewsList: { news: OneModeratedNewsDataType[] };
      } | null = cache.readQuery({
        query: GET_MODERATED_NEWS_LIST,
        variables,
      });

      if (!moderatedNewsList || !newsOnReview) return;

      const updatedModeratedNewsList =
        moderatedNewsList.getModeratedNewsList.news.map((news) => {
          return news.id === newsOnReview.newsId
            ? { ...news, reviewInfo: newsOnReview.reviewInfo }
            : news;
        });

      cache.writeQuery({
        query: GET_MODERATED_NEWS_LIST,
        variables,
        data: {
          getModeratedNewsList: {
            ...moderatedNewsList.getModeratedNewsList,
            news: updatedModeratedNewsList,
          },
        },
      });
    },
  });

  return async (id: string) => {
    try {
      const { data, errors } = await startNewsReviewMutation({
        variables: { id },
      });

      const isStartNewsSuccess = verifyApolloMutationResponse(data, errors);
      if (!isStartNewsSuccess) return;

      if (data?.startNewsReview.__typename === 'Error') {
        Toast.error(data.startNewsReview.error);
        return;
      }

      if (data?.startNewsReview.__typename === 'StartNewsReview') {
        Toast.info(t('news:successes:article_start_review_success'));
        return data.startNewsReview.newsId;
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:article_start_review_error'));
    }
  };
};

export default useStartNewsReview;
