import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { formatDatetime } from 'application/utils/date';
import getScoreMma from 'application/utils/get-score-mma';
import { Button, DropdownButton, Opponent } from 'ui';
import ProgressLine from 'ui/progress-line';
import Switch from 'ui/switch';

import HotOpponent from './hot-opponent';
import css from './hot-tournament.module.scss';
import { HotTournamentResultModel } from '../../graphql/get-hot-tournaments';
import ExtraCard from '../extra-card';

interface HotTournamentProps {
  tournament: string;
  tournaments: { value: string; label: string }[];
  date: Date;
  events: HotTournamentResultModel[];
  onTournamentChange: (tournament: string) => void;
}

const HotTournament: React.FC<HotTournamentProps> = ({
  tournament,
  tournaments,
  date,
  events,
  onTournamentChange,
}) => {
  const { t } = useTranslation(['common', 'vote', 'tournaments', 'bet']);
  const router = useRouter();

  const [event, ...otherEvents] = events;

  const getScoreOpponent1 = () => {
    const { win, draw, lose } = event.opponent1.mmaOpponent;

    return getScoreMma([win, lose, draw]);
  };

  const getScoreOpponent2 = () => {
    const { win, draw, lose } = event.opponent2.mmaOpponent;

    return getScoreMma([win, lose, draw]);
  };

  const switcherItems: { value: 'pari' | 'live'; label: string }[] = [];
  if (event && event.pari) {
    switcherItems.push({ value: 'pari', label: 'PARI' });
  }
  if (event && event.live) {
    switcherItems.push({ value: 'live', label: 'LIVE' });
  }

  const [eventType, setEventType] = useState(switcherItems[0].value);

  const [expanded, setExpanded] = useState(otherEvents.length <= 4);

  const eventsToView = expanded ? otherEvents : otherEvents.splice(0, 4);

  const info = eventType === 'pari' ? event.pari : event.live;

  return (
    <ExtraCard title={t('tournaments:hot_tournament')}>
      <div className={css.Root}>
        <div className={css.Header}>
          <DropdownButton
            value={tournament}
            items={tournaments}
            onChange={onTournamentChange}
            className={css.TournamentDropdown}
            classNameLabel={css.DropdownLabel}
          />
          <div className={css.Date}>{formatDatetime(date)}</div>
          <div className={css.Switcher}>
            {switcherItems.length === 2 && (
              <Switch
                value={eventType}
                items={switcherItems}
                onChange={setEventType}
              />
            )}
          </div>
        </div>
        <div className={css.List}>
          <div className={css.First}>
            <div className={css.FirstItem}>
              <Opponent
                name={event.opponent1.name}
                country={event.opponent1.mmaOpponent.countryFlag}
                rank={
                  event.opponent1.rank || event.opponent1.mmaOpponent.rating
                }
                image={event.opponent1.image}
                score={event.opponent1.score || getScoreOpponent1()}
                coeff={info.coef1.toFixed(2)}
                win={null}
                className={css.OpponentItem}
                onClick={() => router.push(`/tournament/${tournament}`)}
                isCoeffLive={false}
              />
              <div className={css.MainItemDescription}>
                <div className={css.ItemPercents}>
                  {info.percent1}% / {info.percent2}%
                </div>
                <div className={css.ItemProgress}>
                  <ProgressLine value1={info.percent1} value2={info.percent2} />
                </div>
                <div className={css.ItemValue}>{info.amount}&nbsp;vts</div>
                <div className={css.ItemValueDescription}>
                  {t('bets:volume')}
                </div>
                <Link className="noLink" href={`/tournament/${tournament}`}>
                  <Button color="secondary" outline className={css.MainVote}>
                    {t('vote:actions:vote')}
                  </Button>
                </Link>
              </div>
              <Opponent
                name={event.opponent2.name}
                country={event.opponent2.mmaOpponent.countryFlag}
                rank={
                  event.opponent2.rank || event.opponent2.mmaOpponent.rating
                }
                image={event.opponent2.image}
                score={event.opponent2.score || getScoreOpponent2()}
                coeff={info.coef2.toFixed(2)}
                win={null}
                className={css.OpponentItem}
                onClick={() => router.push(`/tournament/${tournament}`)}
                isCoeffLive={false}
              />
            </div>
            <div className={css.FirstItemDescription}>
              <div className={css.MobileValue}>
                <div className={css.ItemValue}>{info.amount}&nbsp;vts</div>
                <div className={css.ItemValueDescription}>
                  {t('bets:volume')}
                </div>
              </div>
              <div className={css.MobileProgressWrapper}>
                {info.percent1}%
                <div className={css.MobileProgress}>
                  <ProgressLine value1={info.percent1} value2={info.percent2} />
                </div>
                {info.percent2}%
              </div>
              <div className={css.CommentWrapper}>
                <div className={css.Comments}>{1}</div>
              </div>
            </div>
          </div>
          <div className={css.EventList}>
            {eventsToView.map((item, index) => {
              const {
                win: win1,
                draw: draw1,
                lose: lose1,
              } = item.opponent1.mmaOpponent;

              const {
                win: win2,
                draw: draw2,
                lose: lose2,
              } = item.opponent2.mmaOpponent;

              return (
                <div className={css.Item} key={index}>
                  <div className={css.ItemOpponent}>
                    <HotOpponent
                      name={item.opponent1.name}
                      score={
                        item.opponent1.score ||
                        getScoreMma([win1, lose1, draw1])
                      }
                      coefficient={
                        eventType === 'pari'
                          ? item.pari.coef1.toFixed(2)
                          : item.live.coef1.toFixed(2)
                      }
                      image={item.opponent1.image}
                      country={item.opponent1.mmaOpponent.countryFlag}
                      percent={
                        eventType === 'pari'
                          ? item.pari.percent1.toFixed(0)
                          : item.live.percent1.toFixed(0)
                      }
                      onClick={() => router.push(`/tournament/${tournament}`)}
                    />
                  </div>
                  <div className={css.ItemDescription}>
                    <div className={css.ItemPercents}>
                      {eventType === 'pari'
                        ? item.pari.percent1.toFixed(0)
                        : item.live.percent1.toFixed(0)}
                      % /{' '}
                      {eventType === 'pari'
                        ? item.pari.percent2.toFixed(0)
                        : item.live.percent2.toFixed(0)}
                      %
                    </div>
                    <Link className="noLink" href={`/tournament/${tournament}`}>
                      <Button
                        className={css.VoteButton}
                        color="secondary"
                        outline
                      >
                        {t('vote:actions:vote')}
                      </Button>
                    </Link>
                  </div>
                  <div className={css.ItemOpponent}>
                    <HotOpponent
                      name={item.opponent2.name}
                      score={
                        item.opponent2.score ||
                        getScoreMma([win2, lose2, draw2])
                      }
                      coefficient={
                        eventType === 'pari'
                          ? item.pari.coef2.toFixed(2)
                          : item.live.coef2.toFixed(2)
                      }
                      image={item.opponent2.image}
                      country={item.opponent2.mmaOpponent.countryFlag}
                      percent={
                        eventType === 'pari'
                          ? item.pari.percent2.toFixed(0)
                          : item.live.percent2.toFixed(0)
                      }
                      onClick={() => router.push(`/tournament/${tournament}`)}
                      inverse
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {!expanded && (
          <div className={css.MoreButton} onClick={() => setExpanded(true)}>
            {t('common:actions:show_more')}
          </div>
        )}
        <div className={css.VoteButtonMobile}>
          <Link className="noLink" href={`/tournament/${tournament}`}>
            <Button color="secondary" outline relative>
              {t('vote:actions:vote')}
            </Button>
          </Link>
        </div>
        <div className={css.Slider}>
          {tournaments.map((item, index) => (
            <div
              key={index}
              className={
                item.value === tournament
                  ? css.ActiveSliderItem
                  : css.SliderItem
              }
              onClick={() => onTournamentChange(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </ExtraCard>
  );
};

export default HotTournament;
