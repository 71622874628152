import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface BanCommentAuthorMutation {
  banCommentAuthor:
    | {
        __typename: 'BanCommentAuthor';
        comment: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface BanCommentAuthorMutationVars {
  id: string;
}

const BAN_COMMENT_AUTHOR = gql`
  mutation BanCommentAuthor($id: ID!) {
    banCommentAuthor(id: $id) {
      __typename
      ... on BanCommentAuthor {
        comment {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default BAN_COMMENT_AUTHOR;
