import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import type { CommentModel } from '../commets-type';

export interface GetCommentsQuery {
  getComments:
    | {
        __typename: 'GetComments';
        comments: CommentModel[];
        level: number;
        totalCount: number;
      }
    | GraphqlErrorType;
}

export interface GetCommentsQueryVars {
  sort: 'POPULAR' | 'LAST' | 'ORDER';
  tournamentId?: string;
  eventId?: string;
  newsId?: string;
  commentId?: string;
  lastCommentId?: string;
}

const GET_COMMENTS = gql`
  query GetComments(
    $tournamentId: ID
    $eventId: ID
    $newsId: ID
    $commentId: ID
    $sort: String
    $lastCommentId: ID
  ) {
    getComments(
      tournamentId: $tournamentId
      eventId: $eventId
      newsId: $newsId
      commentId: $commentId
      sort: $sort
      lastCommentId: $lastCommentId
    ) {
      ... on GetComments {
        comments {
          client {
            id
            name
            avatar
            iq
          }
          comment
          date
          id
          isUpdated
          isLiked
          likesCount
          parentId
          recipient {
            id
            name
          }
          childCount
          tournamentId
          eventId
          newsId
        }
        level
        totalCount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_COMMENTS;
