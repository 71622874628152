import cx from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import useBodyOverflow from 'application/hooks/use-body-overflow';
import { getScrollbarWidth } from 'application/utils/get-scrollbar-width';
import webApp, { LanguageType } from 'application/web-app';
import { EventSportType } from 'features/news/graphql/get-event-sports-list';
import CalendarIcon from 'ui/assets/icons/24/calendar.svg';
import { MobileMenuItemType } from 'ui/header/header';

import css from './mobile-menu.module.scss';
import MoreIcon from './more.svg';
import SportsIcon from './sports.svg';

interface MobileMenuProps {
  activeItem: MobileMenuItemType;
  appLanguages: LanguageType[];
  sports: Omit<EventSportType, 'icon'>[];
  openHowVote: () => void;
  onCreateNewsModalOpen: () => void;
  setActiveItem: (item: MobileMenuItemType) => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  sports,
  activeItem,
  openHowVote,
  appLanguages,
  setActiveItem,
  onCreateNewsModalOpen,
}) => {
  const { t } = useTranslation(['header', 'events', 'vote', 'footer']);
  const { setOverflowed } = useBodyOverflow();
  const scrollbarWidth = getScrollbarWidth();
  const { i18n } = useTranslation();
  const router = useRouter();

  const [currentLanguage, setCurrentLanguage] = useState<LanguageType>(
    appLanguages.find(
      (l) => l.value === (i18n.language || 'ru')
    ) as LanguageType
  );

  useEffect(() => {
    setOverflowed(!!activeItem);
  }, [activeItem, scrollbarWidth, setOverflowed, setActiveItem]);

  const getItemClasses = (item: MobileMenuItemType) =>
    cx(css.MenuItem, activeItem === item && css.MenuItemActive);

  const changeLanguage = async (language: LanguageType) => {
    setCurrentLanguage(language);
    await i18n.changeLanguage(language.value, () => {
      document.cookie = `i18next=${language.value}; max-age=31536000; path=/`;
      router.reload();
    });
  };

  return (
    <>
      <button
        className={cx(css.BurgerMenu, activeItem && css.BurgerMenuCross)}
        onClick={() =>
          setActiveItem(
            activeItem ? MobileMenuItemType.NONE : MobileMenuItemType.SPORTS
          )
        }
      >
        <span className={css.BurgerMenuItem}></span>
        <span className={css.BurgerMenuItem}></span>
        <span className={css.BurgerMenuItem}></span>
      </button>
      {activeItem && (
        <div
          className={css.OverPage}
          onClick={() => setActiveItem(MobileMenuItemType.NONE)}
        />
      )}
      {activeItem && (
        <div className={css.MenuContainer}>
          <ul className={css.Menu}>
            <li
              className={getItemClasses(MobileMenuItemType.LANGUAGE)}
              onClick={() => setActiveItem(MobileMenuItemType.LANGUAGE)}
            >
              <div className={css.CurrentLanguageIconContainer}>
                {currentLanguage.icon}
              </div>
              <p className={css.MenuItemText}>{t('header:language')}</p>
            </li>
            <li
              className={getItemClasses(MobileMenuItemType.SPORTS)}
              onClick={() => setActiveItem(MobileMenuItemType.SPORTS)}
            >
              <SportsIcon className={css.MenuItemIcon} width={34} height={34} />
              <p className={css.MenuItemText}>{t('header:sports')}</p>
            </li>
            <li
              className={cx(css.MenuItem)}
              onClick={() => {
                setActiveItem(MobileMenuItemType.NONE);
                router.push('/events-calendar');
              }}
            >
              <CalendarIcon
                className={css.MenuItemIcon}
                width={27}
                height={27}
              />
              <p className={css.MenuItemText}>{t('events:calendar')}</p>
            </li>
            <li
              className={getItemClasses(MobileMenuItemType.MORE)}
              onClick={() => setActiveItem(MobileMenuItemType.MORE)}
            >
              <MoreIcon className={css.MenuItemIcon} width={26} height={26} />
              <p className={css.MenuItemText}>{t('header:more')}</p>
            </li>
          </ul>

          {activeItem === MobileMenuItemType.MORE && (
            <ul className={css.Submenu}>
              <li
                key="how-vote"
                className={css.SubmenuItem}
                onClick={openHowVote}
              >
                {t('vote:labels:how_vote')}
              </li>
              <li
                key="create-news"
                className={css.SubmenuItem}
                onClick={() => {
                  setActiveItem(MobileMenuItemType.NONE);
                  onCreateNewsModalOpen();
                }}
              >
                {t('header:create_article')}
              </li>
              <li
                key="create-news"
                className={css.SubmenuItem}
                onClick={() => {
                  router.push('/about');
                }}
              >
                {t('footer:about_us')}
              </li>
              <li
                key="create-news"
                className={css.SubmenuItem}
                onClick={() => {
                  router.push('/articles');
                }}
              >
                {t('footer:articles')}
              </li>
            </ul>
          )}

          {activeItem === MobileMenuItemType.SPORTS && (
            <ul className={css.Submenu}>
              <li
                className={cx(
                  css.SubmenuItem,
                  !router.query.url && css.SubmenuItemActive
                )}
                onClick={() => {
                  setActiveItem(MobileMenuItemType.NONE);
                  router.push(`/`);
                }}
              >
                {t('header:all')}
              </li>

              {sports.map((sport) => (
                <li
                  key={sport.id}
                  className={cx(
                    css.SubmenuItem,
                    router.query.url === sport.alias && css.SubmenuItemActive
                  )}
                  onClick={() => {
                    setActiveItem(MobileMenuItemType.NONE);
                    webApp.router.getSport(sport.alias);
                    router.push(`/${sport.alias}`);
                  }}
                >
                  {sport.name}
                </li>
              ))}
            </ul>
          )}

          {activeItem === MobileMenuItemType.LANGUAGE && (
            <ul className={css.Submenu}>
              {appLanguages.map((lang) => (
                <li
                  className={cx(
                    css.SubmenuItem,
                    lang.value === currentLanguage.value &&
                      css.SubmenuItemActive
                  )}
                  key={lang.value}
                  onClick={() => changeLanguage(lang)}
                >
                  {lang.icon}
                  {lang.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};
export default MobileMenu;
