import React from 'react';

import getCountryFlag from 'application/utils/get-country-flag';

import css from './hot-opponent.module.scss';

interface HotOpponentProps {
  name: string;
  country: string | null;
  score: string;
  coefficient: string;
  percent: string;
  image: string | null;
  inverse?: boolean;
  onClick?: () => void;
}

const HotOpponent: React.FC<HotOpponentProps> = ({
  name,
  country,
  score,
  percent,
  coefficient,
  image,
  inverse,
  onClick,
}) => {
  const CountryNode = getCountryFlag(country);

  const splittedName = name.split(' ');
  if (splittedName.length > 1) {
    splittedName[0] = `${splittedName[0][0]}. `;
    splittedName.length = 2;
  }

  return (
    <div className={inverse ? css.InverseRoot : css.Root} onClick={onClick}>
      <div className={css.Info}>
        <div className={css.Name}>
          {CountryNode && (
            <div className={css.Country}>
              <CountryNode />
            </div>
          )}
          <div className={css.Text}>{splittedName.join(' ')}</div>
        </div>
        <div className={css.Score}>{score}</div>
        <div className={css.PercentInfo}>
          <div className={css.Percent}>{percent}%</div>
          <div className={css.Circle} />
          <div className={css.Coefficient}>{coefficient}</div>
        </div>
      </div>
      <div
        className={css.Image}
        style={{ backgroundImage: `url(${image || '/images/no-image.png'})` }}
      />
    </div>
  );
};

export default HotOpponent;
