import { useTranslation } from 'next-i18next';
import React from 'react';

import { ConfirmModal } from 'ui';

interface DeleteCommentModalProps {
  isDeleteCommentModalOpen: boolean;
  deleteComment: () => void;
  closeModal: () => void;
}

const DeleteCommentModal: React.FC<DeleteCommentModalProps> = ({
  isDeleteCommentModalOpen,
  closeModal,
  deleteComment,
}) => {
  const { t } = useTranslation(['comments']);

  return (
    <ConfirmModal
      isModalOpen={isDeleteCommentModalOpen}
      callback={deleteComment}
      closeModal={closeModal}
    >
      <p>{t('comments:delete_comment_confirm')}</p>
    </ConfirmModal>
  );
};

export default DeleteCommentModal;
