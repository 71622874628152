import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import useDebounce from 'application/hooks/use-debounce';
import { Toast } from 'ui';
import { refactorEditorBlocks } from 'ui/editor/refactor-editor-blocks';

import GET_CLIENT_COUNTS from '../../graphql/get-сlient-сounts';
import SAVE_NEWS, {
  SaveNewsMutation,
  SaveNewsMutationVars,
} from '../../graphql/save-news';
import useEditUnpublishedNews from '../../hooks/use-edit-unpublished-news';
import useLoadWidgetData from '../../hooks/use-load-widget-data';
import useUploadImage from '../../hooks/use-upload-image';
import useUploadVideo from '../../hooks/use-upload-video';
import { NewsFormDataType } from '../../types/news-form-data-type';
import { NewsSaveStatusType } from '../../types/news-save-status-type';
import CreateNewsModalFooter from '../create-news-modal-footer';
import EditNewsModal from '../edit-news-modal';

interface CreateNewsModalContainerProps {
  closeModal: () => void;
  newsId?: string;
}

const CreateNewsModalContainer: React.FC<CreateNewsModalContainerProps> = ({
  newsId,
  closeModal,
}) => {
  const { t } = useTranslation(['news']);

  const [newsSaveStatus, setNewsSaveStatus] = useState<NewsSaveStatusType>(
    NewsSaveStatusType.NONE
  );
  const [isDataLoaded, setDataLoaded] = useState(false);

  const [saveNewsMutation] = useMutation<
    SaveNewsMutation,
    SaveNewsMutationVars
  >(SAVE_NEWS, {
    refetchQueries: [{ query: GET_CLIENT_COUNTS }],
  });

  const { newsFormData, newsFormDataLoading, updateNewsFormData } =
    useEditUnpublishedNews(newsId);

  const loadWidgetLink = useLoadWidgetData();
  const uploadImage = useUploadImage();
  const uploadVideo = useUploadVideo();

  const isNewsDataReady =
    !newsId || (newsId && newsFormData && !newsFormDataLoading);

  const saveNews = async (newsFormData: NewsFormDataType) => {
    const {
      id,
      sportId,
      categoryId,
      title,
      previewDescription,
      previewImage,
      previewVideo,
      blocks,
      previewUploadDescription,
    } = newsFormData;

    const isNewsDataUpdated =
      title || previewImage || previewVideo || blocks.length > 0;

    if (!sportId || !categoryId || !isNewsDataUpdated) {
      return;
    }

    const newBlocks = refactorEditorBlocks(blocks);

    try {
      const { errors, data } = await saveNewsMutation({
        variables: {
          id,
          input: {
            title,
            previewDescription,
            previewImage,
            previewVideo,
            categoryId,
            sportId,
            blocks: newBlocks,
            previewUploadDescription,
          },
        },
      });

      const isLoadNewsSuccess = verifyApolloMutationResponse(data, errors);
      if (!isLoadNewsSuccess) {
        setNewsSaveStatus(NewsSaveStatusType.SAVE_ERROR);
        return;
      }

      if (data?.saveNews.__typename === 'Error') {
        Toast.error(data.saveNews.error);
        setNewsSaveStatus(NewsSaveStatusType.SAVE_ERROR);
        return;
      }

      if (
        data &&
        data.saveNews.__typename === 'SaveNews' &&
        data.saveNews.news.id
      ) {
        setNewsSaveStatus(NewsSaveStatusType.SAVED);
        updateNewsFormData('id', data.saveNews.news.id);
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:draft_save_error'));
    }
  };

  const debouncedSaveNews = useDebounce(saveNews, 1000);

  useEffect(() => {
    if (isNewsDataReady) {
      setDataLoaded(true);
    }
  }, [isNewsDataReady]);

  const setNewsSaveStatusToSaving = () => {
    if (newsSaveStatus !== NewsSaveStatusType.SAVING) {
      setNewsSaveStatus(NewsSaveStatusType.SAVING);
    }
  };

  const createSaveNewsDataFunction = (
    field: keyof NewsFormDataType,
    value: any
  ) => {
    return function () {
      if (
        (field === 'sportId' || field === 'categoryId') &&
        newsFormData[field] !== null &&
        newsFormData.id
      ) {
        setNewsSaveStatusToSaving();
      } else if (field !== 'sportId' && field !== 'categoryId') {
        setNewsSaveStatusToSaving();
      }

      updateNewsFormData(field, value, debouncedSaveNews);
    };
  };

  const uploadPreviewImage = async (file: File) => {
    if (newsSaveStatus !== NewsSaveStatusType.SAVING) {
      setNewsSaveStatus(NewsSaveStatusType.SAVING);
    }

    const result = await uploadImage(file);
    if (result.success === 1) {
      updateNewsFormData('previewImage', result.file.url, debouncedSaveNews);
    }
  };

  const uploadPreviewVideo = async (file: File) => {
    if (newsSaveStatus !== NewsSaveStatusType.SAVING) {
      setNewsSaveStatus(NewsSaveStatusType.SAVING);
    }

    const result = await uploadVideo(file);
    if (result.success === 1) {
      updateNewsFormData('previewVideo', result.file.url, debouncedSaveNews);
    }
  };

  if (!isDataLoaded) {
    return null;
  }

  return (
    <EditNewsModal
      previewUploadDescription={newsFormData.previewUploadDescription}
      newsData={newsFormData}
      uploadNewsImage={uploadImage}
      uploadNewsVideo={uploadVideo}
      loadNewsWidgetLink={loadWidgetLink}
      uploadPreviewImage={uploadPreviewImage}
      uploadPreviewVideo={uploadPreviewVideo}
      saveNewsTitle={(title) => createSaveNewsDataFunction('title', title)()}
      saveSportId={(sportId) =>
        createSaveNewsDataFunction('sportId', sportId)()
      }
      saveCategoryId={(categoryId) =>
        createSaveNewsDataFunction('categoryId', categoryId)()
      }
      saveEditorBlocks={(blocks) =>
        createSaveNewsDataFunction('blocks', blocks)()
      }
      saveNewsPreviewUploadDescription={(previewUploadDescription) =>
        createSaveNewsDataFunction(
          'previewUploadDescription',
          previewUploadDescription
        )()
      }
      saveNewsPreviewDescription={(previewDescription) =>
        createSaveNewsDataFunction('previewDescription', previewDescription)()
      }
      closeModal={closeModal}
    >
      <CreateNewsModalFooter
        newsId={newsFormData.id}
        newsSaveStatus={newsSaveStatus}
        closeModal={closeModal}
      />
    </EditNewsModal>
  );
};

export default CreateNewsModalContainer;
