import React, { MutableRefObject } from 'react';

import { Loader } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import useWindowDimensions from '../../../../application/hooks/use-window-dimensions';
import TopVotersWidget from '../../../vote/components/top-voters-widget';
import { ComponentOrigins } from '../../types/component-origins';
import { OnePublishedNewsDataType } from '../../types/one-published-news-data-type';
import PublishedNewsPreview from '../published-news-preview';

interface NewsFeedProps {
  triggerRef?: MutableRefObject<HTMLDivElement | null>;
  news: OnePublishedNewsDataType[];
  loading: boolean;
  sport?: string;
  showWidgets?: boolean;
}

const NewsFeed: React.FC<NewsFeedProps> = ({
  news,
  sport,
  loading,
  showWidgets,
  triggerRef,
}) => {
  const pinnedNews = news.find((el) => el.isPinned);
  const otherNews = news.filter((el) => !el.isPinned);
  const { width } = useWindowDimensions();
  const MOBILE_WIDTH = 767;

  return (
    <>
      <Flex flexDirection="column" gap={GridGap.x3}>
        {pinnedNews && (
          <>
            <PublishedNewsPreview
              news={pinnedNews}
              componentOrigin={ComponentOrigins.NEWS_LIST}
              isPinned
            />
            {showWidgets && width <= MOBILE_WIDTH && (
              <TopVotersWidget sport={sport} />
            )}
          </>
        )}
        {!pinnedNews && showWidgets && width <= MOBILE_WIDTH && (
          <TopVotersWidget sport={sport} />
        )}
        {otherNews.map((item, index) => {
          const isLastEl = otherNews.length === index + 1;

          return (
            <PublishedNewsPreview
              news={item}
              key={item.id + 'news_preview'}
              componentOrigin={ComponentOrigins.NEWS_LIST}
              triggerRef={isLastEl ? triggerRef : undefined}
            />
          );
        })}
      </Flex>
      {loading && (
        <Flex alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      )}
    </>
  );
};

export default NewsFeed;
