import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type ClientTransactionModel = {
  id: string;
  date: string;
  eventName?: string;
  outcomeName?: string;
  typeName?: string;
  name?: string;
  amount: number;
  tokenQuantity: number;
  showTokenQuantity: boolean;
};

export interface GetMyClientTransactionsQuery {
  getMyClientTransactions:
    | {
        __typename: 'GetMyClientTransactions';
        transactions: ClientTransactionModel[];
        nav: {
          offset: number;
          hasNext: boolean;
        };
      }
    | GraphqlErrorType;
}

export interface GetMyClientTransactionsQueryVars {
  wallet: string;
  transactionsType: 'trading' | 'balance';
  nav: {
    limit: number;
    lastId?: number;
  };
}

const GET_MY_CLIENT_TRANSACTIONS = gql`
  query GetMyClientTransactions(
    $wallet: String!
    $transactionsType: String!
    $nav: GetMyClientTransactionsNavQuery!
  ) {
    getMyClientTransactions(
      wallet: $wallet
      transactionsType: $transactionsType
      nav: $nav
    ) {
      __typename
      ... on GetMyClientTransactions {
        transactions {
          id
          date
          eventName
          outcomeName
          typeName
          name
          amount
          tokenQuantity
          showTokenQuantity
        }
        nav {
          limit
          hasNext
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_MY_CLIENT_TRANSACTIONS;
