import { LanguageType } from '../types/language-type';

export const getLanguage = (cookie?: string): LanguageType => {
  const c =
    cookie ?? (typeof window !== 'undefined' ? document.cookie : undefined);

  const match = c?.match(/i18next=([^;]*)/);

  return match ? (match[1] as LanguageType) : 'ru';
};
