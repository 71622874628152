import React, { useEffect, useState } from 'react';

import NextEvents from './next-events';
import useNextEvents from './use-next-events';

const NextEventsConnected: React.FC<{ sport?: string }> = ({ sport }) => {
  const {
    loading,
    events: { tournaments, events },
  } = useNextEvents(sport);

  const [tournament, setTournament] = useState<string | null>(null);

  useEffect(() => {
    if (tournaments.length > 0) {
      setTournament(tournaments[0].id);
    }
  }, [tournaments, events]);

  return (
    <NextEvents
      loading={loading}
      tournament={tournament!}
      tournaments={tournaments}
      events={events.filter((item) => item.tournamentId === tournament)}
      onTournamentSelect={setTournament}
    />
  );
};

export default NextEventsConnected;
