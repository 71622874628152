import cx from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import webApp from 'application/web-app';
import { IndexCard, Tabs } from 'ui';
import WidgetHeader from 'ui/widget-header';

import GreenArrow from './green-arrow.svg';
import NoArrow from './no-arrow.svg';
import RedArrow from './red-arrow.svg';
import css from './vote-leaders.module.scss';

export type VoteLeadersPeriods = 'TODAY' | 'WEEK' | 'MONTH';

export type VoteLeader = {
  id: string;
  tournamentId: string;
  position: number;
  positionStatus: boolean | null;
  name: string;
  coeff1: string;
  coeff2: string;
  rating: string;
};

interface VoteLeadersProps {
  votes: VoteLeader[];
  period: VoteLeadersPeriods;
  onPeriodChange: (period: VoteLeadersPeriods) => void;
}

const VoteLeaders: React.FC<VoteLeadersProps> = ({
  votes,
  period,
  onPeriodChange,
}) => {
  const { t } = useTranslation(['common', 'vote-leaders-widget']);

  return (
    <IndexCard small>
      <WidgetHeader href="" title={t('vote-leaders-widget:label')} />

      <div className={css.Tabs}>
        <Tabs
          value={period}
          items={[
            { value: 'TODAY', label: t('common:period:today') },
            { value: 'WEEK', label: t('common:period:week') },
            { value: 'MONTH', label: t('common:period:month') },
          ]}
          onSelect={onPeriodChange}
          small
        />
      </div>

      {votes.length === 0 && (
        <p className={css.NoData}>{t('vote-leaders-widget:empty')} </p>
      )}

      {votes.map((item) => (
        <Link
          key={item.id}
          className={cx(css.Link, 'noLink')}
          href={webApp.router.getTournamnt(item.tournamentId, {
            eventId: item.id,
          })}
        >
          <div className={css.Item}>
            <div className={css.Position}>
              <span>{item.position}</span>
              <span>
                {item.positionStatus === true && <GreenArrow />}
                {item.positionStatus === false && <RedArrow />}
                {item.positionStatus === null && <NoArrow />}
              </span>
            </div>
            <div className={css.Name}>
              <span className={css.Title}>{item.name}</span>
              <div className={css.Description}>
                <span>{item.coeff1}</span>/<span>{item.coeff2}</span>
              </div>
            </div>
            <div className={css.Rating}>+{item.rating}</div>
          </div>
        </Link>
      ))}
    </IndexCard>
  );
};

export default VoteLeaders;
