import cx from 'classnames';
import React, { Ref, forwardRef, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import css from './input.module.scss';
import MinusIcon from './minus-icon.svg';
import PlusIcon from './plus-icon.svg';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  ref?: Ref<any>;
  type?: 'input' | 'email' | 'password' | 'tel' | 'number';
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  right?: React.ReactNode;
  error?: string;
  mask?: string;
  disabled?: boolean;
  autoComplete?: string;
  className?: string;
  postfix?: string;
  tabIndex?: number;
  step?: number;
  min?: number;
  max?: number;
  showSpinner?: boolean;
}

// eslint-disable-next-line react/display-name
const Input: React.FC<InputProps> = forwardRef(
  (
    {
      type = 'input',
      value,
      placeholder,
      right,
      error,
      mask,
      disabled,
      autoComplete,
      onChange,
      className,
      postfix = '',
      tabIndex,
      step = 1,
      min,
      max,
      showSpinner = false,
      ...props
    },
    ref
  ) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const handleIncrement = () => {
      let newValue = (parseFloat(localValue) || 0) + step;
      if (max !== undefined) newValue = Math.min(newValue, max);
      setLocalValue(newValue.toString());
      onChange(newValue.toString());
    };

    const handleDecrement = () => {
      let newValue = (parseFloat(localValue) || 0) - step;
      if (min !== undefined) newValue = Math.max(newValue, min);
      setLocalValue(newValue.toString());
      onChange(newValue.toString());
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value;
      setLocalValue(newValue);
      onChange(newValue);
    };

    return (
      <div className={css.Root}>
        <div className={css.InputWrapper}>
          {mask ? (
            <InputMask
              ref={ref}
              mask={mask}
              type={type === 'number' ? 'text' : type}
              className={cx(
                error ? css.InputError : css.Input,
                className,
                type === 'number' && css.NumberInput
              )}
              value={localValue}
              placeholder={placeholder}
              disabled={disabled}
              autoComplete={autoComplete}
              onChange={handleChange}
            />
          ) : (
            <input
              ref={ref}
              tabIndex={tabIndex}
              type={type}
              className={cx(
                error ? css.InputError : css.Input,
                className,
                type === 'number' && css.NumberInput
              )}
              value={`${localValue}${postfix}`}
              placeholder={placeholder}
              disabled={disabled}
              autoComplete={autoComplete}
              onChange={handleChange}
              {...props}
            />
          )}
          {showSpinner && (
            <>
              <button
                type="button"
                className={css.SpinnerButtonLeft}
                onClick={handleDecrement}
                disabled={
                  disabled ||
                  (min !== undefined && parseFloat(localValue) <= min)
                }
              >
                <MinusIcon />
              </button>
              <button
                type="button"
                className={css.SpinnerButtonRight}
                onClick={handleIncrement}
                disabled={
                  disabled ||
                  (max !== undefined && parseFloat(localValue) >= max)
                }
              >
                <PlusIcon />
              </button>
            </>
          )}
        </div>
        {right && <div className={css.Right}>{right}</div>}
        {error && <p className={css.Error}>{error}</p>}
      </div>
    );
  }
);

export default Input;
