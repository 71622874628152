export type GetCommentsRefetchVars = {
  sort: 'POPULAR' | 'LAST' | 'ORDER';
  tournamentId?: string;
  eventId?: string;
  newsId?: string;
  commentId?: string;
  lastCommentId?: string;
  [key: string]: string | undefined;
};

export const removeEmptyProperties = (obj: GetCommentsRefetchVars) => {
  const cleanedObject = { ...obj };

  Object.keys(cleanedObject).forEach((key) => {
    if (!cleanedObject[key]) {
      delete cleanedObject[key];
    }
  });

  return cleanedObject;
};
