import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import DELETE_MY_UNPUBLISHED_NEWS, {
  DeleteMyUnpublishedNewsMutation,
  DeleteMyUnpublishedNewsMutationVars,
} from '../graphql/delete-my-unpublished-news';
import GET_CLIENT_COUNTS from '../graphql/get-сlient-сounts';

const useDeleteMyUnpublishedNews = () => {
  const { t } = useTranslation(['news']);
  const [deleteNewsMutation] = useMutation<
    DeleteMyUnpublishedNewsMutation,
    DeleteMyUnpublishedNewsMutationVars
  >(DELETE_MY_UNPUBLISHED_NEWS, {
    refetchQueries: [{ query: GET_CLIENT_COUNTS }],
  });

  return async (id: string) => {
    try {
      const { data, errors } = await deleteNewsMutation({
        variables: { id },
      });

      const isDeleteNewsSuccess = verifyApolloMutationResponse(data, errors);
      if (!isDeleteNewsSuccess) return;

      if (data?.deleteMyUnpublishedNews.__typename === 'Error') {
        Toast.error(data.deleteMyUnpublishedNews.error);
        return;
      }

      if (
        data?.deleteMyUnpublishedNews.__typename === 'DeleteMyUnpublishedNews'
      ) {
        Toast.success(t('news:successes:article_delete_success'));
        return data.deleteMyUnpublishedNews.news.id;
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:article_delete_error'));
    }
  };
};

export default useDeleteMyUnpublishedNews;
