const config = {
  graphqlApiUrl: process.env.NEXT_PUBLIC_GRAPHQL_API_URL || '',
  graphqlPrivateApiUrl: process.env.NEXT_PRIVATE_GRAPHQL_API_URL || '',
  graphqlWsUrl: process.env.NEXT_PUBLIC_GRAPHQL_WS_URL || '',
  recaptchKey: process.env.NEXT_PUBLIC_RECAPTCHA || '',
  gaId: process.env.NEXT_PUBLIC_GA_ID || '',
  firebase:
    process.env.NEXT_PUBLIC_FCM_ENABLED !== '1'
      ? null
      : {
          apiKey: process.env.NEXT_PUBLIC_FCM_API_KEY,
          authDomain: process.env.NEXT_PUBLIC_FCM_AUTH_DOMAIN,
          projectId: process.env.NEXT_PUBLIC_FCM_PROJECT_ID,
          storageBucket: process.env.NEXT_PUBLIC_FCM_STORAGE_BUCKET,
          messagingSenderId: process.env.NEXT_PUBLIC_FCM_MESSAGING_SENDER_ID,
          appId: process.env.NEXT_PUBLIC_FCM_APP_ID,
        },
  pendoId: process.env.NEXT_PUBLIC_PENDO_ID,
  yandexId: process.env.NEXT_PUBLIC_YANDEX_ID,
};

export default config;
