import React from 'react';

import { CommentModel } from '../../commets-type';
import Comment from '../comment';
import CommentsListContainer from '../comments-list';

interface CommentsTreeProps {
  comments: CommentModel[];
  updateCommentsData: () => void;
}

const CommentsTree: React.FC<CommentsTreeProps> = ({
  comments,
  updateCommentsData,
}) => {
  return (
    <div>
      {comments.map((comment) => (
        <React.Fragment key={comment.id}>
          <Comment comment={comment} updateCommentsData={updateCommentsData} />

          {comment.childCount > 0 && (
            <CommentsListContainer commentId={comment.id} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CommentsTree;
