import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface DeleteMyCommentMutation {
  deleteMyComment:
    | {
        __typename: 'DeleteMyComment';
        eventId: string;
        newsId: string;
        tournamentId: string;
        commentId: string;
        totalCount: number;
      }
    | GraphqlErrorType;
}

export interface DeleteMyCommentMutationVars {
  id: string;
}

const DELETE_MY_COMMENT = gql`
  mutation DeleteMyComment($id: ID!) {
    deleteMyComment(id: $id) {
      ... on DeleteMyComment {
        eventId
        newsId
        tournamentId
        commentId
        totalCount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default DELETE_MY_COMMENT;
