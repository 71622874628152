import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import useBodyOverflow from 'application/hooks/use-body-overflow';
import useWindowDimensions from 'application/hooks/use-window-dimensions';
import { IndexCard } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './index-page.module.scss';
import NewsFeed from '../../news/components/news-feed';
import HowVote from '../../vote/components/how-vote';
import TopVoters from '../../vote/components/top-voters-widget';
import VoteLeaders from '../../vote/components/vote-leaders';
import Contacts from '../components/contacts';
import HotTournamentConnected from '../components/hot-tournament';
import LastAnnounce from '../components/last-announce';
import LastComments from '../components/last-comments';
import NextEvents from '../components/next-events';
import NextTournaments from '../components/next-tournaments';
import { TopNewsWidget } from '../components/top-news-widget/top-news-widget';

const DESKTOP_WIDTH = 1334;
const MOBILE_WIDTH = 1014;

const IndexPage: React.FC<{ sport?: string }> = ({ sport }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openHowVote, setOpenHowVote] = useState(false);
  const { mounted, width } = useWindowDimensions();
  const { setOverflowed } = useBodyOverflow();
  const { t } = useTranslation(['common']);
  const router = useRouter();

  const handlers = useSwipeable({
    onSwipedLeft: () => setOpenMenu(true),
    onSwipedRight: () => setOpenMenu(false),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    if ('how-vote' in router.query) {
      setOpenHowVote(true);
    } else {
      setOpenHowVote(false);
    }
  }, [router.query]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('loading');
    }
  }, []);

  useEffect(() => {
    setOverflowed(openMenu && width < MOBILE_WIDTH);
  }, [openMenu, width]);

  const LeftMenu = (
    <>
      <TopVoters sport={sport} />
      <VoteLeaders sport={sport} />
      <LastComments sport={sport} />
    </>
  );

  return (
    <div className={css.Root} {...handlers}>
      <div className={css.Center}>
        <TopNewsWidget />
        <div className={css.Selector} onClick={() => setOpenMenu(true)}>
          <IndexCard>{t('common:labels:current')}</IndexCard>
        </div>
        {!openHowVote && <HotTournamentConnected sport={sport} />}
        {openHowVote ? <HowVote /> : <NewsFeed />}
      </div>

      {(!mounted || width >= DESKTOP_WIDTH) && (
        <div className={css.Left} aria-hidden="true">
          <Flex gap={GridGap.x3} flexDirection="column">
            {LeftMenu}
            <Contacts />
          </Flex>
        </div>
      )}

      <div className={openMenu ? css.OpenRight : css.Right}>
        <div className={css.MobileMenu}>
          <div
            className={css.MobileMenuBack}
            onClick={() => setOpenMenu(false)}
          />
          <div className={css.MobileMenuTitle}>
            {t('common:labels:current')}
          </div>
        </div>

        <Flex
          flexDirection="column"
          gap={GridGap.x3}
          className={css.WidgetsContainer}
        >
          <NextTournaments sport={sport} />
          {mounted && width < DESKTOP_WIDTH && <>{LeftMenu}</>}
          <NextEvents sport={sport} />
          <LastAnnounce sport={sport} />
          {mounted && width < DESKTOP_WIDTH && (
            <>
              <Contacts />
            </>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default IndexPage;
