import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { Toast } from 'ui';

import { verifyApolloMutationResponse } from '../../../application/graphql/utils';
import PIN_PUBLISHED_NEWS, {
  pinPublishedNewsMutation,
  pinPublishedNewsQueryMutationVars,
} from '../graphql/pin-published-news';

const usePinPublishedNews = () => {
  const { t } = useTranslation(['news']);
  const [pinPublishedNewsMutation] = useMutation<
    pinPublishedNewsMutation,
    pinPublishedNewsQueryMutationVars
  >(PIN_PUBLISHED_NEWS);

  return async (newsId: string, pin: boolean) => {
    try {
      const { data, errors } = await pinPublishedNewsMutation({
        variables: { newsId, pin },
      });

      const isPinNewsSuccess = verifyApolloMutationResponse(data, errors);

      if (!isPinNewsSuccess) return;

      if (data?.pinPublishedNews.__typename === 'Error') {
        Toast.error(data.pinPublishedNews.error);
        return;
      }

      if (data?.pinPublishedNews.__typename === 'PinPublishedNews') {
        return data.pinPublishedNews.news.id;
      }
    } catch (e: unknown) {
      Toast.error(t('common:errors:something_went_wrong'));
    }
  };
};

export default usePinPublishedNews;
