import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import {
  ModeratedNewsListFiltersType,
  ModeratedNewsListReviewingStatusesType,
} from './use-set-moderated-news-list-filters';
import CANCEL_NEWS_REVIEW, {
  CancelNewsReviewMutation,
  CancelNewsReviewMutationVars,
} from '../graphql/cancel-news-review';
import GET_MODERATED_NEWS_LIST, {
  OneModeratedNewsDataType,
} from '../graphql/get-moderation-news-list';
import GET_ONE_MODERATED_NEWS from '../graphql/get-one-moderated-news';

const useCancelNewsReview = (filters: ModeratedNewsListFiltersType) => {
  const { t } = useTranslation(['news']);
  const [cancelNewsReviewMutation] = useMutation<
    CancelNewsReviewMutation,
    CancelNewsReviewMutationVars
  >(CANCEL_NEWS_REVIEW, {
    update(cache, { data }) {
      if (data?.cancelNewsReview.__typename !== 'CancelNewsReview') return;

      const canceledReviewNews = data.cancelNewsReview;

      const oneModeratedNews: {
        getOneModeratedNews: {
          news: OneModeratedNewsDataType;
          myReview: boolean;
        };
      } | null = cache.readQuery({
        query: GET_ONE_MODERATED_NEWS,
        variables: { id: canceledReviewNews.newsId },
      });

      if (oneModeratedNews) {
        cache.writeQuery({
          query: GET_ONE_MODERATED_NEWS,
          variables: { id: canceledReviewNews.newsId },
          data: {
            getOneModeratedNews: {
              ...oneModeratedNews.getOneModeratedNews,
              myReview:
                canceledReviewNews.reviewInfo.id ===
                ModeratedNewsListReviewingStatusesType.MY,
            },
          },
        });
      }

      const { status, sports, sort, pageNumber, categories } = filters;
      const variables = {
        sort,
        pageNumber,
        filter: {
          categories,
          sports,
          status,
        },
      };

      const moderatedNewsList: {
        getModeratedNewsList: { news: OneModeratedNewsDataType[] };
      } | null = cache.readQuery({
        query: GET_MODERATED_NEWS_LIST,
        variables,
      });

      if (!moderatedNewsList || !canceledReviewNews) return;

      const updatedModeratedNewsList =
        moderatedNewsList.getModeratedNewsList.news.map((news) => {
          return news.id === canceledReviewNews.newsId
            ? { ...news, reviewInfo: canceledReviewNews.reviewInfo }
            : news;
        });

      cache.writeQuery({
        query: GET_MODERATED_NEWS_LIST,
        variables,
        data: {
          getModeratedNewsList: {
            ...moderatedNewsList.getModeratedNewsList,
            news: updatedModeratedNewsList,
          },
        },
      });
    },
  });

  return async (id: string) => {
    try {
      const { data, errors } = await cancelNewsReviewMutation({
        variables: { id },
      });

      const isCancelReviewSuccess = verifyApolloMutationResponse(data, errors);
      if (!isCancelReviewSuccess) return;

      if (data?.cancelNewsReview.__typename === 'Error') {
        Toast.error(data.cancelNewsReview.error);
        return;
      }

      if (data?.cancelNewsReview.__typename === 'CancelNewsReview') {
        Toast.info(t('news:successes:article_cancel_review_success'));
        return data.cancelNewsReview.newsId;
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:article_cancel_review_error'));
    }
  };
};

export default useCancelNewsReview;
