import cx from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './select.module.scss';

interface DropdownButtonProps<T> {
  value: T;
  items: { value: T; label: string; icon?: string | ReactNode }[];
  onChange: (value: T) => void;
  error?: string;
  placeholder?: string;
  title?: string;
}

type DropdownButtonFC = <T>(
  props: DropdownButtonProps<T>
) => React.ReactElement<DropdownButtonProps<T>>;

const DropdownButton: DropdownButtonFC = ({
  value,
  items,
  error,
  placeholder,
  onChange,
  title,
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handler);
    }

    return () => document.removeEventListener('click', handler);
  }, [isOpen]);

  const selectedItem = items.find((item) => item.value === value);

  return (
    <>
      {title && <div>{title}</div>}
      <div className={css.Root}>
        <div
          className={css.Select}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!isOpen);
          }}
        >
          {selectedItem ? (
            <>
              {selectedItem.icon &&
                (typeof selectedItem.icon === 'string' ? (
                  <img src={selectedItem.icon} alt={selectedItem.label} />
                ) : (
                  selectedItem.icon
                ))}
              <p className={css.SelectedItemLabel}>{selectedItem.label}</p>
            </>
          ) : (
            <div className={css.Placeholder}>{placeholder}</div>
          )}
        </div>

        {isOpen && (
          <ul className={css.List} ref={ref}>
            {items.map((item, index) => (
              <li
                key={index}
                className={cx(css.Item, item.value === value && css.ItemActive)}
                onClick={() => {
                  onChange(item.value);
                  setOpen(false);
                }}
              >
                <Flex alignItems="center" gap={GridGap.x1}>
                  {item.icon &&
                    (typeof item.icon === 'string' ? (
                      <img src={item.icon} alt={item.label} />
                    ) : (
                      item.icon
                    ))}
                  {item.label}
                </Flex>
              </li>
            ))}
          </ul>
        )}
        {error && <div className={css.Error}>{error}</div>}
      </div>
    </>
  );
};

export default DropdownButton;
