import { useMutation } from '@apollo/client';
import { i18n } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import { CommentModel } from '../commets-type';
import GET_COMMENTS, { GetCommentsQueryVars } from '../graphql/get-comments';
import LIKE_COMMENT, {
  LikeCommentMutation,
  LikeCommentMutationVars,
} from '../graphql/like-comment';

const useLikeComment = (params: GetCommentsQueryVars) => {
  const [likeCommentMutation] = useMutation<
    LikeCommentMutation,
    LikeCommentMutationVars
  >(LIKE_COMMENT, {
    update: (cache, { data }) => {
      if (data?.likeComment.__typename !== 'LikeComment') return;

      const { commentId, isLiked, likesCount } = data.likeComment.like;

      const commentsList: {
        getComments: { comments: CommentModel[] };
      } | null = cache.readQuery({
        query: GET_COMMENTS,
        variables: params,
      });

      if (!commentsList?.getComments.comments || !commentId) return;

      const commentToUpdate = commentsList.getComments.comments.find(
        (comment) => comment.id === commentId
      );

      if (!commentToUpdate) return;

      cache.modify({
        id: cache.identify(commentToUpdate),
        fields: {
          isLiked() {
            return isLiked;
          },
          likesCount() {
            return likesCount;
          },
        },
      });
    },
  });

  return async (id: string, isLiked: boolean, likesCount: number) => {
    try {
      const { data, errors } = await likeCommentMutation({
        variables: { id },
        optimisticResponse: {
          likeComment: {
            __typename: 'LikeComment',
            like: {
              commentId: id,
              isLiked,
              likesCount,
            },
          },
        },
      });

      const isLikeCommentSuccess = verifyApolloMutationResponse(data, errors);
      if (!isLikeCommentSuccess) return;

      if (data?.likeComment.__typename === 'Error') {
        Toast.error(data.likeComment.error);
        return;
      }

      if (data?.likeComment.__typename === 'LikeComment') {
        return {
          isLiked: data.likeComment.like.isLiked,
          likesCount: data.likeComment.like.likesCount,
        };
      }
    } catch (e: unknown) {
      Toast.error(i18n!.t('comments:like_comment_error'));
    }
  };
};

export default useLikeComment;
