import { useMutation } from '@apollo/client';
import { i18n } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import { CommentModel } from '../commets-type';
import GET_COMMENTS, { GetCommentsQueryVars } from '../graphql/get-comments';
import UPDATE_MY_COMMENT, {
  UpdateMyCommentMutation,
  UpdateMyCommentMutationVars,
} from '../graphql/update-my-comment';

export interface UpdateMyCommentVariables {
  id: string;
  comment: string;
  recipientId?: string;
}

const useUpdateMyComment = (params: GetCommentsQueryVars) => {
  const [updateMyComment] = useMutation<
    UpdateMyCommentMutation,
    UpdateMyCommentMutationVars
  >(UPDATE_MY_COMMENT, {
    update: (cache, { data }) => {
      if (data?.updateMyComment.__typename !== 'UpdateMyComment') return;

      const { id, comment, isUpdated } = data.updateMyComment.comment;

      const commentsList: {
        getComments: { comments: CommentModel[] };
      } | null = cache.readQuery({
        query: GET_COMMENTS,
        variables: params,
      });

      if (!commentsList?.getComments.comments || !id) return;

      const commentToUpdate = commentsList.getComments.comments.find(
        (comment) => comment.id === id
      );

      if (!commentToUpdate) return;

      cache.modify({
        id: cache.identify(commentToUpdate),
        fields: {
          comment() {
            return comment;
          },
          isUpdate() {
            return isUpdated;
          },
        },
      });
    },
  });

  return async (variables: UpdateMyCommentVariables) => {
    try {
      const { data, errors } = await updateMyComment({
        variables,
      });

      const isUpdateMyCommentSuccess = verifyApolloMutationResponse(
        data,
        errors
      );
      if (!isUpdateMyCommentSuccess) return;

      if (data?.updateMyComment.__typename === 'Error') {
        Toast.error(data.updateMyComment.error);
        return;
      }

      if (data?.updateMyComment.__typename === 'UpdateMyComment') {
        Toast.info(i18n!.t('comments:update_comment_success'));
        return data.updateMyComment.comment.id;
      }
    } catch (e: unknown) {
      Toast.error(i18n!.t('comments:update_comment_error'));
    }
  };
};

export default useUpdateMyComment;
