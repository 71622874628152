'use client'; // This is a client component 👈🏽

import { MetaMaskSDK, SDKProvider } from '@metamask/sdk';
import { EventType } from '@metamask/sdk-communication-layer';
import { BrowserProvider, Contract, formatEther, parseEther } from 'ethers';
import { createContext, useContext, useEffect, useState } from 'react';

import tokenAbi from '../token-abi.json';

const AppMetaMaskContext = createContext<{
  sdk: MetaMaskSDK | null;
  connected: boolean;
  account: string;
  sign: (msg: string) => Promise<string>;
  approve: (amount: number) => Promise<void>;
  revoke: () => Promise<void>;
  getBalance: (account: string) => Promise<number>;
  connect: () => Promise<void>;
  disconnect: () => void;
}>({
  sdk: null,
  account: '',
  connected: false,
  sign: () => {
    throw new Error('Sign not implememnted');
  },
  approve: () => {
    throw new Error('Approve not implememnted');
  },
  revoke: () => {
    throw new Error('Revoke not implememnted');
  },
  getBalance: () => {
    throw new Error('GetBalance not implememnted');
  },
  connect: () => {
    throw new Error('Connect not implememnted');
  },
  disconnect: () => {
    throw new Error('Disconnect not implememnted');
  },
});

export const useAppMetamask = () => useContext(AppMetaMaskContext);

const AppMetaMaskProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sdk, setSDK] = useState<MetaMaskSDK | null>(null);
  const [activeProvider, setActiveProvider] = useState<SDKProvider>();
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState<string>('');

  useEffect(() => {
    const doAsync = async () => {
      const clientSDK = new MetaMaskSDK({
        checkInstallationImmediately: false,
        dappMetadata: {
          name: 'Votesport.io',
          url: window.location.href,
        },
        storage: {
          enabled: true,
        },
      });
      await clientSDK.init();
      setSDK(clientSDK);
    };
    doAsync();
  }, []);

  return (
    <AppMetaMaskContext.Provider
      value={{
        sdk,
        connected,
        account,
        sign: async (message: string) => {
          return '';
        },
        approve: async (amount: number) => {},
        revoke: async () => {},
        getBalance: async (account) => {
          return 0;
        },
        connect: async () => {},
        disconnect: () => {
          sdk?.terminate();
        },
      }}
    >
      {children}
    </AppMetaMaskContext.Provider>
  );
};

export default AppMetaMaskProvider;
