import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button, Loader } from 'ui';
import { Flex } from 'ui/flex';
import { Text } from 'ui/v2/typography/text';

import EventsList from './events-list';
import { PAGE_LIMIT_LIVE } from '../../graphql/get-user-statistic-live-orders';
import { useGetStatisticLive } from '../../hooks/use-get-statistic-live';

const LiveEventsList = () => {
  const { t } = useTranslation(['common']);

  const { data, loading, sort, onGetNextPage } = useGetStatisticLive();

  if (data?.getStatsByEvents.__typename !== 'GetStatsByEvents') {
    return null;
  }

  return (
    <>
      {data.getStatsByEvents.events.length === 0 ? (
        <Text type="paragraph" textAlign="center">
          {t('common:labels:no_data')}
        </Text>
      ) : (
        <EventsList
          ordersList={data.getStatsByEvents.events}
          sort={sort.direction}
          setSort={sort.setDirection}
        />
      )}
      {loading && (
        <Flex justifyContent="center" alignItems="center">
          <Loader />
        </Flex>
      )}
      {data.getStatsByEvents.nav.hasNext && (
        <Flex justifyContent="center">
          <Button
            color="empty"
            onClick={() => onGetNextPage(PAGE_LIMIT_LIVE)}
            disabled={loading}
          >
            {t('common:actions:load_more')}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default LiveEventsList;
