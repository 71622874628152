import { useTranslation } from 'next-i18next';
import React from 'react';

import { ConfirmModal } from 'ui';

interface BanCommentModalProps {
  authorName: string;
  isBanCommentModalOpen: boolean;
  banCommentAuthor: () => void;
  closeModal: () => void;
}

const BanCommentAuthorModal: React.FC<BanCommentModalProps> = ({
  authorName,
  isBanCommentModalOpen,
  banCommentAuthor,
  closeModal,
}) => {
  const { t } = useTranslation(['comments']);

  return (
    <ConfirmModal
      actionButtonTitle={t('comments:ban')}
      isModalOpen={isBanCommentModalOpen}
      callback={banCommentAuthor}
      closeModal={closeModal}
    >
      <p>
        {t('comments:ban_user_confirm')} <span>{authorName}</span>?
      </p>
    </ConfirmModal>
  );
};

export default BanCommentAuthorModal;
