import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import GET_MY_CLIENT from 'application/graphql/get-my-client';
import WebApp from 'application/web-app';
import webApp from 'application/web-app';
import SIGNUP_CLIENT, {
  SignupClientMutation,
  SignupClientMutationVars,
} from 'features/signup/graphql/signup-client';
import { Toast } from 'ui';

import SignupModal, { SignupFormErrors } from './signup-modal';

interface SignupModalConnected {
  isOpen: boolean;
  onClose?: () => void;
  onSignup: () => void;
  onGoToSignin: () => void;
  noOverflow?: boolean;
}

const SignupModalConnected: React.FC<SignupModalConnected> = ({
  isOpen,
  onClose,
  onSignup,
  onGoToSignin,
  noOverflow,
}) => {
  const { t } = useTranslation(['common', 'auth']);
  const [formErrors, setFormErrors] = useState<SignupFormErrors>({});
  const [isProcessing, setProcessing] = useState(false);
  const [signupClient] = useMutation<
    SignupClientMutation,
    SignupClientMutationVars
  >(SIGNUP_CLIENT, { refetchQueries: [GET_MY_CLIENT] });

  const onClearPasswordConfirmError = () => {
    setFormErrors((prev) => ({ ...prev, passwordConfirm: '' }));
  };

  return (
    <SignupModal
      errors={formErrors}
      isOpen={isOpen}
      isProcessing={isProcessing}
      noOverflow={noOverflow}
      onClose={onClose}
      onClearPasswordConfirmError={onClearPasswordConfirmError}
      onGotToSignin={onGoToSignin}
      onRegistration={async ({ recaptcha, passwordConfirm, ...input }) => {
        try {
          setProcessing(true);

          if (input.password !== passwordConfirm) {
            throw new Error('passwordConfirm');
          }

          const deviceIds = await webApp.getDeviceIds();

          const { data, errors } = await signupClient({
            variables: {
              input: {
                ...input,
                recaptcha: recaptcha?.getValue() || null,
                deviceId: deviceIds.deviceId,
                uniqId: deviceIds.uniqId,
              },
            },
          });

          if (errors) {
            Toast.error(errors.map((item) => item.message).join(', '));
            recaptcha?.reset();
            return;
          }

          if (!data) {
            Toast.error(t('common:errors:undefined_error'));
            recaptcha?.reset();
            return;
          }

          if (data?.signupClient.__typename === 'Error') {
            if (data.signupClient.warnings) {
              recaptcha?.reset();
              return setFormErrors(
                data.signupClient.warnings.reduce(
                  (prev, cur) => ({
                    ...prev,
                    [cur.key]: cur.warning,
                  }),
                  {}
                )
              );
            }

            Toast.error(data.signupClient.error);
            recaptcha?.reset();
            return;
          }

          Toast.success(t('auth:success_registration'));
          onSignup();
        } catch (e) {
          if (e instanceof Error && e.message === 'passwordConfirm') {
            setFormErrors((prev) => ({
              ...prev,
              passwordConfirm: t('auth:password_dont_math'),
            }));
          }
          WebApp.log.exception(e);
          recaptcha?.reset();
        } finally {
          setProcessing(false);
        }
      }}
    />
  );
};

export default SignupModalConnected;
