import React from 'react';

import { Textarea } from 'ui';
import CloseIcon from 'ui/icons/close-input';

import css from './comment-input.module.scss';

interface CommentInputProps {
  commentText: string;
  processing: boolean;
  autoFocus?: boolean;
  callback: () => void;
  setCommentText: (text: string) => void;
  onClose?: () => void;
}

const CommentInput: React.FC<CommentInputProps> = ({
  commentText,
  processing,
  autoFocus = false,
  callback,
  setCommentText,
  onClose,
}) => (
  <>
    {onClose && <CloseIcon className={css.Close} onClick={onClose} />}

    <Textarea
      autoFocus={autoFocus}
      value={commentText}
      onChange={setCommentText}
      height={125}
      buttons={
        <div className={css.ButtonContainer}>
          <button
            disabled={processing}
            className={css.Button}
            onClick={callback}
          />
        </div>
      }
    />
  </>
);

export default CommentInput;
