import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button, ModalFooter } from 'ui';

import css from './edit-published-news-modal.module.scss';
import { LoadNewsWidgetLinkType } from '../../types/load-news-widget-link-type';
import { NewsFormDataType } from '../../types/news-form-data-type';
import { UploadType } from '../../types/upload-type';
import EditNewsModal from '../edit-news-modal';

interface EditPublishedNewsModalProps {
  newsFormData: NewsFormDataType;
  isNewsDataModified: boolean;
  loading: boolean;
  closeModal: () => void;
  loadNewsWidgetLink: LoadNewsWidgetLinkType;
  uploadImage: UploadType;
  uploadVideo: UploadType;
  isPinned?: boolean;
  setIsPinned?: () => void;
  uploadPreviewImage: (img: File) => void;
  uploadPreviewVideo: (img: File) => void;
  updatePublishedNews: () => Promise<void>;
  updateNewsFormData: <T extends keyof NewsFormDataType>(
    field: T,
    value: NewsFormDataType[T]
  ) => void;
}

const EditPublishedNewsModal: React.FC<EditPublishedNewsModalProps> = ({
  newsFormData,
  loading,
  updateNewsFormData,
  isNewsDataModified,
  updatePublishedNews,
  uploadPreviewImage,
  uploadPreviewVideo,
  uploadImage,
  uploadVideo,
  loadNewsWidgetLink,
  closeModal,
  setIsPinned,
  isPinned,
}) => {
  const { t } = useTranslation('common');

  return (
    <EditNewsModal
      previewUploadDescription={newsFormData.previewUploadDescription}
      saveNewsPreviewUploadDescription={(previewUploadDescription) =>
        updateNewsFormData('previewUploadDescription', previewUploadDescription)
      }
      newsData={newsFormData}
      isPinned={isPinned}
      setIsPinned={setIsPinned}
      closeModal={closeModal}
      uploadNewsImage={uploadImage}
      uploadNewsVideo={uploadVideo}
      uploadPreviewImage={uploadPreviewImage}
      uploadPreviewVideo={uploadPreviewVideo}
      loadNewsWidgetLink={loadNewsWidgetLink}
      saveNewsTitle={(title) => updateNewsFormData('title', title)}
      saveEditorBlocks={(blocks) => updateNewsFormData('blocks', blocks)}
      saveSportId={(sportId) => updateNewsFormData('sportId', sportId)}
      saveCategoryId={(categoryId) =>
        updateNewsFormData('categoryId', categoryId)
      }
      saveNewsPreviewDescription={(previewDescription) =>
        updateNewsFormData('previewDescription', previewDescription)
      }
    >
      <ModalFooter>
        <Button
          className={css.Button}
          onClick={updatePublishedNews}
          disabled={!isNewsDataModified || loading}
        >
          {t('common:actions:save')}
        </Button>
      </ModalFooter>
    </EditNewsModal>
  );
};

export default EditPublishedNewsModal;
