import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import { Toast } from 'ui';

import GET_MY_WALLET_ADDRESSES from '../graphql/get-my-wallet-addresses';
import REMOVE_WALLET_ADDRESS, {
  RemoveWalletAddressMutation,
  RemoveWalletAddressMutationVars,
} from '../graphql/remove-wallet-address';

const useRemoveWalletAddressClient = () => {
  const { t } = useTranslation(['common', 'wallets']);

  const [removeWalletAddress] = useMutation<
    RemoveWalletAddressMutation,
    RemoveWalletAddressMutationVars
  >(REMOVE_WALLET_ADDRESS);

  const removeWalletAddressCallback = useCallback(
    async (id: string) => {
      const { errors, data } = await removeWalletAddress({
        variables: {
          id,
        },
        refetchQueries: [GET_MY_WALLET_ADDRESSES],
      });

      if (errors) {
        Toast.error(errors.map((item) => item.name).join(', '));
        return;
      }

      if (!data) {
        Toast.error(t('common:errors:undefined_error'));
        return;
      }

      if (data.removeWalletAddress.__typename === 'Error') {
        Toast.error(data.removeWalletAddress.error);
        return;
      }
    },
    [removeWalletAddress, t]
  );

  return removeWalletAddressCallback;
};

export default useRemoveWalletAddressClient;
