import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';

import { Button, Modal, ModalFooter } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './confirm-modal.module.scss';

interface ConfirmModalProps {
  isModalOpen: boolean;
  modalTitle?: string;
  children: ReactNode;
  actionButtonTitle?: string;
  closeModal: () => void;
  callback: () => void;
  disabled?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isModalOpen,
  modalTitle,
  children,
  actionButtonTitle,
  closeModal,
  callback,
  disabled,
}) => {
  const { t } = useTranslation(['common']);
  const buttonTitle = actionButtonTitle ?? t('common:actions:delete');

  return (
    <Modal
      isOpen={isModalOpen}
      width={600}
      onClose={closeModal}
      title={modalTitle || ''}
    >
      <div className={css.ChildrenWrapper}>{children}</div>

      <ModalFooter>
        <Flex gap={GridGap.x4} justifyContent="flex-end">
          <Button
            color="empty"
            onClick={closeModal}
            className={css.Button}
            disabled={disabled}
          >
            {t('common:actions:cancel')}
          </Button>

          <Button
            color="secondary"
            onClick={callback}
            className={css.Button}
            disabled={disabled}
          >
            {buttonTitle}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
