import { detect } from 'detect-browser';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { Toast } from 'ui';

const MIN_VERSION: Record<string, number> = {
  chrome: 84, // 2020 jul
  firefox: 67, // 2021 jul
  fxios: 67, // 2021 jul
  'edge-chromium': 94, // 2021 aug
  'edge-ios': 94, // 2021 aug
  edge: 94, // 2021 aug
  safari: 15, // 2021 sep
  ios: 15, // 2021 sep
  opera: 70, // 2020 jul
  yandexbrowser: 21, // 2021 jun
  samsung: 14, // 2021 apr
};

const BrowserChecker = () => {
  const { t } = useTranslation(['common']);

  useEffect(() => {
    const browser = detect();

    if (browser) {
      const { name, version } = browser;

      const majorVersion = parseInt((version || '0').split('.')[0], 10);

      if (MIN_VERSION[name] && majorVersion < MIN_VERSION[name]) {
        Toast.warning(t('common:warnings:browser_not_supported'));
      } else if (!MIN_VERSION[name]) {
        Toast.warning(t('common:warnings:browser_not_detected'));
      }
    }
  }, []);

  return null;
};

export default BrowserChecker;
