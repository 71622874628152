import React from 'react';

import LastAnnounce from './last-announce';
import useLastAnnounces from './use-last-announces';

const LastAnnounceConnected: React.FC<{ sport?: string }> = ({ sport }) => {
  const { announces } = useLastAnnounces(sport);

  return <LastAnnounce announces={announces} />;
};

export default LastAnnounceConnected;
