import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { useAppModals } from 'application/context/app-modals-provider';
import { useUser } from 'application/context/user-provider';
import WebApp from 'application/web-app';

import useAddComment from '../../hooks/use-add-comment';
import CommentInput from '../comment-input/comment-input';
import { SortType } from '../comments-list';
import { AnswerVariablesType } from '../comments-list/comments-list';

interface AnswerInputContainerProps {
  answerVariables: AnswerVariablesType;
  childCount?: number;
  autoFocus?: boolean;
  onSend?: () => void;
  onClose?: () => void;
  updateCommentsData?: () => void;
}

const AnswerInputContainer: React.FC<AnswerInputContainerProps> = ({
  answerVariables,
  onSend,
  onClose,
  updateCommentsData,
  childCount,
  autoFocus = false,
}) => {
  const { setSignInOpen } = useAppModals();

  const [processing, setProcessing] = useState(false);
  const [commentText, setCommentText] = useState('');
  const { user } = useUser();
  const router = useRouter();

  const { recipientId, ...rest } = answerVariables;
  const addCommentMutation = useAddComment(
    {
      ...rest,
      sort: answerVariables.commentId
        ? SortType.ORDER
        : (router.query?.sort as SortType) || SortType.LAST,
    },
    router.asPath.includes('tournament') ? (router.query.id as string) : ''
  );

  const addComment = async () => {
    if (!user) {
      setSignInOpen(true);
      return;
    }

    setProcessing(true);
    const variables = {
      ...answerVariables,
      comment: commentText,
    };
    const commentId = await addCommentMutation(variables);
    if (commentId) {
      !childCount && updateCommentsData && updateCommentsData();
      setCommentText('');
      onSend && onSend();
      WebApp.eventEmitter.emitNewsCommentsChange();
    }
    setProcessing(false);
  };

  return (
    <CommentInput
      autoFocus={autoFocus}
      processing={processing}
      commentText={commentText}
      callback={addComment}
      setCommentText={setCommentText}
      onClose={onClose}
    />
  );
};

export default AnswerInputContainer;
