import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface EventType {
  id: string;
  name: string;
  count: number;
}

export interface GetTournamentEventsCommentsQuery {
  getTournamentEventsComments:
    | {
        __typename: 'GetTournamentEventsComments';
        count: number;
        events: EventType[];
      }
    | GraphqlErrorType;
}

export interface GetTournamentEventsCommentsQueryVars {
  id: string;
}

const GET_TOURNAMENT_EVENTS_COMMENTS = gql`
  query GetTournamentEventsComments($id: ID!) {
    getTournamentEventsComments(id: $id) {
      __typename
      ... on GetTournamentEventsComments {
        count
        events {
          id
          name
          count
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_TOURNAMENT_EVENTS_COMMENTS;
