import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface LikeCommentMutation {
  likeComment:
    | {
        __typename: 'LikeComment';
        like: {
          commentId: string;
          isLiked: boolean;
          likesCount: number;
        };
      }
    | GraphqlErrorType;
}

export interface LikeCommentMutationVars {
  id: string;
}

const LIKE_COMMENT = gql`
  mutation LikeComment($id: ID!) {
    likeComment(commentId: $id) {
      ... on LikeComment {
        like {
          commentId
          isLiked
          likesCount
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default LIKE_COMMENT;
