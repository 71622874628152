import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { UserIcon } from 'ui/icons';

import css from './client-avatar.module.scss';

interface ClientAvatarProps {
  avatar: string | null;
  size?: 'L' | 'M' | 'S' | 'XS';
  className?: string;
  color?: 'dark' | 'light';
  isActive?: boolean;
}

const ClientAvatar: React.FC<ClientAvatarProps> = ({
  avatar,
  className,
  size,
  color = 'light',
  isActive,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <div
      className={cx(className, css.Root, css[`${size}`], {
        [css.Dark]: color === 'dark',
        [css.Active]: isActive,
      })}
    >
      {avatar ? (
        <img src={avatar} alt={t('common:alt:u_avatar')} />
      ) : (
        <UserIcon />
      )}
    </div>
  );
};

export default ClientAvatar;
