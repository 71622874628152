import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button } from 'ui';

interface DisconnectMetamaskProps {
  onClick: () => void;
}

const DisconnectMetamask: React.FC<DisconnectMetamaskProps> = ({ onClick }) => {
  const { t } = useTranslation(['wallets']);

  return (
    <Button onClick={onClick} outline>
      {t('disconnect_metamask')}
    </Button>
  );
};

export default DisconnectMetamask;
