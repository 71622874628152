import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import LOGOUT_CLIENT, {
  LogoutClientMutation,
  LogoutClientMutationVars,
} from 'application/graphql/logout-client';
import { errorNotification } from 'application/notification';
import webApp from 'application/web-app';

import { useAppModals } from './app-modals-provider';
import { useFirebaseApp } from '../../features/firebase/context/firebase-provider';
import GET_MY_CLIENT, {
  GetMyClientQuery,
  GetMyClientQueryVars,
  MyClientModel,
} from '../graphql/get-my-client';
import UPDATE_PUSH_TOKEN, {
  UpdatePushTokenMutation,
  UpdatePushTokenMutationVars,
} from '../graphql/update-push-token';

const UserContext = createContext<{
  user: MyClientModel | null;
  signinUser: (login: string, password: string) => Promise<void>;
  sendPushToken: () => Promise<void>;
  logoutUserContext: () => Promise<void>;
}>({
  user: null,
  signinUser: (login: string, password: string) => {
    throw new Error('signinUser not implement');
  },
  sendPushToken: () => {
    throw new Error('sendPushToken not implement');
  },
  logoutUserContext: () => {
    throw new Error('logoutUserContext not implement');
  },
});

export const useUser = () => useContext(UserContext);

const UserProvider: React.FC<{
  user: MyClientModel | null;
  children: React.ReactNode;
}> = ({ children, user: initialUser }) => {
  const router = useRouter();

  const modals = useAppModals();
  const alreadySignIn = useRef(false);

  const { token, retrievePushToken } = useFirebaseApp();
  const [user, setUser] = useState<MyClientModel | null>(initialUser);

  const { data } = useQuery<GetMyClientQuery, GetMyClientQueryVars>(
    GET_MY_CLIENT,
    { variables: { token: token || null } }
  );

  const [logoutClient] = useMutation<
    LogoutClientMutation,
    LogoutClientMutationVars
  >(LOGOUT_CLIENT, { refetchQueries: [GET_MY_CLIENT] });

  const [updatePushToken] = useMutation<
    UpdatePushTokenMutation,
    UpdatePushTokenMutationVars
  >(UPDATE_PUSH_TOKEN);

  useEffect(() => {
    if (data?.getMyClient.client) {
      setUser({
        ...data.getMyClient.client,
        moderator: !!data.getMyClient.abilities.find(
          (item) => item.action === 'MODERATOR' && item.resource === 'CONTENT'
        ),
        editor: !!data.getMyClient.abilities.find(
          (item) => item.action === 'EDITOR' && item.resource === 'CONTENT'
        ),
      });
    } else {
      setUser(null);

      if (
        data?.getMyClient.client === null &&
        router.query.signin !== undefined &&
        !modals.isSignInOpen &&
        !modals.isSignUpOpen &&
        !modals.isForgotPasswordOpen &&
        !alreadySignIn.current
      ) {
        alreadySignIn.current = true;
        modals.setSignInOpen(true);
      }
    }
  }, [data]);

  useEffect(() => {
    const asyncFn = async () => {
      window.pendo.initialize({
        visitor: { id: (await webApp.getDeviceIds()).deviceId },
        ...(user && { account: { id: user.id } }),
      });
    };

    if (window && window.pendo) {
      asyncFn();
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      updatePushToken({ variables: { token } });
    }
  }, [updatePushToken, token]);

  return (
    <UserContext.Provider
      value={{
        user,
        signinUser: async () => {},
        sendPushToken: async () => {
          await retrievePushToken();
        },
        logoutUserContext: async () => {
          const { data, errors } = await logoutClient();

          if (errors) {
            errorNotification(errors.map((item) => item.message).join(', '));
            return;
          }

          if (data?.logoutClient.__typename === 'Error') {
            errorNotification(data.logoutClient.error);
            return;
          }

          router.push(webApp.router.getIndexPage());
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
