import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { errorNotification, infoNotification } from 'application/notification';
import { getCorrectSortDirection } from 'application/utils/getCorrectSortDirection';
import REMOVE_LIVE_LIMIT_BID, {
  RemoveLiveLimitBidMutation,
  RemoveLiveLimitBidMutationVars,
} from 'features/tournament/graphql/remove-live-limit-bid';
import REMOVE_LIVE_MARKET_ORDER, {
  RemoveLiveMarketOrderMutation,
  RemoveLiveMarketOrderMutationVars,
} from 'features/tournament/graphql/remove-live-market-order';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';

import GET_STATS_ORDERS from '../graphql/get-user-statistic-live-orders';

interface Params {
  isActive?: boolean;
  sortBy?: string;
  sortDirection: SortDirection;
  successCallback?: () => void;
}

export const useManagementOrders = ({
  isActive,
  sortBy,
  sortDirection,
  successCallback,
}: Params) => {
  const { t } = useTranslation(['bets']);

  const [removeBid, { loading: isBidLoad }] = useMutation<
    RemoveLiveLimitBidMutation,
    RemoveLiveLimitBidMutationVars
  >(REMOVE_LIVE_LIMIT_BID, {
    refetchQueries: (result) => {
      if (result.data?.removeLiveLimitBid.__typename === 'RemoveLiveLimitBid') {
        return [
          {
            query: GET_STATS_ORDERS,
            variables: {
              orderType: 'limit',
              sort: {
                isActive,
                page: 1,
                sortBy,
                sortDirection: getCorrectSortDirection(sortDirection),
              },
            },
          },
        ];
      }

      return [];
    },
  });

  const [removeMarketOrder, { loading: isMarketOrderLoad }] = useMutation<
    RemoveLiveMarketOrderMutation,
    RemoveLiveMarketOrderMutationVars
  >(REMOVE_LIVE_MARKET_ORDER, {
    refetchQueries: (result) => {
      if (
        result.data?.removeLiveMarketOrder.__typename ===
        'RemoveLiveMarketOrder'
      ) {
        return [
          {
            query: GET_STATS_ORDERS,
            variables: {
              orderType: 'market',
              sort: {
                isActive,
                page: 1,
                sortBy,
                sortDirection: getCorrectSortDirection(sortDirection),
              },
            },
          },
        ];
      }

      return [];
    },
  });

  const onRemoveBid = (id: string) => {
    removeBid({ variables: { id } }).then(({ errors, data }) => {
      if (errors) {
        errorNotification(errors.map((error) => error.message).join(', '));
      }

      if (data?.removeLiveLimitBid.__typename === 'Error') {
        errorNotification(data.removeLiveLimitBid.error);
      }

      if (data?.removeLiveLimitBid.__typename === 'RemoveLiveLimitBid') {
        infoNotification(t('bets:your_bet_withdrawn'));
        successCallback?.();
      }
    });
  };

  const onRemoveMarketOrder = (id: string) => {
    removeMarketOrder({ variables: { id } }).then(({ errors, data }) => {
      if (errors) {
        errorNotification(errors.map((error) => error.message).join(', '));
      }

      if (data?.removeLiveMarketOrder.__typename === 'Error') {
        errorNotification(data.removeLiveMarketOrder.error);
      }

      if (data?.removeLiveMarketOrder.__typename === 'RemoveLiveMarketOrder') {
        infoNotification(t('bets:your_bet_withdrawn'));
        successCallback?.();
      }
    });
  };

  return {
    onRemoveBid,
    onRemoveMarketOrder,
    isBidLoad,
    isMarketOrderLoad,
  };
};
