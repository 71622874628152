import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';
import {
  OPPONENET_FRAGMENT,
  OpponentModel,
} from 'application/graphql/opponent-type';

export type HotTournamentResultModel = {
  id: string;
  opponent1: OpponentModel;
  opponent2: OpponentModel;
  pari: {
    amount: number;
    percent1: number;
    percent2: number;
    coef1: number;
    coef2: number;
    coefDraw: number;
  };
  live: {
    amount: number;
    percent1: number;
    percent2: number;
    coef1: number;
    coef2: number;
    coefDraw: number;
  };
};

export type HotTournamentModel = {
  id: string;
  name: string;
  startDate: string;
  results: HotTournamentResultModel[];
};

export interface GetHotTournamentsQuery {
  getHotTournaments:
    | {
        __typename: 'GetHotTournaments';
        tournaments: HotTournamentModel[];
      }
    | GraphqlErrorType;
}

export interface GetHotTournamentsQueryVars {
  sport?: string;
}

const GET_HOT_TOURNAMENTS = gql`
  ${OPPONENET_FRAGMENT}
  query GetHotTournaments($sport: ID) {
    getHotTournaments(sport: $sport) {
      __typename
      ... on GetHotTournaments {
        tournaments {
          id
          name
          startDate
          results {
            id
            opponent1 {
              ...OpponentFragment
              mmaOpponent {
                win
                draw
                lose
                country
                countryFlag
                rating
              }
            }
            opponent2 {
              ...OpponentFragment
              mmaOpponent {
                win
                draw
                lose
                country
                countryFlag
                rating
              }
            }
            pari {
              amount
              percent1
              percent2
              coef1
              coef2
              coefDraw
            }
            live {
              amount
              percent1
              percent2
              coef1
              coef2
              coefDraw
            }
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_HOT_TOURNAMENTS;
