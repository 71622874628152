import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { ReactNode, useState } from 'react';

import { useMediaQuery } from 'hooks/use-media-query.hook';
import Button from 'ui/button';

import css from './render-list.module.scss';

type Props<T> = {
  renderItem: (item: T, index: number) => ReactNode;
  list: T[];
  limit?: number;
  mobileLimit?: number;
  className?: string;
  children?: ReactNode;
  firstEl?: ReactNode;
  lastEl?: ReactNode;
  defaultIsLimited?: boolean;
};

export const RenderList = <T extends unknown>({
  renderItem,
  list,
  limit,
  mobileLimit,
  className,
  firstEl,
  lastEl,
  defaultIsLimited = true,
}: Props<T>) => {
  const { t } = useTranslation(['common']);

  const [isLimited, setIsLimited] = useState(defaultIsLimited);

  const isMobile = useMediaQuery('(max-width: 430px)');

  const finalLimit = isMobile ? mobileLimit : limit;

  const toggleLimited = () => {
    setIsLimited(!isLimited);
  };

  const isNeedSlice = finalLimit ? list.length > finalLimit : false;

  const hiddenCount = finalLimit && Math.max(0, list.length - finalLimit);

  const newList = isNeedSlice && isLimited ? list.slice(0, finalLimit) : list;

  return (
    <div className={cx(css.root, className)}>
      {firstEl}
      {newList.map((item, i) => renderItem(item, i))}
      {isNeedSlice && (
        <Button size="small" color="invert" onClick={toggleLimited}>
          {isLimited
            ? `${t('common:actions:show_more')} (${hiddenCount})`
            : t('common:actions:hide')}
        </Button>
      )}
      {lastEl}
    </div>
  );
};
