import { i18n } from 'next-i18next';

import { Toast } from 'ui';

export const validateImage = (file: File) => {
  if (!file.type.startsWith('image/')) {
    Toast.error(i18n!.t('common:errors:file_not_image_error'));
    return false;
  }

  const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();
  const allowedExtensions = ['png', 'jpeg', 'jpg', 'webp'];

  if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
    Toast.error(i18n!.t('common:errors:acceptable_img_extensions_error'));
    return false;
  }

  if (file.size > 5 * 1024 * 1024) {
    Toast.error(i18n!.t('common:errors:max_img_size_error'));
    return false;
  }

  return true;
};

export const validateEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const isValidImageFile = (file: File) => {
  const imageExt = ['png', 'jpeg', 'jpg', 'webp'];

  const fileExtension = file.name.split('.').pop()?.toLowerCase();

  const isImage = !!fileExtension && imageExt.includes(fileExtension);

  if (!isImage) {
    Toast.error(i18n!.t('common:errors:acceptable_img_extensions_error'));
    return false;
  }

  if (file.size > 5 * 1024 * 1024) {
    Toast.error(i18n!.t('common:errors:max_img_size_error'));
    return false;
  }

  return true;
};

export const isValidVideoFile = (file: File) => {
  const videoExt = ['mp4', 'm4v'];

  const fileExtension = file.name.split('.').pop()?.toLowerCase();

  const isVideo = !!fileExtension && videoExt.includes(fileExtension);

  if (!isVideo) {
    Toast.error(i18n!.t('common:errors:acceptable_video_extensions_error'));
    return false;
  }

  if (file.size > 10 * 1024 * 1024) {
    Toast.error(i18n!.t('common:errors:max_video_size_error'));
    return false;
  }

  return true;
};
