import React from 'react';

import css from './comment.module.scss';
import type { CommentModel } from '../../commets-type';
import CommentFooter from '../comment-footer';
import CommentHeader from '../comment-header';
import CommentInput from '../comment-input';

interface CommentProps {
  comment: CommentModel;
  isCommentEditingOpen: boolean;
  isCommentUpdateLoading: boolean;
  commentText: string;
  setCommentText: (text: string) => void;
  updateComment: () => void;
  openCommentEditing: () => void;
  closeCommentEditing: () => void;
  updateCommentsData: () => void;
}

const Comment: React.FC<CommentProps> = ({
  comment,
  isCommentEditingOpen,
  isCommentUpdateLoading,
  commentText,
  setCommentText,
  openCommentEditing,
  closeCommentEditing,
  updateComment,
  updateCommentsData,
}) => {
  return (
    <div className={comment.parentId ? css.CommentLine : css.Comment}>
      <CommentHeader comment={comment} editMyComment={openCommentEditing} />

      {isCommentEditingOpen ? (
        <CommentInput
          autoFocus={true}
          commentText={commentText}
          setCommentText={setCommentText}
          processing={isCommentUpdateLoading}
          callback={updateComment}
          onClose={closeCommentEditing}
        />
      ) : (
        <p className={css.Text}>{comment.comment}</p>
      )}

      <CommentFooter
        comment={comment}
        updateCommentsData={updateCommentsData}
      />
    </div>
  );
};

export default Comment;
