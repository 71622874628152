import { useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';

import GET_APP_CONFIG, {
  GetAppConfigQuery,
  GetAppConfigQueryVars,
  SportType,
} from 'application/graphql/get-app-config';

export type AppConfig = {
  sports: SportType[];
  walletEnabled: boolean;
  referralEnabled: boolean;
};

const AppConfigContext = createContext<{
  appConfig: AppConfig | null;
}>({
  appConfig: {
    sports: [],
    walletEnabled: false,
    referralEnabled: false,
  },
});

export const useAppConfig = () => useContext(AppConfigContext);

const AppConfigProvider: React.FC<{
  config: AppConfig;
  children: React.ReactNode;
}> = ({ config, children }) => {
  const [appConfig] = useState<AppConfig | null>(config);

  return (
    <AppConfigContext.Provider
      value={{
        appConfig,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

export default AppConfigProvider;
