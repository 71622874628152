import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import { ModeratedNewsListFiltersType } from './use-set-moderated-news-list-filters';
import GET_MODERATED_NEWS_LIST, {
  OneModeratedNewsDataType,
} from '../graphql/get-moderation-news-list';
import UPDATE_MODERATED_NEWS, {
  UpdateModerateNewsMutation,
  UpdateModerateNewsMutationVars,
} from '../graphql/update-moderated-news';
import { NewsFormPayloadType } from '../types/news-form-payload-type';

const useUpdateModeratedNews = (filters: ModeratedNewsListFiltersType) => {
  const { t } = useTranslation(['news']);
  const [updateModeratedNewsMutation] = useMutation<
    UpdateModerateNewsMutation,
    UpdateModerateNewsMutationVars
  >(UPDATE_MODERATED_NEWS, {
    update: (cache, { data }) => {
      if (data?.updateModeratedNews.__typename !== 'UpdateModeratedNews')
        return;

      const newNewsData = data.updateModeratedNews.news;
      const { status, sports, sort, pageNumber, categories } = filters;
      const variables = {
        sort,
        pageNumber,
        filter: {
          categories,
          sports,
          status,
        },
      };

      const moderatedNewsList: {
        getModeratedNewsList: { news: OneModeratedNewsDataType[] };
      } | null = cache.readQuery({
        query: GET_MODERATED_NEWS_LIST,
        variables,
      });

      if (!moderatedNewsList || !newNewsData.id) return;

      const updatedNewsList = moderatedNewsList.getModeratedNewsList.news.map(
        (news) => (news.id === newNewsData.id ? newNewsData : news)
      );

      cache.writeQuery({
        query: GET_MODERATED_NEWS_LIST,
        variables,
        data: {
          getModeratedNewsList: {
            ...moderatedNewsList.getModeratedNewsList,
            news: updatedNewsList,
          },
        },
      });
    },
  });

  return async (id: string, input: NewsFormPayloadType) => {
    try {
      const { data, errors } = await updateModeratedNewsMutation({
        variables: { id, input },
      });

      const isUpdatePublishedNewsSuccess = verifyApolloMutationResponse(
        data,
        errors
      );
      if (!isUpdatePublishedNewsSuccess) return;

      if (data?.updateModeratedNews.__typename === 'Error') {
        Toast.error(data.updateModeratedNews.error);
        return;
      }

      if (data?.updateModeratedNews.__typename === 'UpdateModeratedNews') {
        return data.updateModeratedNews.news;
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:article_edit_error'));
    }
  };
};

export default useUpdateModeratedNews;
