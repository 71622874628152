import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button, Input, Modal } from 'ui';

import css from './transfer-money-dialog.module.scss';

export type TransferMoneyDialogValues = {
  amount: string;
};

export type TransferMoneyDialogErrors = {
  [K in keyof TransferMoneyDialogValues]?: string;
};

interface TransferMoneyDialogProps {
  processing: boolean;
  isOpen: boolean;
  values: TransferMoneyDialogValues;
  errors: TransferMoneyDialogErrors;
  onClose: () => void;
  onChange: (values: Partial<TransferMoneyDialogValues>) => void;
  onSubmit: () => void;
}

const TransferMoneyDialog: React.FC<TransferMoneyDialogProps> = ({
  processing,
  values,
  errors,
  isOpen,
  onClose,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation(['bets']);

  return (
    <Modal title={t('bets:money_transfer')} isOpen={isOpen} onClose={onClose}>
      <div className={css.TransferMoneyDialog}>
        <div className={css.Form}>
          <div>{t('bets:transfer_amount')}</div>
          <Input
            value={values.amount}
            error={errors.amount}
            onChange={(amount) => {
              let newAmount = amount.replace(/[^0-9.]/g, '');

              const splitted = newAmount.split('.');
              if (splitted.length > 1 && splitted[1].length > 2) {
                splitted[1] = splitted[1].substring(0, 2);
              }

              if (splitted.length > 2) {
                splitted.length = 2;
              }

              onChange({ amount: splitted.join('.') });
            }}
          />
          <Button size="large" onClick={onSubmit} disabled={processing}>
            {t('bets:transfer')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TransferMoneyDialog;
