import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button, Likes } from 'ui';
import { Flex } from 'ui/flex';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';

import css from './comment-footer.module.scss';
import { CommentModel } from '../../commets-type';
import AnswerInput from '../answer-input';

interface CommentFooterProps {
  comment: CommentModel;
  moderator: boolean;
  isAnswerOpen: boolean;
  banCommentAuthor: () => void;
  openAnswerInput: () => void;
  removeComment: () => void;
  likeComment: () => void;
  sendAnswer: () => void;
  updateCommentsData: () => void;
}

const CommentFooter: React.FC<CommentFooterProps> = ({
  comment,
  moderator,
  isAnswerOpen,
  likeComment,
  removeComment,
  banCommentAuthor,
  openAnswerInput,
  sendAnswer,
  updateCommentsData,
}) => {
  const { t } = useTranslation(['common', 'comments']);
  const { likesCount, isLiked, parentId, id, client } = comment;

  return (
    <Grid
      alignItems="center"
      gridTemplateColumns="auto auto 1fr"
      gap={GridGap.x0}
      style={{ rowGap: '6px' }}
    >
      <Likes likesCount={likesCount} isLiked={isLiked} setLike={likeComment} />

      <p className={css.Answer} onClick={openAnswerInput}>
        {t('comments:answer')}
      </p>

      {moderator && (
        <Flex
          gap={GridGap.x1}
          alignItems="center"
          style={{ textAlign: 'right', fontSize: 12 }}
          className={css.ModeratorButtonsContainer}
        >
          <Button color="invert" onClick={removeComment}>
            {t('common:actions:delete')}
          </Button>

          <Button color="secondary" onClick={banCommentAuthor}>
            {t('comments:ban')}
          </Button>
        </Flex>
      )}

      {isAnswerOpen && (
        <AnswerInput
          answerVariables={{
            commentId: parentId || id,
            recipientId: client.id,
          }}
          autoFocus={true}
          childCount={comment.childCount}
          updateCommentsData={updateCommentsData}
          onSend={sendAnswer}
          onClose={sendAnswer}
        />
      )}
    </Grid>
  );
};

export default CommentFooter;
