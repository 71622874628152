import cx from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import webApp from 'application/web-app';
import { BalancePageTabType } from 'features/cabinet/pages/balance-page';

import css from './profile-menu.module.scss';

interface ProfileMenuProps {
  username?: string;
  logout: () => void;
  closeMenu: () => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  username = '',
  logout,
  closeMenu,
}) => {
  const { t } = useTranslation(['header', 'help']);
  const router = useRouter();

  return (
    <>
      <div className={css.OverPage} onClick={closeMenu} />

      <ul className={css.Menu} onMouseLeave={closeMenu}>
        <li className={cx(css.Item, css.UserName)}>{username}</li>

        <li
          className={css.Item}
          onClick={() =>
            router.push(webApp.router.getBalance()).finally(closeMenu)
          }
        >
          {t('header:cabinet')}
        </li>

        <li
          className={css.Item}
          onClick={() =>
            router.push(webApp.router.getNewsComment('29')).finally(closeMenu)
          }
        >
          {t('header:about')}
        </li>

        <li
          className={css.Item}
          onClick={() =>
            router.push(webApp.router.getHelp()).finally(closeMenu)
          }
        >
          {t('help:labels:help')}
        </li>

        <li
          className={css.Item}
          onClick={() =>
            router.push(webApp.router.getCabinet()).finally(closeMenu)
          }
        >
          {t('header:settings')}
        </li>

        <li className={cx(css.Item, css.Logout)} onClick={logout}>
          {t('header:logout')}
        </li>
      </ul>
    </>
  );
};

export default ProfileMenu;
