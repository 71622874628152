import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';

export type NextTournamentModel = {
  id: string;
  name: string;
  icon: string;
  date: string;
};

export interface GetNextTournamentsQuery {
  getNextTournaments:
    | {
        __typename: 'GetNextTournaments';
        tournaments: NextTournamentModel[];
      }
    | GraphqlErrorType;
}

export interface GetNextTournamentsQueryVars {
  sport?: string;
}

const GET_NEXT_TOURNAMENTS = gql`
  query GetNextTournaments($sport: ID) {
    getNextTournaments(sport: $sport) {
      __typename
      ... on GetNextTournaments {
        tournaments {
          id
          name
          icon
          date
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEXT_TOURNAMENTS;
