import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveFlex } from 'ui/responsive-flex/responsive-flex';
import { Text } from 'ui/v2/typography/text';

import ConnectAddress from '../components/connect-address';
import ConnectMemtamaskButton from '../components/connect-metamask-button';
import DisconnectMetamask from '../components/disconnect-metamask';
import WalletsList from '../components/wallets-list';
import { useAppMetamask } from '../context/app-metamask-context';
import useGetMyWalletAddresses from '../hooks/use-get-my-wallet-addresses';

const BalanceWallets: React.FC = () => {
  const { sdk, connected, account, getBalance } = useAppMetamask();
  const { loading, addresses } = useGetMyWalletAddresses();
  const [limits, setLimits] = useState<Record<string, number>>({});

  const { t } = useTranslation(['wallets']);

  useEffect(() => {
    if (!addresses) {
      setLimits({});
      return;
    }

    const promises = addresses.map((item) => getBalance(item.address));

    Promise.all(promises).then((results) => {
      const newLimits: Record<string, number> = {};

      for (let i = 0; i < addresses.length; i++) {
        newLimits[addresses[i].address] = results[i];
      }

      setLimits(newLimits);
    });
  }, [addresses, getBalance]);

  if (loading || addresses === null) {
    return null;
  }

  const ifAddressExist = addresses.find((item) => item.address === account);

  return (
    <Flex flexDirection="column" width="100%" gap={GridGap.x2}>
      <Flex>
        <Text type="caption" color="black-primary" bold>
          {t('wallets-list')}
        </Text>
      </Flex>
      {addresses.length > 0 && (
        <Flex>
          <WalletsList wallets={addresses} limits={limits} />
        </Flex>
      )}
      {sdk && !connected && (
        <Flex>
          <ConnectMemtamaskButton />
        </Flex>
      )}
      {connected && (
        <Flex justifyContent="space-between">
          {!ifAddressExist && (
            <ResponsiveFlex>
              <ConnectAddress />
            </ResponsiveFlex>
          )}

          <Flex>
            <DisconnectMetamask />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default BalanceWallets;
