import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type LastCommentModel = {
  newsId: string;
  tournamentId: string;
  eventId: string;
  date: string;
  name: string;
  comments: number;
  isUpdated: boolean;
};

export interface GetLastCommentsQuery {
  getLastComments:
    | {
        __typename: 'GetLastComments';
        comments: LastCommentModel[];
      }
    | GraphqlErrorType;
}

export interface GetLastCommentsQueryVars {
  sport?: string;
}

const GET_LAST_COMMENTS = gql`
  query GetLastComments($sport: String) {
    getLastComments(sport: $sport) {
      __typename
      ... on GetLastComments {
        comments {
          newsId
          tournamentId
          eventId
          date
          name
          comments
          isUpdated
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_LAST_COMMENTS;
