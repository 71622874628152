import { useRouter } from 'next/router';
import React from 'react';

import webApp from 'application/web-app';

import NextTournaments from './next-tournaments';
import useNextTournaments from './use-next-tournaments';

const NextTournamentsConnected: React.FC<{ sport?: string }> = ({ sport }) => {
  const { tournaments } = useNextTournaments(sport);
  const router = useRouter();

  return (
    <NextTournaments
      tournaments={tournaments}
      onTournamentSelect={(id) => {
        router.push(webApp.router.getTournamnt(id));
      }}
    />
  );
};

export default NextTournamentsConnected;
