import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import ArrowRightIcon from 'assets/icons/arrow-right-m.svg';
import { Card, Checkbox } from 'ui';
import Accordion from 'ui/accordion';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { Radio } from 'ui/radio/radio';
import { RadioGroup } from 'ui/radio/radio-group';

import css from './moderated-news-list-filters.module.scss';
import useWindowDimensions from '../../../../application/hooks/use-window-dimensions';
import { EventSportType } from '../../graphql/get-event-sports-list';
import { CategoryType } from '../../graphql/get-news-categories';
import { NewsReviewingStatusType } from '../../graphql/get-news-reviewing-statuses-list';
import {
  ModeratedNewsListDateSortType,
  ModeratedNewsListFiltersType,
  ModeratedNewsListReviewingStatusesType,
} from '../../hooks/use-set-moderated-news-list-filters';

interface ModeratedNewsListFiltersProps {
  filters: ModeratedNewsListFiltersType;
  sportsList: EventSportType[];
  categoriesList: CategoryType[];
  statusesList: NewsReviewingStatusType[];
  dateChange: (date: ModeratedNewsListDateSortType) => void;
  sportChange: (sport: string) => void;
  categoryChange: (category: string) => void;
  statusChange: (status: ModeratedNewsListReviewingStatusesType) => void;
  resetAllFilters: () => void;
}

const ModeratedNewsListFilters: React.FC<ModeratedNewsListFiltersProps> = ({
  filters,
  dateChange,
  sportsList,
  statusesList,
  categoriesList,
  sportChange,
  categoryChange,
  statusChange,
  resetAllFilters,
}) => {
  const { sports, sort: date, status: statusId, categories } = filters;
  const { t } = useTranslation(['news', 'period']);
  const windowSize = useWindowDimensions();
  const [isMobileContainerActive, setIsMobileContainerActive] = useState(false);

  const buttonDisabled =
    !sports.length &&
    !categories.length &&
    date === ModeratedNewsListDateSortType.LAST &&
    statusId === ModeratedNewsListReviewingStatusesType.ALL;

  useEffect(() => {
    if (windowSize.width < 900) {
      setIsMobileContainerActive(true);
    }
  }, [windowSize.width]);

  return (
    <div className={css.Root}>
      <Card>
        {windowSize.width < 900 && (
          <p
            className={css.FiltersTitle}
            onClick={() => setIsMobileContainerActive(!isMobileContainerActive)}
          >
            {t('news:labels:filters')}
            <ArrowRightIcon
              style={{
                marginLeft: '22px',
                transform: `rotate(${
                  isMobileContainerActive ? '90deg' : '270deg'
                })`,
              }}
            />
          </p>
        )}

        <div
          className={cx(
            css.Container,
            isMobileContainerActive && css.ContainerMobile
          )}
        >
          <div className={css.Filter}>
            <Accordion
              label={t('common:period:date')}
              className={cx(css.Label, css.DateContainer)}
              childrenClassName={cx(css.FilterItemsContainer, css.DataItems)}
            >
              <RadioGroup onChange={dateChange}>
                <Radio
                  value={ModeratedNewsListDateSortType.LAST}
                  checked={date === ModeratedNewsListDateSortType.LAST}
                  key={ModeratedNewsListDateSortType.LAST}
                >
                  {t('common:period:new_first')}
                </Radio>
                <Radio
                  value={ModeratedNewsListDateSortType.ORDER}
                  checked={date === ModeratedNewsListDateSortType.ORDER}
                  key={ModeratedNewsListDateSortType.ORDER}
                >
                  {t('common:period:order')}
                </Radio>
              </RadioGroup>
            </Accordion>
          </div>

          <div className={css.Filter}>
            {sports.length > 0 && (
              <div className={css.FilterCount}>{sports.length}</div>
            )}
            <Accordion
              label={t('news:labels:sport')}
              className={css.Label}
              childrenClassName={cx(
                css.FilterItemsContainer,
                css.SportItemsContainer
              )}
            >
              <Flex flexDirection="column" gap={GridGap.x2}>
                {sportsList.map((sport) => (
                  <Checkbox
                    key={sport.id}
                    checked={sports.includes(sport.id)}
                    onCheck={() => sportChange(sport.id)}
                  >
                    {sport.name}
                  </Checkbox>
                ))}
              </Flex>
            </Accordion>
          </div>

          <div className={css.Filter}>
            {categories.length > 0 && (
              <div className={css.FilterCount}>{categories.length}</div>
            )}
            <Accordion
              label={t('news:labels:category')}
              className={css.Label}
              childrenClassName={cx(
                css.FilterItemsContainer,
                css.CategoryItemsContainer
              )}
            >
              <Flex flexDirection="column" gap={GridGap.x2}>
                {categoriesList.map((category) => (
                  <Checkbox
                    key={category.id}
                    checked={categories.includes(category.id)}
                    onCheck={() => categoryChange(category.id)}
                  >
                    {category.name}
                  </Checkbox>
                ))}
              </Flex>
            </Accordion>
          </div>

          <div className={css.Filter}>
            <Accordion
              label={t('news:labels:status')}
              className={cx(css.Label, css.StatusContainer)}
              childrenClassName={css.FilterItemsContainer}
            >
              <RadioGroup onChange={statusChange}>
                {statusesList.map((status) => (
                  <Radio
                    value={status.id}
                    checked={statusId === status.id}
                    key={status.id}
                  >
                    {status.status}
                  </Radio>
                ))}
              </RadioGroup>
            </Accordion>
          </div>

          <button
            className={css.ResetButton}
            onClick={resetAllFilters}
            disabled={buttonDisabled}
          >
            {t('news:actions:reset_all')}
          </button>
        </div>
      </Card>
    </div>
  );
};

export default ModeratedNewsListFilters;
