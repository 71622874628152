import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { i18n } from 'next-i18next';

import { errorNotification } from 'application/notification';

export const verifyApolloQueryResponse = <T>(
  data: T,
  errors: ApolloError | undefined
): T | undefined => {
  if (errors) {
    errorNotification(errors.message);
    return;
  }

  if (!data) {
    errorNotification(i18n!.t('common:errors:undefined_error'));
    return;
  }

  return data;
};

export const verifyApolloMutationResponse = <T>(
  data: T,
  errors: readonly GraphQLError[] | undefined
): T | undefined => {
  if (errors) {
    errorNotification(errors.map((item) => item.message).join(', '));
    return;
  }

  if (!data) {
    errorNotification(i18n!.t('common:errors:undefined_error'));
    return;
  }

  return data;
};

export const processApolloWarnings = (
  warnings: { key: string; warning: string }[]
) => warnings.reduce((prev, cur) => ({ ...prev, [cur.key]: cur.warning }), {});
