import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { CommentModel } from '../commets-type';

export interface AddCommentMutation {
  addComment:
    | {
        __typename: 'AddComment';
        comment: CommentModel;
        totalCount: number;
      }
    | GraphqlErrorType;
}

export interface AddCommentMutationVars {
  tournamentId?: string;
  eventId?: string;
  newsId?: string;
  commentId?: string;
  comment: string;
  recipientId?: string;
}

const ADD_COMMENT = gql`
  mutation AddComment(
    $tournamentId: ID
    $eventId: ID
    $newsId: ID
    $commentId: ID
    $comment: String!
    $recipientId: ID
  ) {
    addComment(
      tournamentId: $tournamentId
      eventId: $eventId
      newsId: $newsId
      commentId: $commentId
      comment: $comment
      recipientId: $recipientId
    ) {
      __typename
      ... on AddComment {
        totalCount
        comment {
          id
          comment
          date
          parentId
          client {
            id
            name
            avatar
            iq
          }
          recipient {
            id
            name
          }
          childCount
          isLiked
          likesCount
          isUpdated
          tournamentId
          eventId
          newsId
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default ADD_COMMENT;
