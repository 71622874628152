import cx from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import webApp from 'application/web-app';
import { EventSportType } from 'features/news/graphql/get-event-sports-list';

import css from './sports-menu.module.scss';

interface SportsMenuProps {
  sports: Omit<EventSportType, 'icon'>[];
  closeMenu: () => void;
}

const SportsMenu: React.FC<SportsMenuProps> = ({ closeMenu, sports }) => {
  const router = useRouter();
  const { t } = useTranslation(['header']);

  return (
    <ul className={css.Root} onMouseLeave={closeMenu}>
      <p className={css.Title}>{t('header:sports')}</p>

      <li
        className={cx(css.Item, !router.query.url && css.ItemActive)}
        onClick={() => {
          router.push(`/`);
        }}
      >
        {t('header:all')}
      </li>

      {sports.map((sport) => (
        <li
          className={cx(
            css.Item,
            sport.alias === router.query.url && css.ItemActive
          )}
          key={sport.id}
          onClick={() => {
            router.push(webApp.router.getSport(sport.alias));
            closeMenu();
          }}
        >
          {sport.name}
        </li>
      ))}
    </ul>
  );
};

export default SportsMenu;
