const minTwoDigits = (n: number) => {
  return (n < 10 ? '0' : '') + n;
};

const roundNumber = (num: number, decimalPlaces: number) => {
  const p = 10 ** decimalPlaces;
  const e = Number.EPSILON * num * p;

  return Math.round(num * p + e) / p;
};

const formatNumber = (num: number) => {
  return num.toLocaleString('en-US', {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  });
};

export { minTwoDigits, roundNumber, formatNumber };
