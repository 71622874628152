import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { useUser } from 'application/context/user-provider';
import WebApp from 'application/web-app';

import CommentHeader from './comment-header';
import { CommentModel } from '../../commets-type';
import useDeleteMyComment from '../../hooks/use-delete-my-comment';
import DeleteCommentModal from '../../modals/delete-comment-modal';
import { removeEmptyProperties } from '../../utils/removeEmptyProperties';
import { SortType } from '../comments-list';

interface CommentHeaderContainerProps {
  comment: CommentModel;
  editMyComment?: () => void;
}

const CommentHeaderContainer: React.FC<CommentHeaderContainerProps> = ({
  comment,
  editMyComment,
}) => {
  const {
    id,
    isUpdated,
    client,
    date,
    newsId,
    eventId,
    tournamentId,
    parentId,
  } = comment;
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const { user } = useUser();
  const router = useRouter();

  const variables = parentId
    ? { commentId: parentId, sort: SortType.ORDER }
    : {
        newsId,
        eventId,
        tournamentId,
        sort: (router.query.sort as SortType) || SortType.LAST,
      };
  const deleteMyCommentMutation = useDeleteMyComment(
    removeEmptyProperties(variables),
    router.asPath.includes('tournament') ? (router.query.id as string) : ''
  );

  const deleteMyComment = async () => {
    const success = await deleteMyCommentMutation(id);
    if (success) {
      setDeleteCommentModalOpen(false);
      WebApp.eventEmitter.emitNewsCommentsChange();
    }
  };

  return (
    <>
      <CommentHeader
        isCommentUpdated={isUpdated}
        authorData={client}
        myComment={user?.id === client.id}
        date={date}
        deleteMyComment={() => setDeleteCommentModalOpen(true)}
        editMyComment={editMyComment}
      />

      {isDeleteCommentModalOpen && (
        <DeleteCommentModal
          isDeleteCommentModalOpen={isDeleteCommentModalOpen}
          deleteComment={deleteMyComment}
          closeModal={() => setDeleteCommentModalOpen(false)}
        />
      )}
    </>
  );
};

export default CommentHeaderContainer;
