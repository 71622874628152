import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { dateToTextShort } from 'application/utils/date';
import { Toast } from 'ui';

import GET_LAST_COMMENTS, {
  GetLastCommentsQuery,
  GetLastCommentsQueryVars,
} from '../../graphql/get-last-comments';

const useLastComments = (sport?: string) => {
  const [comments, setComments] = useState<
    {
      newsId: string | null;
      tournamentId: string | null;
      eventId: string | null;
      name: string;
      comment: string;
      time: string;
      isUpdated: boolean;
    }[]
  >([]);

  const { data, error, loading } = useQuery<
    GetLastCommentsQuery,
    GetLastCommentsQueryVars
  >(GET_LAST_COMMENTS, { variables: { sport }, fetchPolicy: 'network-only' });

  useEffect(() => {
    if (error) {
      Toast.error(error.message);
    }

    if (data?.getLastComments.__typename === 'Error') {
      Toast.error(data.getLastComments.error);
    }

    if (data?.getLastComments.__typename === 'GetLastComments') {
      setComments(
        data.getLastComments.comments.map((item) => ({
          newsId: item.newsId,
          tournamentId: item.tournamentId,
          eventId: item.eventId,
          name: item.name,
          comment: `${item.comments}`,
          time: dateToTextShort(new Date(item.date)),
          isUpdated: item.isUpdated,
        }))
      );
    }
  }, [data, error]);

  return {
    loading,
    comments,
  };
};

export default useLastComments;
