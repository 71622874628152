import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface RemoveCommentMutation {
  removeComment:
    | {
        __typename: 'RemoveComment';
        tournamentId: string;
        eventId: string;
        newsId: string;
        commentId: string;
        totalCount: number;
      }
    | GraphqlErrorType;
}

export interface RemoveCommentMutationVars {
  id: string;
}

const REMOVE_COMMENT = gql`
  mutation RemoveComment($id: ID!) {
    removeComment(id: $id) {
      __typename
      ... on RemoveComment {
        totalCount
        commentId
        tournamentId
        eventId
        newsId
      }
      ... on Error {
        error
      }
    }
  }
`;

export default REMOVE_COMMENT;
