import React from 'react';

import css from './title.module.scss';

interface TitleProps {
  children: React.ReactNode;
  id?: string;
}

export const Title: React.FC<TitleProps> = ({ children, id }) => {
  return (
    <span className={css.title} id={id}>
      {children}
    </span>
  );
};
