import { useTranslation } from 'next-i18next';
import React, { CSSProperties, useState } from 'react';

import css from 'ui/challenges/challenges.module.scss';
import { ChallengeItemProps } from 'ui/challenges/challenges.types';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import { Space } from 'ui/space/space';
import { Text } from 'ui/v2/typography/text';

export const ChallengeItem: React.FC<{
  item: ChallengeItemProps;
  style?: CSSProperties;
}> = ({ item, style }) => {
  const { t } = useTranslation(['common']);

  const [open, setOpen] = useState(false);
  const Image: React.FC<{ width: string; alt: string }> =
    item.img as unknown as React.FC;

  return (
    <div
      className={[
        css.challenger__block,
        item.active && css.active,
        !item.active && css.inactive,
      ].join(' ')}
      style={style}
      onClick={() => setOpen(!open)}
    >
      <Grid gridTemplateRows="27px 68px minmax(59px, 1fr)">
        <Space size={1} />
        <Grid alignItems="center" justifyItems="center">
          {<Image width="48px" alt={t('common:alt:challenge')} />}
        </Grid>
        <Grid gap={GridGap.x0}>
          <Text type="caption" color="black-primary" textAlign="center" strong>
            {item.text}
          </Text>
          {open ? (
            <div className={css.challenger__block__item}>
              <Text type="caption2" textAlign="center" color="grey-primary-7">
                {item.desc}
              </Text>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
