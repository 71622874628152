import React from 'react';

import NewsFeed from './news-feed';
import useNewsFeed from '../../hooks/use-news-feed';

const NewsFeedContainer: React.FC<{ sport?: string }> = ({ sport }) => {
  const { loading, triggerRef, data } = useNewsFeed();

  if (data?.getNewsList.__typename !== 'GetNewsList') {
    return null;
  }

  return (
    <NewsFeed
      news={data.getNewsList.news}
      loading={loading}
      triggerRef={triggerRef}
      sport={sport}
      showWidgets
    />
  );
};

export default NewsFeedContainer;
