import { useRouter } from 'next/router';
import React, { useState } from 'react';

import Comment from './comment';
import type { CommentModel } from '../../commets-type';
import useUpdateMyComment from '../../hooks/use-update-my-comment';
import { SortType } from '../comments-list';

interface CommentContainerProps {
  comment: CommentModel;
  updateCommentsData: () => void;
}

const CommentContainer: React.FC<CommentContainerProps> = ({
  comment,
  updateCommentsData,
}) => {
  const { id, parentId, eventId, newsId, tournamentId, recipient } = comment;
  const [isCommentEditingOpen, setCommentEditingOpen] = useState(false);
  const [commentText, setCommentText] = useState(comment.comment);
  const [isCommentUpdateLoading, setCommentUpdateLoading] = useState(false);
  const router = useRouter();

  const variables = parentId
    ? { commentId: parentId, sort: SortType.ORDER }
    : {
        newsId,
        eventId,
        tournamentId,
        sort: (router.query.sort as SortType) || SortType.LAST,
      };
  const updateCommentMutation = useUpdateMyComment(variables);

  const updateComment = async () => {
    setCommentUpdateLoading(true);

    const result = await updateCommentMutation({
      id: id,
      comment: commentText,
      recipientId: recipient?.id || '',
    });

    if (result) {
      setCommentEditingOpen(false);
    }
  };

  return (
    <Comment
      comment={comment}
      commentText={commentText}
      setCommentText={setCommentText}
      isCommentEditingOpen={isCommentEditingOpen}
      isCommentUpdateLoading={isCommentUpdateLoading}
      updateComment={updateComment}
      updateCommentsData={updateCommentsData}
      openCommentEditing={() => setCommentEditingOpen(true)}
      closeCommentEditing={() => setCommentEditingOpen(false)}
    />
  );
};

export default CommentContainer;
