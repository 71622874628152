import { useTranslation } from 'next-i18next';
import React from 'react';

import { Loader } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './bookmarks-news-list.module.scss';
import { BookmarksNews } from '../../graphql/get-bookmarks-news-list';
import { ComponentOrigins } from '../../types/component-origins';
import PublishedNewsPreview from '../published-news-preview';

interface BookmarksNewsListProps {
  news: BookmarksNews[];
  loading: boolean;
}

const BookmarksNewsList: React.FC<BookmarksNewsListProps> = ({
  news,
  loading,
}) => {
  const { t } = useTranslation(['news']);

  if (news.length === 0) {
    return <p className={css.Title}>{t('news:labels:no_bookmarks')}</p>;
  }

  return (
    <Flex flexDirection="column" gap={GridGap.x3}>
      {news.map((news) => (
        <PublishedNewsPreview
          news={news}
          key={news.id}
          componentOrigin={ComponentOrigins.BOOKMARKS_LIST}
        />
      ))}

      {loading && (
        <Flex alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      )}
    </Flex>
  );
};

export default BookmarksNewsList;
