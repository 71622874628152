import { useMutation } from '@apollo/client';
import { i18n } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_TOURNAMENT_INFO, {
  TournamentInfo,
} from '../../tournament/graphql/get-tournament';
import { CommentModel } from '../commets-type';
import { SortType } from '../components/comments-list';
import ADD_COMMENT, {
  AddCommentMutation,
  AddCommentMutationVars,
} from '../graphql/add-comment';
import GET_COMMENTS, { GetCommentsQueryVars } from '../graphql/get-comments';
import GET_TOURNAMENT_EVENTS_COMMENTS, {
  EventType,
} from '../graphql/get-tournament-events-comments';

const useAddComment = (params: GetCommentsQueryVars, tournamentId?: string) => {
  const [addComment] = useMutation<AddCommentMutation, AddCommentMutationVars>(
    ADD_COMMENT,
    {
      update: async (cache, { data }) => {
        if (data?.addComment.__typename !== 'AddComment') return;

        const newCommentData = data.addComment;

        const commentsList: {
          getComments: { comments: CommentModel[] };
        } | null = cache.readQuery({
          query: GET_COMMENTS,
          variables: params,
        });

        if (commentsList?.getComments.comments) {
          const updatedComments =
            params.sort === SortType.LAST
              ? [newCommentData.comment, ...commentsList.getComments.comments]
              : [...commentsList.getComments.comments, newCommentData.comment];

          cache.writeQuery({
            query: GET_COMMENTS,
            variables: params,
            data: {
              getComments: {
                ...commentsList.getComments,
                totalCount: newCommentData.totalCount,
                comments: updatedComments,
              },
            },
          });
        }

        cache.modify({
          fields: {
            getComments(existingCommentsData, { readField }) {
              if (readField('level', existingCommentsData) === 1) {
                return {
                  ...existingCommentsData,
                  totalCount: newCommentData.totalCount,
                };
              }
              return existingCommentsData;
            },
          },
        });

        if (!tournamentId) return;

        const tournamentInfo: {
          getTournamentInfo: {
            __typename: 'GetTournamentInfo';
          } & TournamentInfo;
        } | null = cache.readQuery({
          query: GET_TOURNAMENT_INFO,
          variables: {
            id: tournamentId,
          },
        });

        if (tournamentInfo && newCommentData.comment.eventId) {
          const newEvents = tournamentInfo.getTournamentInfo.events.map(
            (event) =>
              event.id === newCommentData.comment.eventId
                ? {
                    ...event,
                    commentsCount: newCommentData.totalCount,
                  }
                : event
          );

          cache.writeQuery({
            query: GET_TOURNAMENT_INFO,
            variables: {
              id: tournamentId,
            },
            data: {
              getTournamentInfo: {
                ...tournamentInfo.getTournamentInfo,
                events: newEvents,
              },
            },
          });
        }

        const tournamentEventsComments: {
          getTournamentEventsComments: {
            count: number;
            events: EventType[];
          };
        } | null = cache.readQuery({
          query: GET_TOURNAMENT_EVENTS_COMMENTS,
          variables: {
            id: tournamentId,
          },
        });

        if (!tournamentEventsComments) return;

        if (newCommentData.comment.tournamentId) {
          cache.writeQuery({
            query: GET_TOURNAMENT_EVENTS_COMMENTS,
            variables: {
              id: tournamentId,
            },
            data: {
              getTournamentEventsComments: {
                ...tournamentEventsComments.getTournamentEventsComments,
                count: newCommentData.totalCount,
              },
            },
          });
        }

        if (newCommentData.comment.eventId) {
          const newEvents =
            tournamentEventsComments.getTournamentEventsComments.events.map(
              (event) =>
                event.id === newCommentData.comment.eventId
                  ? {
                      ...event,
                      count: newCommentData.totalCount,
                    }
                  : event
            );

          cache.writeQuery({
            query: GET_TOURNAMENT_EVENTS_COMMENTS,
            variables: {
              id: tournamentId,
            },
            data: {
              getTournamentEventsComments: {
                ...tournamentEventsComments.getTournamentEventsComments,
                events: newEvents,
              },
            },
          });
        }
      },
    }
  );

  return async (variables: AddCommentMutationVars) => {
    try {
      const { data, errors } = await addComment({
        variables,
      });

      const isAddCommentSuccess = verifyApolloMutationResponse(data, errors);
      if (!isAddCommentSuccess) return;

      if (data?.addComment.__typename === 'Error') {
        Toast.error(data.addComment.error);
        return;
      }

      if (data?.addComment.__typename === 'AddComment') {
        return data.addComment.comment.id;
      }
    } catch (e: unknown) {
      Toast.error(i18n!.t('comments:no_comment_added'));
    }
  };
};

export default useAddComment;
