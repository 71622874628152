import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { CommentModel } from '../commets-type';

export interface UpdateMyCommentMutation {
  updateMyComment:
    | {
        __typename: 'UpdateMyComment';
        comment: CommentModel;
      }
    | GraphqlErrorType;
}

export interface UpdateMyCommentMutationVars {
  id: string;
  comment: string;
  recipientId?: string;
}

const UPDATE_MY_COMMENT = gql`
  mutation UpdateMyComment($id: ID!, $comment: String!, $recipientId: ID) {
    updateMyComment(id: $id, comment: $comment, recipientId: $recipientId) {
      ... on UpdateMyComment {
        comment {
          id
          comment
          date
          parentId
          client {
            id
            name
            avatar
          }
          recipient {
            id
            name
          }
          isLiked
          likesCount
          isUpdated
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPDATE_MY_COMMENT;
