import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { useUser } from 'application/context/user-provider';
import WebApp from 'application/web-app';

import CommentFooter from './comment-footer';
import { CommentModel } from '../../commets-type';
import useBanCommentAuthor from '../../hooks/use-ban-comment-author';
import useLikeComment from '../../hooks/use-like-comment';
import useRemoveComment from '../../hooks/use-remove-comment';
import BanCommentAuthorModal from '../../modals/ban-comment-author-modal';
import DeleteCommentModal from '../../modals/delete-comment-modal';
import { removeEmptyProperties } from '../../utils/removeEmptyProperties';
import { SortType } from '../comments-list';

interface CommentFooterContainerProps {
  comment: CommentModel;
  updateCommentsData: () => void;
}

const CommentFooterContainer: React.FC<CommentFooterContainerProps> = ({
  comment,
  updateCommentsData,
}) => {
  const {
    likesCount,
    isLiked,
    id,
    client,
    eventId,
    tournamentId,
    parentId,
    newsId,
  } = comment;
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [isBanCommentAuthorModalOpen, setBanCommentAuthorModalOpen] =
    useState(false);
  const [isAnswerOpen, setAnswerOpen] = useState(false);
  const banCommentAuthor = useBanCommentAuthor();
  const { user } = useUser();
  const router = useRouter();
  const tournament = router.asPath.includes('tournament')
    ? (router.query.id as string)
    : '';
  const variables = parentId
    ? { commentId: parentId, sort: SortType.ORDER }
    : {
        newsId,
        eventId,
        tournamentId,
        sort: (router.query.sort as SortType) || SortType.LAST,
      };
  const removeCommentMutation = useRemoveComment(
    removeEmptyProperties(variables),
    tournament
  );
  const likeCommentMutation = useLikeComment(removeEmptyProperties(variables));

  const likeComment = () => {
    const newLikesCount = !isLiked
      ? Number(likesCount) + 1
      : Math.max(0, Number(likesCount) - 1);

    likeCommentMutation(id, !isLiked, newLikesCount);
  };

  const removeComment = async () => {
    const success = await removeCommentMutation(id);
    if (success) {
      setDeleteCommentModalOpen(false);
      WebApp.eventEmitter.emitNewsCommentsChange();
    }
  };

  return (
    <>
      <CommentFooter
        comment={comment}
        isAnswerOpen={isAnswerOpen}
        moderator={user?.moderator ?? false}
        removeComment={() => setDeleteCommentModalOpen(true)}
        banCommentAuthor={() => setBanCommentAuthorModalOpen(true)}
        openAnswerInput={() => !isAnswerOpen && setAnswerOpen(true)}
        sendAnswer={() => setAnswerOpen(false)}
        likeComment={likeComment}
        updateCommentsData={updateCommentsData}
      />

      {isDeleteCommentModalOpen && (
        <DeleteCommentModal
          isDeleteCommentModalOpen={isDeleteCommentModalOpen}
          deleteComment={removeComment}
          closeModal={() => setDeleteCommentModalOpen(false)}
        />
      )}

      {isBanCommentAuthorModalOpen && (
        <BanCommentAuthorModal
          authorName={client.name}
          isBanCommentModalOpen={isBanCommentAuthorModalOpen}
          banCommentAuthor={() => banCommentAuthor(id)}
          closeModal={() => setBanCommentAuthorModalOpen(false)}
        />
      )}
    </>
  );
};

export default CommentFooterContainer;
