import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type TrainingVideo = {
  videoRegistration: string;
  videoVote1: string;
  videoVote2: string;
};

export interface GetTrainingVideosQuery {
  getTrainingVideos:
    | {
        __typename: 'GetTrainingVideos';
        videos: TrainingVideo;
      }
    | GraphqlErrorType;
}

export interface GetTrainingVideoQueryVars {}

const GET_TRAINING_VIDEOS = gql`
  query GetTrainingVideos {
    getTrainingVideos {
      ... on GetTrainingVideos {
        videos {
          videoRegistration
          videoVote1
          videoVote2
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_TRAINING_VIDEOS;
