import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { IndexCard } from 'ui';
import Button from 'ui/button';
import { ArrowDownIcon } from 'ui/icons';
import Tabs from 'ui/tabs';

import { dateToTextShortAll } from '../../../../application/utils/date';
import useGetPopularNewsList from '../../../news/hooks/use-get-popular-news-list';
import { PopularNewsListDataType } from '../../../news/types/popular-news-list-data-type';
import css from '../top-news-widget/top-news-widget.module.scss';

const MAX_PAGES = 3;

export const TopNewsWidget = () => {
  const { t } = useTranslation(['news']);
  const sortOptions = [
    { value: 'today', label: t('news:tabs:today') },
    { value: 'week', label: t('news:tabs:week') },
    { value: 'month', label: t('news:tabs:month') },
  ];
  const [sort, setSort] = useState(sortOptions[0].value);
  const [page, setPage] = useState(1);
  const [newsList, setNewsList] = useState<PopularNewsListDataType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const { data: newNews, loading } = useGetPopularNewsList(page, sort);

  useEffect(() => {
    if (newNews) {
      setNewsList((prevNews) =>
        page === 1 ? newNews : [...prevNews, ...newNews]
      );

      if (newNews.length < 5 || page >= MAX_PAGES) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setLoadingMore(false);
    }
  }, [newNews, page]);

  const handleSortChange = (newSort: string) => {
    setSort(newSort);
    setPage(1);
    setNewsList([]);
    setHasMore(true);
    setLoadingMore(false);
  };

  const handleShowMore = () => {
    if (hasMore && !loadingMore && page < MAX_PAGES) {
      setLoadingMore(true);
      setPage(page + 1);
    }
  };

  if (loading && page === 1) return <div>Загрузка...</div>;

  return (
    <IndexCard small>
      <h3 className={css.widgetTitle}>{t('news:labels:popular')}</h3>
      <Tabs
        value={sort}
        items={sortOptions}
        onSelect={handleSortChange}
        type="small"
      />
      <ul className={css.newsList}>
        {newsList?.map((news, index) => (
          <li key={news.id} className={css.newsItem}>
            <div className={css.newsLinkBlock}>
              <a className={css.newsTitleLink} href={`/news/${news.id}`}>
                {news.title}
              </a>
              {index < newsList.length - 1 && <div className={css.separator} />}
            </div>
          </li>
        ))}
      </ul>

      {hasMore && (
        <div
          className={`${css.showMoreContainer} ${
            loadingMore ? css.Hidden : ''
          }`}
        >
          <Button
            className={css.ShowMoreButton}
            onClick={handleShowMore}
            disabled={loadingMore || page >= MAX_PAGES}
          >
            {t('news:actions:show_more')}
            <ArrowDownIcon className={css.showMoreIcon} />
          </Button>
        </div>
      )}
      {loadingMore && <div className={css.LoadingText}>Загрузка...</div>}
    </IndexCard>
  );
};
