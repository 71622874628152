import React from 'react';

import css from './progress-line.module.scss';

interface ProgressLineProps {
  value1: number;
  value2: number;
  color1?: string;
  color2?: string;
}

const ProgressLine: React.FC<ProgressLineProps> = ({
  value1,
  value2,
  color1,
  color2,
}) => (
  <div className={css.Root} style={{ backgroundColor: 'green' }}>
    <div
      className={css.Progress1}
      style={{ width: `${value1}%`, backgroundColor: color1 }}
    />
    <div
      className={css.Progress2}
      style={{ width: `${value2}%`, backgroundColor: color2 }}
    />
  </div>
);

export default ProgressLine;
