import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import DELETE_PUBLISHED_NEWS, {
  DeletePublishedNewsMutation,
  DeletePublishedNewsMutationVars,
} from '../graphql/delete-published-news';
import GET_NEWS_LIST, { GetNewsListQueryVars } from '../graphql/get-news-list';
import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

const useDeletePublishedNews = (newsListVariables?: GetNewsListQueryVars) => {
  const { t } = useTranslation(['news']);
  const [deletePublishedNewsMutation] = useMutation<
    DeletePublishedNewsMutation,
    DeletePublishedNewsMutationVars
  >(DELETE_PUBLISHED_NEWS, {
    update(cache, { data }) {
      if (
        data?.deletePublishedNews.__typename !== 'DeletePublishedNews' ||
        !newsListVariables
      ) {
        return;
      }

      const deletedNewsId = data.deletePublishedNews.newsId;
      const newsList: {
        getNewsList: { news: OnePublishedNewsDataType[] };
      } | null = cache.readQuery({
        query: GET_NEWS_LIST,
        variables: newsListVariables,
      });

      if (!newsList || !deletedNewsId) return;

      const updatedNewsList = newsList.getNewsList.news.filter(
        (news) => news.id !== deletedNewsId
      );

      cache.writeQuery({
        query: GET_NEWS_LIST,
        variables: newsListVariables,
        data: {
          getNewsList: {
            ...newsList.getNewsList,
            news: updatedNewsList,
          },
        },
      });
    },
  });

  return async (id: string) => {
    try {
      const { data, errors } = await deletePublishedNewsMutation({
        variables: { id },
      });

      const isDeleteNewsSuccess = verifyApolloMutationResponse(data, errors);
      if (!isDeleteNewsSuccess) return;

      if (data?.deletePublishedNews.__typename === 'Error') {
        Toast.error(data.deletePublishedNews.error);
        return;
      }

      if (data?.deletePublishedNews.__typename === 'DeletePublishedNews') {
        Toast.success(t('news:successes:article_delete_success'));
        return data.deletePublishedNews.newsId;
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:article_delete_error'));
    }
  };
};

export default useDeletePublishedNews;
