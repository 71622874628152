import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { formatDatetime } from 'application/utils/date';
import webApp from 'application/web-app';
import { IndexCard } from 'ui';
import WidgetHeader from 'ui/widget-header';

import ArrowIcon from './arrow.svg';
import css from './last-announce.module.scss';

type LastAnnounceType = {
  id: string;
  tournamentId: string;
  name: string;
  date: Date;
  description: string;
  opponent1: {
    flag: string;
    name: string;
    image: string | null;
    coeff: number;
    score: string;
  };
  opponent2: {
    flag: string;
    name: string;
    image: string | null;
    coeff: number;
    score: string;
  };
};

interface LastAnnounceProps {
  announces: LastAnnounceType[];
}

type FlagType = keyof typeof Flags;

const LastAnnounce: React.FC<LastAnnounceProps> = ({ announces }) => {
  const { t } = useTranslation(['announcements', 'common']);

  return (
    <IndexCard small>
      <WidgetHeader
        href=""
        title={t('announcements:latest_announcements')}
        NoBorderBottom
      />

      {announces.length === 0 && (
        <p className={css.NoData}>{t('announcements:no_announcements')}</p>
      )}

      {announces.map((announce) => {
        const Country1Node =
          announce.opponent1.flag && hasFlag(announce.opponent1.flag)
            ? Flags[announce.opponent1.flag as FlagType]
            : null;
        const Country2Node =
          announce.opponent2.flag && hasFlag(announce.opponent2.flag)
            ? Flags[announce.opponent2.flag as FlagType]
            : null;

        return (
          <Link
            className="noLink"
            href={webApp.router.getTournamnt(announce.tournamentId, {
              eventId: announce.id,
            })}
            key={announce.id}
          >
            <div className={css.Item}>
              <div className={css.Block}>
                <div
                  className={css.Logo}
                  style={{
                    backgroundImage: `url(${
                      announce.opponent1.image || '/images/no-image.png'
                    })`,
                  }}
                />
                <div className={css.Text}>
                  <div className={css.Name}>
                    {Country1Node && <Country1Node className={css.Flag} />}
                    <span>{announce.opponent1.name}</span>
                  </div>
                  <div className={css.Statistic}>
                    <span>{announce.opponent1.coeff}</span>
                    <span>{announce.opponent1.score}</span>
                  </div>
                </div>
              </div>
              <div className={css.Block}>
                <div
                  className={css.Logo}
                  style={{
                    backgroundImage: `url(${
                      announce.opponent2.image || '/images/no-image.png'
                    })`,
                  }}
                />
                <div className={css.Text}>
                  <div className={css.Name}>
                    {Country2Node && <Country2Node className={css.Flag} />}
                    <span>{announce.opponent2.name}</span>
                  </div>
                  <div className={css.Statistic}>
                    <span>{announce.opponent2.coeff}</span>
                    <span>{announce.opponent2.score}</span>
                  </div>
                </div>
              </div>
              <div className={css.AnnounceText}>
                <span>
                  {formatDatetime(announce.date)}, {announce.name}
                </span>
                <span>{announce.description}</span>
              </div>
            </div>
          </Link>
        );
      })}

      {announces.length > 0 && (
        <div>
          <Link
            className="noLink"
            href={webApp.router.getTournamnt(announces[0].tournamentId)}
          >
            <div className={css.Description}>
              <span>{t('common:labels:full_card')}</span>
              <ArrowIcon />
            </div>
          </Link>
        </div>
      )}
    </IndexCard>
  );
};

export default LastAnnounce;
