import { useTranslation } from 'next-i18next';

import { StickyColumn } from 'features/sticky-column/sticky-column';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { SortingButton } from 'ui/sorting-button';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';
import { Text } from 'ui/v2/typography/text';

import css from '../../user-statistic-events.module.scss';

interface Props {
  gridTemplateColumns: string;
  gridTemplateColumnsTablet: string;
  gridTemplateColumnsMobile: string;
  sort: SortDirection;
  setSort: (direction: SortDirection) => void;
}

const EventsListTableHeader = ({
  gridTemplateColumns,
  gridTemplateColumnsTablet,
  gridTemplateColumnsMobile,
  setSort,
  sort,
}: Props) => {
  const { t } = useTranslation(['period', 'events', 'common']);

  return (
    <ResponsiveGrid
      alignItems="center"
      gap={GridGap.x2}
      gridTemplateColumns={gridTemplateColumns}
      mobile={{ gridTemplateColumns: gridTemplateColumnsMobile }}
      tablet={{ gridTemplateColumns: gridTemplateColumnsTablet }}
      gridAutoRows="40px"
      className={css.Table}
    >
      <SortingButton
        defaultDirection={sort}
        active={sort !== SortDirection.NONE}
        onChange={setSort}
        className={css.Sort}
      >
        {t('common:period:date')}
      </SortingButton>
      <Text type="headline">{t('events:event')}</Text>
      <Text type="headline">{t('bets:outcome')}</Text>
      <Text type="headline" textAlign="center">
        {t('bets:coeff')}
      </Text>
      <Text type="headline" textAlign="center">
        {t('bets:amount')}
      </Text>
      <Text type="headline" textAlign="center">
        Win
      </Text>
      <StickyColumn>
        <Text type="headline" textAlign="center">
          PnL
        </Text>
      </StickyColumn>
    </ResponsiveGrid>
  );
};

export default EventsListTableHeader;
