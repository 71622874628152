import { useTranslation } from 'next-i18next';

import { Button, Loader } from 'ui';
import { Flex } from 'ui/flex';
import { Text } from 'ui/v2/typography/text';

import EventsList from './events-list';
import { PAGE_LIMIT_PARI } from '../../graphql/get-user-statistic-pari-events';
import { useGetStatisticLive } from '../../hooks/use-get-statistic-pari';

const PariEventsList = () => {
  const { t } = useTranslation(['common']);
  const { data, loading, sort, onGetNextPage } = useGetStatisticLive();

  if (data?.getPariStatsByEvents.__typename !== 'GetPariStatsByEvents') {
    return null;
  }

  return (
    <>
      {data.getPariStatsByEvents.events.length === 0 ? (
        <Text type="paragraph" textAlign="center">
          {t('common:labels:no_data')}
        </Text>
      ) : (
        <EventsList
          pariList={data.getPariStatsByEvents.events}
          sort={sort.direction}
          setSort={sort.setDirection}
        />
      )}
      {loading && (
        <Flex justifyContent="center" alignItems="center">
          <Loader />
        </Flex>
      )}
      {data.getPariStatsByEvents.nav.hasNext && (
        <Flex justifyContent="center">
          <Button
            color="empty"
            onClick={() => onGetNextPage(PAGE_LIMIT_PARI)}
            disabled={loading}
          >
            {t('common:actions:load_more')}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default PariEventsList;
