import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import GET_MY_CLIENT from 'application/graphql/get-my-client';
import webApp from 'application/web-app';
import TWO_FACTOR_CONFIRM_SIGNIN, {
  TwoFactorConfirmSigninMutation,
  TwoFactorConfirmSigninMutationVars,
} from 'features/signin/graphql/confirm-signin';
import { Button, Input, Modal, ModalContent } from 'ui';
import { Text } from 'ui/v2/typography/text';

import css from './confirm-singin.module.scss';

interface SigninModalProps {
  confirmState: string | null;
  onConfirm: () => void;
}

const ConfirmSigninModal: React.FC<SigninModalProps> = ({
  confirmState,
  onConfirm,
}) => {
  const { t } = useTranslation(['auth']);
  const [emailCode, setEmailCode] = useState('');
  const [confirmError, setConfirmError] = useState<string | null>(null);

  const [confirmSignMutation, { loading }] = useMutation<
    TwoFactorConfirmSigninMutation,
    TwoFactorConfirmSigninMutationVars
  >(TWO_FACTOR_CONFIRM_SIGNIN, { refetchQueries: [GET_MY_CLIENT] });

  const confirmSignin = async () => {
    if (loading || !emailCode || !confirmState) {
      return;
    }

    const deviceIds = await webApp.getDeviceIds();

    const data = await confirmSignMutation({
      variables: {
        confirmState,
        emailCode,
        deviceId: deviceIds.deviceId,
        uniqId: deviceIds.uniqId,
      },
    });

    if (data.data?.twoFactorConfirmSignin.__typename === 'Error') {
      setConfirmError(data.data.twoFactorConfirmSignin.error);
      return;
    }
    setConfirmError(null);
    onConfirm();
  };

  return (
    <Modal
      title={t('auth:confirm_singin_title')}
      isOpen={!!confirmState}
      width={375}
    >
      <ModalContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            type="input"
            value={emailCode}
            onChange={setEmailCode}
            placeholder={t('auth:confirm_singin_email_code_input')}
          />

          {confirmError ? (
            <Text type="caption3" color="red-primary">
              {confirmError}
            </Text>
          ) : (
            <Text type="caption3" color="grey-primary-7">
              {t('auth:confirm_singin_description')}
            </Text>
          )}
          <Button
            className={css.Button}
            onClick={confirmSignin}
            disabled={loading}
          >
            {t('auth:sign_in')}
          </Button>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmSigninModal;
