import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import webApp from 'application/web-app';
import { GENERAL_DISCUSSION_ID } from 'features/comments/components/tournament-comments';
import { IndexCard } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import WidgetHeader from 'ui/widget-header';

import CircleIcon from './circle.svg';
import CommentIcon from './comment.svg';
import css from './last-comments.module.scss';

interface LastCommentsProps {
  comments: {
    newsId: string | null;
    tournamentId: string | null;
    eventId: string | null;
    name: string;
    comment: string;
    time: string;
    isUpdated: boolean;
  }[];
}

const LastsComment: React.FC<LastCommentsProps> = ({ comments }) => {
  const { t } = useTranslation(['last-comments-widget']);

  return (
    <IndexCard small>
      <WidgetHeader href="" title={t('last-comments-widget:label')} />

      {comments.length === 0 && (
        <p className={css.NoData}>{t('last-comments-widget:empty')}</p>
      )}

      <Flex
        gap={GridGap.x1}
        flexDirection="column"
        className={css.ItemsContainer}
      >
        {comments.map((comment, index) => {
          const url =
            comment.newsId !== null
              ? webApp.router.getNewsComment(comment.newsId)
              : webApp.router.getTournamentComment(
                  comment.tournamentId!,
                  comment.eventId
                );

          const query =
            comment.newsId !== null
              ? { news: comment.newsId }
              : { event: comment.eventId || GENERAL_DISCUSSION_ID };

          return (
            <Link
              key={index}
              href={{ pathname: url, query }}
              className={css.Item}
            >
              <Flex flexDirection="column">
                <p className={css.NewsTitle}>{comment.name}</p>
                <Flex alignItems="center">
                  <CommentIcon />
                  <span className={css.NewsCommentsCount}>
                    {comment.comment}
                  </span>
                  <span className={css.Time}>
                    <CircleIcon />
                    {comment.time}
                  </span>
                  {comment.isUpdated && (
                    <span className={css.Updating}>
                      {t('last-comments-widget:changed')}
                    </span>
                  )}
                </Flex>
              </Flex>
            </Link>
          );
        })}
      </Flex>
    </IndexCard>
  );
};

export default LastsComment;
