import { useMutation } from '@apollo/client';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import GET_MY_CLIENT from 'application/graphql/get-my-client';
import WebApp from 'application/web-app';
import webApp from 'application/web-app';
import { Toast } from 'ui';

import ConfirmSigninModal from './confirm-singin';
import SigninModal, { SigninFormErrors } from './signin-modal';
import SIGNIN_CLIENT, {
  SigninClientMutation,
  SigninClientMutationVars,
} from '../../graphql/signin-client';

interface SigninModalConnectedProps {
  isOpen: boolean;
  onClose?: () => void;
  onGotToSignup: () => void;
  onGoToForgotPassword: () => void;
  noOverflow?: boolean;
}

const SigninModalConnected: React.FC<SigninModalConnectedProps> = ({
  isOpen,
  noOverflow,
  onClose,
  onGotToSignup,
  onGoToForgotPassword,
}) => {
  const { pathname, push } = useRouter();
  const params = useSearchParams();

  const { t } = useTranslation(['common']);

  const [formErrors, setFormErrors] = useState<SigninFormErrors>({});
  const [isProcessing, setProcessing] = useState(false);
  const [confirmState, setConfirmState] = useState<string | null>(null);

  const isSignInPage = pathname === webApp.router.getSingIn();

  const [signinClient] = useMutation<
    SigninClientMutation,
    SigninClientMutationVars
  >(SIGNIN_CLIENT, {
    refetchQueries: ({ data }) =>
      data?.signinClient.__typename === 'Error' ? [] : [GET_MY_CLIENT],
  });

  const onRedirectToIndexPage = () => void push(webApp.router.getIndexPage());

  useEffect(() => {
    const confirmState = params.get('confirmState');

    if (confirmState) {
      setConfirmState(confirmState);
      onClose?.();
    }
  }, [params, onClose]);

  const singIn = async (values: { email: string; password: string }) => {
    try {
      setProcessing(true);

      const deviceIds = await webApp.getDeviceIds();

      const { data, errors } = await signinClient({
        variables: {
          ...values,
          deviceId: deviceIds.deviceId,
          uniqId: deviceIds.uniqId,
        },
      });
      if (errors) {
        Toast.error(errors.map((item) => item.message).join(', '));
        return;
      }

      if (!data) {
        Toast.error(t('common:errors:undefined_error'));
        return;
      }

      if (data?.signinClient.__typename === 'Error') {
        if (data.signinClient.warnings) {
          return setFormErrors(
            data.signinClient.warnings.reduce(
              (prev, cur) => ({
                ...prev,
                [cur.key]: cur.warning,
              }),
              {}
            )
          );
        }

        Toast.error(data.signinClient.error);
        return;
      }
      WebApp.analytic.sendSigninEvent({ type: 'email' });

      if (!data.signinClient.needTwoFactorConfirm) {
        onClose?.();
        isSignInPage && onRedirectToIndexPage();
        return;
      }
      const { confirmState } = data.signinClient;

      onClose?.();
      setConfirmState(confirmState);
    } catch (e) {
      WebApp.log.exception(e);
    } finally {
      setProcessing(false);
    }
  };

  const onConfirm = () => {
    setConfirmState(null);
    isSignInPage && onRedirectToIndexPage();
  };

  return (
    <>
      <SigninModal
        noOverflow={noOverflow}
        noClose={isSignInPage}
        errors={formErrors}
        isOpen={isOpen}
        isProcessing={isProcessing}
        onClose={onClose}
        onGotToSignup={onGotToSignup}
        onGoToForgotPassword={onGoToForgotPassword}
        onSignin={singIn}
      />
      <ConfirmSigninModal confirmState={confirmState} onConfirm={onConfirm} />
    </>
  );
};

export default SigninModalConnected;
