import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { formatDatetime } from 'application/utils/date';
import getCountryFlag from 'application/utils/get-country-flag';
import getScoreMma from 'application/utils/get-score-mma';
import webApp from 'application/web-app';
import { DropdownButton, IndexCard, Loader } from 'ui';
import { Flex } from 'ui/flex';
import { Text } from 'ui/v2/typography/text';
import WidgetHeader from 'ui/widget-header';

import ArrowIcon from './arrow.svg';
import css from './next-events.module.scss';
import { NextEventMmaOpponent } from '../../graphql/get-next-events';

type NextEvent = {
  id: string;
  startDate: string;
  opponent1: {
    name: string;
    country: string;
    coef: number;
    score: string;
    image: string | null;
    mmaOpponent: NextEventMmaOpponent;
  };
  opponent2: {
    name: string;
    country: string;
    coef: number;
    score: string;
    image: string | null;
    mmaOpponent: NextEventMmaOpponent;
  };
};

interface NextEventsProps {
  tournament: string;
  loading: boolean;
  tournaments: {
    id: string;
    name: string;
    date: Date;
    sportType: string | null;
  }[];
  events: NextEvent[];
  onTournamentSelect: (id: string) => void;
}

const NextEvents: React.FC<NextEventsProps> = ({
  tournament,
  tournaments,
  events,
  loading,
  onTournamentSelect,
}) => {
  const activeTournament = tournaments.find((item) => item.id === tournament);
  const { t } = useTranslation(['events', 'common']);
  const noDataTextConditions = !loading && tournaments?.length === 0;

  return (
    <IndexCard small>
      <WidgetHeader href="" title={t('events:next_events')} />

      {!loading && tournaments.length > 0 && (
        <div className={css.Select}>
          <DropdownButton
            value={tournament}
            items={tournaments.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={onTournamentSelect}
          />

          <Text type="caption2" className={css.Date} color="black-primary">
            {activeTournament?.date && formatDatetime(activeTournament?.date)}
          </Text>
        </div>
      )}

      <div>
        {noDataTextConditions && (
          <p className={css.NoData}>{t('events:no_events')}</p>
        )}

        {loading && (
          <Flex alignItems="center" justifyContent="center">
            <Loader />
          </Flex>
        )}

        {!loading &&
          events.map((event) => {
            const Country1Node = getCountryFlag(
              event.opponent1.mmaOpponent.countryFlag
            );
            const Country2Node = getCountryFlag(
              event.opponent2.mmaOpponent.countryFlag
            );

            const statsOpponent1 = getScoreMma([
              event.opponent1.mmaOpponent.win,
              event.opponent1.mmaOpponent.lose,
              event.opponent1.mmaOpponent.draw,
            ]);

            const statsOpponent2 = getScoreMma([
              event.opponent2.mmaOpponent.win,
              event.opponent2.mmaOpponent.lose,
              event.opponent2.mmaOpponent.draw,
            ]);

            return (
              <div className={css.Item} key={event.id}>
                <div className={css.BlockWrapper}>
                  <Link
                    className="noLink"
                    href={webApp.router.getTournamnt(activeTournament!.id, {
                      eventId: event.id,
                    })}
                  >
                    <div className={css.Block}>
                      <div
                        className={css.Logo}
                        style={{
                          backgroundImage: `url(${
                            event.opponent1.image || '/images/no-image.png'
                          })`,
                        }}
                      />
                      <div className={css.Text}>
                        <div className={css.Name}>
                          {Country1Node && (
                            <Country1Node className={css.Flag} />
                          )}
                          <span>{event.opponent1.name}</span>
                        </div>
                        <div className={css.Statistic}>
                          <span>{event.opponent1.coef}</span>
                          <span>{event.opponent1.score || statsOpponent1}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={css.BlockWrapper}>
                  <Link
                    className="noLink"
                    href={webApp.router.getTournamnt(activeTournament!.id, {
                      eventId: event.id,
                    })}
                  >
                    <div className={css.Block}>
                      <div
                        className={css.Logo}
                        style={{
                          backgroundImage: `url(${
                            event.opponent2.image || '/images/no-image.png'
                          })`,
                        }}
                      />
                      <div className={css.Text}>
                        <div className={css.Name}>
                          {Country2Node && (
                            <Country2Node className={css.Flag} />
                          )}
                          <span>{event.opponent2.name}</span>
                        </div>
                        <div className={css.Statistic}>
                          <span>{event.opponent2.coef}</span>
                          <span>{event.opponent2.score || statsOpponent2}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={css.EventDate}>
                  {formatDatetime(new Date(event.startDate))}
                </div>
              </div>
            );
          })}
      </div>

      {activeTournament && !loading && (
        <Link
          href={webApp.router.getTournamnt(activeTournament.id)}
          className="noLink"
        >
          <div className={css.Description}>
            <span>
              {activeTournament.sportType === 'MMA'
                ? t('common:labels:full_card')
                : t('common:labels:to_tournament')}
            </span>
            <ArrowIcon />
          </div>
        </Link>
      )}
    </IndexCard>
  );
};

export default NextEvents;
