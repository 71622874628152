import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import UPLOAD_CONTENT_VIDEO, {
  UploadContentVideoMutation,
  UploadContentVideoMutationVars,
} from '../graphql/upload-content-video';

export type UploadVideoResponseSuccess = {
  success: 1;
  file: {
    url: string;
  };
};

type UploadVideoResponseError = {
  success: 0;
  message: string;
};

type UploadImageResponse =
  | UploadVideoResponseSuccess
  | UploadVideoResponseError;

const useUploadVideo = () => {
  const { t } = useTranslation(['news']);
  const [uploadVideoMutation] = useMutation<
    UploadContentVideoMutation,
    UploadContentVideoMutationVars
  >(UPLOAD_CONTENT_VIDEO);

  const uploadErrorResponse: UploadVideoResponseError = {
    success: 0,
    message: t('news:errors:upload_video_error'),
  };

  return async (file: File): Promise<UploadImageResponse> => {
    try {
      const { data, errors } = await uploadVideoMutation({
        variables: { file },
      });

      const isUploadSuccess = verifyApolloMutationResponse(data, errors);
      if (!isUploadSuccess) {
        return uploadErrorResponse;
      }

      if (data?.uploadContentVideo.__typename === 'Error') {
        Toast.error(data.uploadContentVideo.error);
        return uploadErrorResponse;
      }

      if (data?.uploadContentVideo.__typename === 'UploadContentVideo') {
        return {
          success: 1,
          file: { url: data.uploadContentVideo.fileUrl },
        };
      }

      return uploadErrorResponse;
    } catch (e: unknown) {
      Toast.error(t('news:errors:upload_video_error'));
      return uploadErrorResponse;
    }
  };
};

export default useUploadVideo;
