import React, { useState } from 'react';

import { PeriodType } from 'application/types/period-type';

import TopVotersWidget from './top-voters-widget';
import useTopVoters from '../../hooks/use-top-voters';

const TopVotersWidgetContainer: React.FC<{ sport?: string }> = ({ sport }) => {
  const [period, setPeriod] = useState<PeriodType>(PeriodType.WEEK);
  const { loading, votersData } = useTopVoters({
    sport,
    rating: 'PNL',
    period,
    page: 1,
  });

  return (
    <TopVotersWidget
      period={period}
      voters={votersData?.voters?.slice(0, 5)}
      loading={loading}
      onPeriodChange={setPeriod}
      onVoterSelect={() => {}}
    />
  );
};

export default TopVotersWidgetContainer;
