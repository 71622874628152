import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type TopVoterModel = {
  id: string;
  position: number;
  avatar: string | null;
  name: string;
  social: number;
  iq: number;
  pnl: number;
};

export interface GetTopVotersQuery {
  getTopVoters:
    | {
        __typename: 'GetTopVoters';
        voters: TopVoterModel[];
        page: number;
        currentVoter: TopVoterModel;
      }
    | GraphqlErrorType;
}

export interface GetTopVotersQueryVars {
  period: string;
  sport?: string;
  rating: string;
  page: number;
}

const GET_TOP_VOTERS = gql`
  query GetTopVoters(
    $period: String!
    $rating: String!
    $sport: ID
    $page: Int!
  ) {
    getTopVoters(period: $period, rating: $rating, sport: $sport, page: $page) {
      ... on GetTopVoters {
        voters {
          id
          position
          avatar
          name
          iq
          pnl
          social
        }
        currentVoter {
          id
          position
          avatar
          name
          iq
          social
        }
        page
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_TOP_VOTERS;
