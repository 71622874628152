import { useTranslation } from 'next-i18next';
import React from 'react';

import { ArrowDownIcon } from 'ui/icons';

import css from './show-more.module.scss';

interface ShowMoreButtonProps {
  onClick?: () => void;
}

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ onClick }) => {
  const { t } = useTranslation(['common']);

  return (
    <button className={css.Root} onClick={onClick}>
      {t('common:actions:show_more')}
      <ArrowDownIcon className={css.Icon} />
    </button>
  );
};
