import { useTranslation } from 'next-i18next';
import React, { Ref } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Button, Input, Modal, ModalContent, ModalFooter, ReCaptcha } from 'ui';
import { ArrowRightIcon } from 'ui/icons';

import css from './forgot-password-modal.module.scss';
import SuccessImage from './success.svg';

export type ForgotPasswordModalFormValues = {
  email: string;
};

export type ForgotPasswordModalFormErrors = {
  email?: string;
  recaptcha?: string;
};

interface ForgotPasswordModalProps {
  values: ForgotPasswordModalFormValues;
  errors: ForgotPasswordModalFormErrors;
  recaptchaRef: Ref<ReCAPTCHA>;
  processing: boolean;
  isOpen: boolean;
  isEmailSend: boolean;
  onChange: (values: Partial<ForgotPasswordModalFormValues>) => void;
  onClose?: () => void;
  onGoToSignIn: () => void;
  onSubmit: () => void;
  noOverflow?: boolean;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  values,
  errors,
  processing,
  isOpen,
  isEmailSend,
  onSubmit,
  onChange,
  onClose,
  onGoToSignIn,
  noOverflow,
  recaptchaRef,
}) => {
  const { t } = useTranslation(['auth', 'common']);

  return (
    <Modal
      title={
        isEmailSend ? (
          <div className={css.SuccessTitle}>
            <div>
              <SuccessImage />
            </div>
            <div>{t('auth:application_sent')}</div>
          </div>
        ) : (
          t('auth:password_recovery')
        )
      }
      isOpen={isOpen}
      width={375}
      onClose={onClose}
      noOverflow={noOverflow}
    >
      <ModalContent>
        <div className={css.Description}>
          {isEmailSend ? t('auth:instructions') : t('auth:how_to_recover')}
        </div>
        {!isEmailSend && (
          <form
            className={css.Form}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Input
              type="email"
              value={values.email}
              onChange={(email) => onChange({ email })}
              placeholder="Email"
              error={errors.email}
            />
            <div className={css.Group}>
              <ReCaptcha ref={recaptchaRef} error={errors.recaptcha} />
            </div>
            <Button className={css.SubmitButton} disabled={processing}>
              {t('common:actions:send')}
            </Button>
          </form>
        )}
      </ModalContent>
      <ModalFooter>
        <div className={css.SignInButton} onClick={onGoToSignIn}>
          {t('auth:sign_in')} <ArrowRightIcon width={14} height={14} />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ForgotPasswordModal;
