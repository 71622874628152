import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { PeriodType } from 'application/types/period-type';
import webApp from 'application/web-app';
import { ClientAvatar, IndexCard, Loader, Tabs } from 'ui';
import { Flex } from 'ui/flex';
import WidgetHeader from 'ui/widget-header';

import css from './top-voters-widget.module.scss';
import { TopVoterModel } from '../../graphql/get-top-vouters';

interface TopVotersProps {
  period: PeriodType;
  voters?: TopVoterModel[];
  loading: boolean;
  onPeriodChange: (period: PeriodType) => void;
  onVoterSelect: (id: string) => void;
}

const TopVotersWidget: React.FC<TopVotersProps> = ({
  period,
  voters,
  loading,
  onPeriodChange,
  onVoterSelect,
}) => {
  const { t } = useTranslation(['common', 'top-voters-widget']);
  const noDataTextConditions = !loading && voters?.length === 0;

  return (
    <IndexCard small>
      <WidgetHeader
        href={webApp.router.getTopVoters()}
        title={t('top-voters-widget:label')}
        withArrow
      />
      <Tabs
        value={period}
        items={[
          { value: PeriodType.WEEK, label: t('common:period:week') },
          { value: PeriodType.MONTH, label: t('common:period:month') },
          { value: PeriodType.YEAR, label: t('common:period:year') },
          { value: PeriodType.ALL, label: t('common:period:all_time') },
        ]}
        onSelect={onPeriodChange}
        small
      />

      {loading ? (
        <Flex justifyContent="center">
          <Loader />
        </Flex>
      ) : (
        voters?.map((voter) => (
          <div
            key={voter.id}
            className={css.VotersContainer}
            onClick={() => onVoterSelect(voter.id)}
          >
            <div className={css.VoterNumber}>{voter.position}</div>

            <div className={css.NameWrapper}>
              <ClientAvatar avatar={voter.avatar} size="S" />

              <Link
                href={webApp.router.getClientInfo(voter.id)}
                className={css.VoterName}
                passHref
              >
                {voter.name}
              </Link>
              <div className={css.VoterRating}>{voter.pnl}</div>
            </div>
          </div>
        ))
      )}

      {noDataTextConditions && (
        <p className={css.NoData}>{t('top-voters-widget:empty')}</p>
      )}
    </IndexCard>
  );
};

export default TopVotersWidget;
