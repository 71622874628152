import { useRouter } from 'next/router';

import LastComments from './last-comments';
import useLastComments from './use-last-comments';

const LastCommentsConnected: React.FC<{ sport?: string }> = ({ sport }) => {
  const { comments } = useLastComments(sport);

  return <LastComments comments={comments} />;
};

export default LastCommentsConnected;
