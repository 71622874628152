import { ApolloProvider } from '@apollo/client';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

import config from 'application/config';
import AppConfigProvider, {
  AppConfig,
} from 'application/context/app-config-provider';
import AppModalsProvider from 'application/context/app-modals-provider';
import UserProvider from 'application/context/user-provider';
import { useApollo } from 'application/graphql/apollo-client';
import { MyClientModel } from 'application/graphql/get-my-client';
import BrowserChecker from 'features/browser-checker';
import useCookieTerms from 'features/cookie-terms';
import useDropNotification from 'features/drop-info-notification';
import FirebaseProvider from 'features/firebase/context/firebase-provider';
import PushTokenPermissionModalContainer from 'features/firebase/modals/push-token-permission-modal';
import AppMetaMaskProvider from 'features/wallets/context/app-metamask-context';
import { LoaderLinear } from 'ui';
import { AppToastContainer } from 'ui/toast';

import nextI18nextConfig from '../next-i18next.config';

import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/globals.scss';
import '../styles/toastify.scss';
import '../styles/history-fights.scss';
import '../styles/info-fighter.scss';
import '../styles/rating.scss';
import '../styles/statistics-fights.scss';
import '../styles/vertical-scroll-wrapper.scss';

export type AppPage<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type Props = AppProps & {
  Component: AppPage;
};

const NextApp = ({ Component, pageProps }: Props) => {
  useDropNotification();
  useCookieTerms();

  const router = useRouter();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
    };

    const handleRouteChangeComplete = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  const apolloClient = useApollo(
    { apiUrl: config.graphqlApiUrl, wssUrl: config.graphqlWsUrl },
    pageProps
  );

  const getLayout = Component.getLayout ?? ((page) => page);

  const App = getLayout(<Component {...pageProps} />);

  return (
    <>
      {config.gaId && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.gaId}`}
          />
          <Script id="gtm" strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${config.gaId}', {
              page_path: window.location.pathname,
              });
          `}
          </Script>
        </>
      )}
      {config.pendoId && (
        <Script id="pendo" strategy="lazyOnload">
          {`
            (function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('${config.pendoId}');
            `}
        </Script>
      )}
      {config.yandexId && (
        <>
          <Script id="yandex" strategy="lazyOnload">
            {`
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(${config.yandexId}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });`}
          </Script>
          <noscript>
            <div>
              <img
                src={`https://mc.yandex.ru/watch/${config.yandexId}`}
                style={{ position: 'absolute', left: '-9999px' }}
                alt=""
              />
            </div>
          </noscript>
        </>
      )}

      <ApolloProvider client={apolloClient}>
        <AppConfigProvider config={pageProps.appConfig}>
          <AppModalsProvider>
            <FirebaseProvider>
              <UserProvider user={pageProps.user}>
                <AppMetaMaskProvider>
                  {loading && <LoaderLinear />}
                  {App}
                  <BrowserChecker />
                  <AppToastContainer />
                  <PushTokenPermissionModalContainer />
                </AppMetaMaskProvider>
              </UserProvider>
            </FirebaseProvider>
          </AppModalsProvider>
        </AppConfigProvider>
      </ApolloProvider>
    </>
  );
};

export default appWithTranslation(NextApp, nextI18nextConfig);
