import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { InstagramIcon, TelegramIcon, YoutubeIcon } from 'ui/icons';

import css from './contacts.module.scss';

const Contacts: React.FC = () => {
  const { t } = useTranslation(['footer', 'help']);

  return (
    <div className={css.Root}>
      <ul className={css.Menu}>
        <li className={css.Item}>
          <Link href="/about">{t('footer:about_us')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/articles">{t('footer:articles')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/rules">{t('footer:platform_rules')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/help">{t('help:labels:help')}</Link>
        </li>
        <li className={css.Item}>
          <Link href="/job">{t('footer:vacancies')}</Link>
        </li>
      </ul>

      <ul className={css.ListSocial}>
        <li className={css.ListSocialItem}>
          <a
            href="https://www.youtube.com/@eramma"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </a>
        </li>
        <li className={css.ListSocialItem}>
          <a
            href="https://www.instagram.com/eramma_ru/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </li>
        <li className={css.ListSocialItem}>
          <a href="https://t.me/eramma2" target="_blank" rel="noreferrer">
            <TelegramIcon />
          </a>
        </li>
      </ul>
      <div className={css.Footer}>{t('footer:copyright')}</div>
    </div>
  );
};

export default Contacts;
