import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

import { IndexCard } from 'ui';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import { ArrowDownIcon } from 'ui/icons';
import { Title } from 'ui/v2/typography';
import { Text } from 'ui/v2/typography/text';

import css from './how-vote.module.scss';
import { TrainingVideo } from '../../graphql/get-training-videos';

interface HowVoteProps {
  video: TrainingVideo;
}

const HowVote: React.FC<HowVoteProps> = ({ video }) => {
  const { t } = useTranslation(['vote', 'common']);

  const { query } = useRouter();

  useEffect(() => {
    if ('drop' in query) {
      const element = document.getElementById('drop-info-block');

      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [query]);

  return (
    <IndexCard>
      <Grid gap={GridGap.x2}>
        <Title>{t('vote:labels:how_vote')}</Title>
        <Text type="paragraph">{t('vote:how_vote_page:paragraph_1')}</Text>
        <Text type="paragraph">{t('vote:how_vote_page:paragraph_2')}</Text>
        <Text type="paragraph">
          <Trans
            i18nKey="vote:how_vote_page:paragraph_3"
            components={{
              1: <span className={css.TextAccent}></span>,
            }}
          />
        </Text>
        <Text type="paragraph">
          <Trans
            i18nKey="vote:how_vote_page:paragraph_4"
            components={{
              1: <span className={css.TextAccent}></span>,
            }}
          />
        </Text>
        <Text type="paragraph">{t('vote:how_vote_page:paragraph_5')}</Text>
        <video controls className={css.Video} width="100%">
          <source src={video.videoRegistration} type="video/mp4" />
          {t('vote:labels:video_not_available')}
        </video>
        <Text type="paragraph">
          <Trans
            i18nKey="vote:how_vote_page:paragraph_6"
            components={{
              1: <span className={css.TextAccent}></span>,
            }}
          />
        </Text>
        <Text type="paragraph">{t('vote:how_vote_page:paragraph_7')}</Text>
        <Text type="paragraph">{t('vote:how_vote_page:paragraph_8')}</Text>
        <video controls className={css.Video} width="100%">
          <source src={video.videoVote1} type="video/mp4" />
          {t('vote:labels:video_not_available')}
        </video>
        <Text type="paragraph">{t('vote:how_vote_page:paragraph_9')}</Text>
        <video controls className={css.Video} width="100%">
          <source src={video.videoVote2} type="video/mp4" />
          {t('vote:labels:video_not_available')}
        </video>

        <Title id="drop-info-block">
          {t('vote:labels:airdrop_conditions')}
        </Title>
        <Text type="paragraph">{t('vote:airdrop:paragraph_1')}</Text>
        <Text type="paragraph">{t('vote:airdrop:paragraph_2')}</Text>
        <Text type="body3">{t('vote:labels:terms_tokens')}</Text>
        <Text type="paragraph">
          <Trans
            i18nKey="vote:airdrop:paragraph_3"
            components={{
              1: <span className={css.Bold}></span>,
            }}
          />
        </Text>
        <Text type="paragraph">
          <Trans
            i18nKey="vote:airdrop:paragraph_4"
            components={{
              1: <span className={css.Bold}></span>,
            }}
          />
        </Text>

        <Link href={'/'} className={css.Link}>
          <ArrowDownIcon />
          {t('common:labels:to_home')}
        </Link>
      </Grid>
    </IndexCard>
  );
};

export default HowVote;
