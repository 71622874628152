import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import WebApp from 'application/web-app';
import { Select } from 'ui';

import css from './language-select.module.scss';

const LanguageSelect = () => {
  const [currentLanguage, setCurrentLanguage] = useState<null | string>(null);
  const { i18n } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    const setInitialLanguage = async () => {
      setCurrentLanguage(i18n.language);
    };

    setInitialLanguage();
  }, [i18n]);

  const changeLanguage = async (language: string) => {
    setCurrentLanguage(language);
    await i18n.changeLanguage(language, () => {
      document.cookie = `i18next=${language}; max-age=31536000; path=/`;
      router.reload();
    });
  };

  if (!currentLanguage) return null;

  return (
    <div className={css.Root}>
      <Select
        items={WebApp.appLanguages}
        value={currentLanguage}
        onChange={changeLanguage}
      />
    </div>
  );
};

export default LanguageSelect;
