import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { Toast } from 'ui';

import GET_TOP_VOTERS, {
  GetTopVotersQuery,
  GetTopVotersQueryVars,
  TopVoterModel,
} from '../graphql/get-top-vouters';

const useTopVoters = (params: GetTopVotersQueryVars) => {
  const [votersData, setVotersData] = useState<{
    voters: TopVoterModel[];
    page: number;
    currentVoter?: TopVoterModel;
  }>();
  const { data, error, loading, refetch, fetchMore } = useQuery<
    GetTopVotersQuery,
    GetTopVotersQueryVars
  >(GET_TOP_VOTERS, {
    variables: params,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Toast.error(error.message);
    }

    if (data?.getTopVoters.__typename === 'Error') {
      Toast.error(data.getTopVoters.error);
    }

    if (data?.getTopVoters.__typename === 'GetTopVoters') {
      setVotersData(data.getTopVoters);
    }
  }, [data, error]);

  return {
    loading,
    votersData,
    refetch,
    fetchMore,
  };
};

export default useTopVoters;
