import DOMPurify from 'isomorphic-dompurify';

export const sanitizeHtml = (html: string) => {
  let formattedHtml = html.replace(/\\n|\\\\n/g, '<br>');

  return DOMPurify.sanitize(formattedHtml, {
    ALLOWED_TAGS: ['br', 'b', 'i', 'a'],
    ADD_ATTR: ['href'],
  });
};
