import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { ClientType } from 'application/types/client-type';
import { dateToTextShort } from 'application/utils/date';
import { ClientAvatar } from 'ui';
import RemoveEditButtons from 'ui/remove-edit-buttons';

import css from './comment-header.module.scss';
import webApp from '../../../../application/web-app';

interface CommentHeaderProps {
  authorData: ClientType;
  date: string;
  myComment: boolean;
  isCommentUpdated: boolean;
  deleteMyComment?: () => void;
  editMyComment?: () => void;
}

const CommentHeader: React.FC<CommentHeaderProps> = ({
  authorData,
  date,
  myComment,
  deleteMyComment,
  isCommentUpdated,
  editMyComment,
}) => {
  const { name, id, avatar, iq } = authorData;
  const { t } = useTranslation(['comments']);

  return (
    <div className={css.Root}>
      <Link
        href={webApp.router.getClientInfo(id)}
        passHref
        className={css.AvatarLink}
      >
        <ClientAvatar avatar={avatar} size="S" className={css.Avatar} />
      </Link>
      <p className={css.Name}>
        <Link
          href={webApp.router.getClientInfo(id)}
          className={css.Name}
          passHref
        >
          {name}
        </Link>
        <span className={css.IQ}>{iq} IQ</span>
      </p>

      <p className={css.Date}>
        {dateToTextShort(new Date(date))}
        {isCommentUpdated && (
          <span className={css.Updating}>{t('comments:changed')}</span>
        )}
      </p>

      {myComment && (
        <RemoveEditButtons
          className={css.ButtonsContainer}
          remove={deleteMyComment!}
          edit={editMyComment!}
        />
      )}
    </div>
  );
};

export default CommentHeader;
