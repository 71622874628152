import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import webApp from 'application/web-app';
import { IndexCard } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import WidgetHeader from 'ui/widget-header';

import css from './next-tournaments.module.scss';

type NextTournament = {
  id: string;
  icon: string;
  name: string;
  time: string;
};

interface NextTournamentsProps {
  tournaments: NextTournament[];
  onTournamentSelect: (id: string) => void;
}

const NextTournaments: React.FC<NextTournamentsProps> = ({ tournaments }) => {
  const { t } = useTranslation(['tournaments']);

  return (
    <IndexCard small>
      <WidgetHeader
        href={webApp.router.getCalendarOfEvents()}
        title={t('tournaments:next_tournaments')}
        withArrow
      />

      {tournaments.length === 0 && (
        <p className={css.NoData}>{t('tournaments:no_tournaments')} </p>
      )}

      <Flex
        className={css.ItemsContainer}
        flexDirection="column"
        gap={GridGap.x1}
      >
        {tournaments.map((tournament) => (
          <Link
            key={tournament.id}
            className={css.Item}
            href={webApp.router.getTournamnt(tournament.id)}
          >
            <div className={css.Icon}>
              <img src={tournament.icon} alt={tournament.name} />
            </div>

            <div className={css.Name}>
              <span className={css.Text}>{tournament.name}</span>
              <span className={css.Time}>{tournament.time}</span>
            </div>
          </Link>
        ))}
      </Flex>
    </IndexCard>
  );
};

export default NextTournaments;
